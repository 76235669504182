import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useTicketDetailsQuery } from '~/pages/Diagnostic/query'
import TicketDetails from '~/pages/Diagnostic/TicketDetails'
import PageName from '~/components/PageName'
import { StyledPaper } from '~/components/StyledPaper'
import styled from '@emotion/styled'
import PicturesSection from '~/pages/Diagnostic/PicturesSection'
import { useIssueQuery } from '~/pages/Issue/query'
import IssueLog from '~/pages/Diagnostic/IssueLog'
import DeviceHistory from '~/pages/Diagnostic/DeviceHistory'
import DeviceImages from '~/pages/Diagnostic/DeviceImages'

const IssueImages = () => {
    const localization = 'pages.issues.images'
    const { issueId } = useParams()

    const { t } = useTranslation()

    const { data: ticketData } = useTicketDetailsQuery(issueId)
    const { data: issueDetail } = useIssueQuery(issueId)

    const Section = styled(Grid, {
        shouldForwardProp: (prop) => prop !== 'show',
    })(({ show }) => {
        return {
            ...(!show && {
                display: 'none',
            }),
        }
    })

    return (
        <>
            <PageName name={t(`${localization}.title`, { issueId })} />
            <Grid container spacing={3}>
                <Section item xs={12} md={6} show={true}>
                    <StyledPaper variant='outlined'>
                        <TicketDetails data={ticketData} />
                    </StyledPaper>
                </Section>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Section item xs={12} show={true}>
                            <StyledPaper variant='outlined'>
                                <PicturesSection
                                    isFinish={
                                        !!issueDetail.diagnostic?.finished_at
                                    }
                                    isInPictureStep={
                                        issueDetail.diagnostic?.current_step ===
                                        1
                                    }
                                    setDisabledNextButton={() => {}}
                                    isTriage={true}
                                />
                            </StyledPaper>
                        </Section>
                    </Grid>
                </Grid>
                <Section item xs={12} show={true}>
                    <StyledPaper variant='outlined'>
                        <DeviceImages title='lastImageBefore' />
                    </StyledPaper>
                </Section>
                <Section item xs={12} show={true}>
                    <StyledPaper variant='outlined'>
                        <DeviceImages
                            title='firstImageAfter'
                            isBeforeRepair={false}
                        />
                    </StyledPaper>
                </Section>
                <Section item xs={12} show={true}>
                    <StyledPaper variant='outlined'>
                        <IssueLog />
                    </StyledPaper>
                </Section>
                <Section item xs={12} show={true}>
                    <StyledPaper variant='outlined'>
                        <DeviceHistory />
                    </StyledPaper>
                </Section>
            </Grid>
        </>
    )
}

export default IssueImages
