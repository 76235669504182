import {
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Box,
    Table,
    Paper,
    TableContainer,
    TextField,
    Stack,
} from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import TableLoading from '~/components/Table/TableLoading'
import { headCells } from './constants'
import PropTypes from 'prop-types'
import { LoadingButton } from '~/components/Button'
import { useEffect, useState } from 'react'
import StyledModal from '~/components/StyledModal'
import DatePicker from 'react-datepicker'

import {
    useApproveQuotation,
    useCreatingShipmentForIssueTesting,
    useTransitBomStateToWmsOrderMutate,
} from '~/pages/TestData/DispatchShipmentReport/mutation'
import 'react-datepicker/dist/react-datepicker.css'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { generateRandomString } from '~/utils/helpers'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from '~/hooks/useSnackbar'

const IssueTableBody = ({ isLoading, issues }) => {
    const localization = `pages.administrator.testData.overview`
    const { t } = useTranslation()
    const { openSnackbar } = useDialogContext()
    const { showSnackbar, snackbar } = useSnackbar()
    const [issueId, setIssueId] = useState()
    const [startDate, setStartDate] = useState(new Date())
    const [trackingNumber, setTrackingNumber] = useState('')

    const queryClient = useQueryClient()
    const { mutate } = useCreatingShipmentForIssueTesting()
    const { mutate: approveQuotationMutate } = useApproveQuotation()
    useEffect(() => {
        if (issueId) {
            setTrackingNumber(generateRandomString(8))
        }
    }, [issueId])

    const closePopup = () => {
        setIssueId(undefined)
        setStartDate(new Date())
        setTrackingNumber('')
    }

    const addShipmentReport = () => {
        const month = startDate.getMonth() + 1
        const day = startDate.getDate()
        const year = startDate.getFullYear()
        mutate(
            { issueId, trackingNumber, date: `${month}-${day}-${year}` },
            {
                onSuccess: () => {
                    openSnackbar({
                        message: t(
                            `${localization}.create_shipment_successfully`,
                        ),
                        type: 'success',
                    })
                    queryClient.invalidateQueries('test-data-issues')
                    closePopup()
                },
                onError: (error) => {
                    openSnackbar({
                        message:
                            error.response?.data?.message ||
                            error.message ||
                            'Create failed!',
                        type: 'error',
                    })
                },
            },
        )
    }

    const approveQuotation = (issueId, quotation_approved) => {
        approveQuotationMutate(
            { issueId, quotation_approved },
            {
                onSuccess: () => {
                    const msg = quotation_approved
                        ? t(`${localization}.accept_quotation_successfully`)
                        : t(`${localization}.deny_quotation_successfully`)
                    openSnackbar({
                        message: msg,
                        type: 'success',
                    })
                    queryClient.invalidateQueries('test-data-issues')
                    closePopup()
                },
                onError: (error) => {
                    openSnackbar({
                        message:
                            error.response?.data?.message ||
                            error.message ||
                            'Approve quotation failed!',
                        type: 'error',
                    })
                },
            },
        )
    }

    // HANDLE ACCEPT DISPATCH
    const { mutate: transitBomstateWmsOrderMuate } =
        useTransitBomStateToWmsOrderMutate(showSnackbar)

    const btnAcceptDispatchClick = (currentIssueId) => {
        transitBomstateWmsOrderMuate({ issueId: currentIssueId })
    }

    if (isLoading) {
        return <TableLoading colSpan={headCells.length} />
    }

    if (issues.length < 1) {
        return (
            <TableBody>
                <DataNotFound colSpan={headCells.length} />
            </TableBody>
        )
    }
    return (
        <>
            {snackbar}
            <TableBody>
                {issues?.map((issue, index) => (
                    <TableRow
                        key={index}
                        sx={{
                            '&:last-child td, &:last-child th': {
                                border: 0,
                            },
                        }}
                    >
                        <TableCell>{issue.id}</TableCell>
                        <TableCell>{issue.ticket_id}</TableCell>
                        <TableCell>{issue.name || '---'}</TableCell>
                        <TableCell>{issue.status || '---'}</TableCell>
                        <TableCell>{issue.state || '---'}</TableCell>
                        <TableCell>{issue.bom_state || '---'}</TableCell>
                        <TableCell>{issue.trackingno || '---'}</TableCell>
                        <TableCell>
                            <Stack direction='row' gap={2}>
                                {issue.bom_state === 'AWAITING_APPROVAL' && (
                                    <>
                                        <LoadingButton
                                            label={t(
                                                `${localization}.accept_quotation`,
                                            )}
                                            onClick={() =>
                                                approveQuotation(issue.id, true)
                                            }
                                        />
                                        <LoadingButton
                                            label={t(
                                                `${localization}.deny_quotation`,
                                            )}
                                            onClick={() =>
                                                approveQuotation(
                                                    issue.id,
                                                    false,
                                                )
                                            }
                                        />
                                    </>
                                )}
                            </Stack>
                            <Stack direction='row' gap={1}>
                                {issue.bom_state === 'DISPATCHED' && (
                                    <LoadingButton
                                        label={t(
                                            `${localization}.accept_dispatch`,
                                        )}
                                        onClick={() =>
                                            btnAcceptDispatchClick(issue.id)
                                        }
                                        bgColor='#102F44'
                                    />
                                )}
                                <LoadingButton
                                    label={t(`${localization}.create_shipment`)}
                                    onClick={() => setIssueId(issue.id)}
                                    bgColor='#102F44'
                                />
                            </Stack>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
            <StyledModal
                open={!!issueId}
                handleClose={() => {
                    setIssueId(undefined)
                }}
                stackProps={{
                    sx: {
                        minWidth: 650,
                        maxHeight: '95vh',
                        overflowX: 'hidden',
                        overflowY: 'hidden',
                    },
                }}
            >
                <Typography variant='h6'>Add Shipment Report</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                    }}
                >
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Track Number</TableCell>
                                    <TableCell>
                                        <TextField
                                            autoFocus={true}
                                            variant='outlined'
                                            onChange={(e) => {
                                                setTrackingNumber(
                                                    e.target.value,
                                                )
                                            }}
                                            value={trackingNumber}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) =>
                                                setStartDate(date)
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        gap: '8px',
                        marginTop: '10px',
                    }}
                >
                    <LoadingButton label='Cancel' onClick={closePopup} />
                    <LoadingButton label='Add' onClick={addShipmentReport} />
                </Box>
            </StyledModal>
        </>
    )
}

IssueTableBody.propTypes = {
    isLoading: PropTypes.bool,
    issues: PropTypes.array,
}

export default IssueTableBody
