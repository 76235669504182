import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    IconButton,
    Link,
    Stack,
    Typography,
} from '@mui/material'
import BreadCrumb from '~/components/BreadCrumb'
import CustomCardHeader from '~/components/CustomCardHeader'
import PageName from '~/components/PageName'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useIssueQuery } from './query'
import Loading from '~/components/Loading'
import { useEffect, useMemo, useState } from 'react'
import { ticketExternalLink } from '~/utils/helpers'
import { OpenInNew } from '@mui/icons-material'
import PropTypes from 'prop-types'
import usePermissions from '~/hooks/usePermission'
import { ASSIGNED_TO_SUPERVISOR } from '~/pages/Issue/Overview/constants'
import {
    PopupCancelIssue,
    PopupSupervisorProcessIssue,
} from '~/pages/Issue/Overview/components/Popup'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

export const Layout = ({ children, breadcrumbs, pageName }) => {
    return (
        <>
            <BreadCrumb {...{ breadcrumbs }} />
            <PageName name={pageName} />
            {children}
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.any,
    breadcrumbs: PropTypes.array,
    pageName: PropTypes.string,
}

export const IssueDetailBody = () => {
    const { issueId } = useParams()
    const [showProceedIssuePopup, setShowProceedIssuePopup] = useState(false)
    const [showCancelPopup, setShowCancelPopup] = useState(false)
    const {
        data,
        isLoading: isQueryLoading,
        isSuccess,
    } = useIssueQuery(issueId)
    const [initialValues, setInitialValues] = useState(null)
    const { permissions } = usePermissions()

    useEffect(() => {
        if (isSuccess) {
            setInitialValues({ ...data.data, serial: data.data.name })
        }
    }, [data, isSuccess])
    const localization = 'pages.issues.create'
    const { t } = useTranslation('translation', { keyPrefix: localization })
    const isSupervisorRight = permissions.issue.canManageExpertIssue
    const isHiddenCancelButton = useMemo(() => {
        if (data?.data?.state === 'CANCELLED') {
            return true
        }
        return !permissions.issue.canCloseIssue
    }, [data, permissions])

    const isShownProceedButton = useMemo(() => {
        return (
            initialValues?.state === ASSIGNED_TO_SUPERVISOR &&
            isSupervisorRight === true
        )
    }, [initialValues, isSupervisorRight])

    if (isQueryLoading) {
        return <Loading />
    }

    return (
        <Grid container spacing={2}>
            <Stack direction='column' spacing={3} sx={{ width: '100%' }}>
                <Card>
                    <CustomCardHeader
                        title={`Issue ID: ${initialValues?.id}`}
                        action={
                            <Box
                                direction='row'
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 0.5,
                                }}
                            >
                                {isShownProceedButton && (
                                    <Button
                                        variant='contained'
                                        size='small'
                                        sx={{
                                            textTransform: 'none',
                                            backgroundColor: '#76B72A',
                                        }}
                                        onClick={() => {
                                            setShowProceedIssuePopup(true)
                                        }}
                                    >
                                        {t('process_supervisor_issue')}
                                    </Button>
                                )}
                                {!isHiddenCancelButton && (
                                    <Button
                                        variant='contained'
                                        size='small'
                                        sx={{
                                            textTransform: 'none',
                                            backgroundColor: '#102F44',
                                        }}
                                        onClick={() => setShowCancelPopup(true)}
                                    >
                                        {t('cancelIssue')}
                                    </Button>
                                )}
                            </Box>
                        }
                    />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`ticket_id`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.ticket_id ? (
                                        <Link
                                            target='_blank'
                                            underline='none'
                                            href={ticketExternalLink(
                                                initialValues?.ticket_id,
                                                initialValues?.url,
                                            )}
                                        >
                                            {initialValues?.ticket_id}
                                            <IconButton
                                                size='small'
                                                sx={{
                                                    padding: 0,
                                                    marginLeft: '3px',
                                                    marginBottom: '3px',
                                                }}
                                            >
                                                <OpenInNew />
                                            </IconButton>
                                        </Link>
                                    ) : (
                                        'NULL'
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`esd_location`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.esd_location ?? 'NULL'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`serial`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.serial ?? 'NULL'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`description`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.description ?? 'NULL'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`status`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.status ?? 'NULL'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`state`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.state ?? 'NULL'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`bom_state`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.bom_state ?? 'NULL'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`reject_reason`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.reject_reason ?? 'NULL'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`created_at`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.created_at ? (
                                        <DateTimeLocale
                                            datetime={initialValues?.created_at}
                                        />
                                    ) : (
                                        'NULL'
                                    )}
                                </Typography>
                            </Grid>
                            {[
                                'service_id',
                                'service_center_tid',
                                'brand',
                                'school_id',
                                'device_user_id',
                                'contract_id',
                                'damage_description',
                            ].map((fieldName, index) => (
                                <Grid item xs={6} md={4} key={index}>
                                    <Typography
                                        variant='subtitle2'
                                        component='div'
                                    >
                                        {t(fieldName)}
                                    </Typography>
                                    <Typography
                                        variant='primary'
                                        component='div'
                                    >
                                        {initialValues?.ticket?.[fieldName] ??
                                            'NULL'}
                                    </Typography>
                                </Grid>
                            ))}
                            {['damaged', 'powers_on', 'liquid_damage'].map(
                                (fieldName, index) => (
                                    <Grid item xs={6} md={4} key={index}>
                                        <Typography
                                            variant='subtitle2'
                                            component='div'
                                        >
                                            {t(fieldName)}
                                        </Typography>
                                        <Typography
                                            variant='primary'
                                            component='div'
                                        >
                                            {initialValues?.[fieldName]
                                                ? 'True'
                                                : 'False'}
                                        </Typography>
                                    </Grid>
                                ),
                            )}
                            {[
                                'cause_tid',
                                'accessories',
                                'loaner_id',
                                'webshop_id',
                                'image_version_tid',
                                'language',
                                'entity_tid',
                            ].map((fieldName, index) => (
                                <Grid item xs={6} md={4} key={index}>
                                    <Typography
                                        variant='subtitle2'
                                        component='div'
                                    >
                                        {t(fieldName)}
                                    </Typography>
                                    <Typography
                                        variant='primary'
                                        component='div'
                                    >
                                        {initialValues?.ticket?.[fieldName] ??
                                            'NULL'}
                                    </Typography>
                                </Grid>
                            ))}
                            <Grid item xs={6} md={4}>
                                <Typography variant='subtitle2' component='div'>
                                    {t(`previous_id`)}
                                </Typography>
                                <Typography variant='primary' component='div'>
                                    {initialValues?.previous_id ?? 'N/A'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Stack>
            <PopupSupervisorProcessIssue
                isOpened={showProceedIssuePopup}
                closeHandler={() => {
                    setShowProceedIssuePopup(false)
                }}
                issueId={issueId}
                issueData={data?.data}
            />
            <PopupCancelIssue
                isOpened={showCancelPopup}
                closeHandler={() => setShowCancelPopup(false)}
                issueId={issueId}
            />
        </Grid>
    )
}
