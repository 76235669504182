import {
    Divider,
    Link,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import DataNotFound from '~/components/DataNotFound'
import { CauseHeadCells } from '~/components/HeaderTable/CauseHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { ADMINISTRATOR_DEFINITION_CAUSE_EDIT } from '~/constants/Routes'
import { useSnackbar } from '~/hooks/useSnackbar'
import useTable from '~/hooks/useTable'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectMessageCause } from '~/store/cause/selector'
import { setMessageCause } from '~/store/cause/slice'
import { useCausesQuery } from '../query'
import CauseTableFilters from './CauseTableFilters'
import PropTypes from 'prop-types'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const localization = 'pages.administrator.definition.causes.overview.'

const CauseTableBody = ({ isLoading, causes }) => {
    const navigate = useNavigate()
    const locale = localStorage.getItem('LOCALE')

    const showModelData = (models) => {
        if (!models.length) {
            return ['All']
        }
        return models.map((model) => {
            return model.name ?? model.description
        })
    }

    if (isLoading) {
        return <TableLoading colSpan={CauseHeadCells.length} />
    }

    return (
        <TableBody>
            {causes.length > 0 ? (
                causes.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell component='td' scope='row'>
                                <Link
                                    component='button'
                                    variant='inherit'
                                    to='#'
                                    underline='hover'
                                    onClick={() =>
                                        navigate(
                                            ADMINISTRATOR_DEFINITION_CAUSE_EDIT.replace(
                                                ':id',
                                                row.id,
                                            ),
                                        )
                                    }
                                >
                                    <Typography variant='body2' align='center'>
                                        {row.id}
                                    </Typography>
                                </Link>
                            </TableCell>
                            <TableCell align='left'>{row.tid}</TableCell>
                            <TableCell align='left'>
                                <List>
                                    {row.for_all_models ? (
                                        <ListItem>
                                            <ListItemText
                                                primary={'FOR ALL MODELS'}
                                            />
                                        </ListItem>
                                    ) : (
                                        showModelData(row.models).map(
                                            (name, idx) => {
                                                return (
                                                    <ListItem key={idx}>
                                                        <ListItemText
                                                            primary={name}
                                                        />
                                                    </ListItem>
                                                )
                                            },
                                        )
                                    )}
                                </List>
                            </TableCell>
                            <TableCell align='left'>
                                {row?.name[locale] ?? ''}
                            </TableCell>
                            <TableCell align='left'>
                                {row?.disabled ? (
                                    <span
                                        style={{
                                            fontSize: 30,
                                            color: 'red',
                                        }}
                                    >
                                        &#9747;
                                    </span>
                                ) : (
                                    <span
                                        style={{
                                            fontSize: 30,
                                            color: 'green',
                                        }}
                                    >
                                        &#10003;
                                    </span>
                                )}
                            </TableCell>
                            <TableCell align='left'>
                                <DateTimeLocale datetime={row.created_at} />
                            </TableCell>
                            <TableCell align='left'>
                                <DateTimeLocale datetime={row.updated_at} />
                            </TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={CauseHeadCells.length} />
            )}
        </TableBody>
    )
}

CauseTableBody.propTypes = {
    isLoading: PropTypes.bool,
    causes: PropTypes.arrayOf(PropTypes.object),
}

const CausesTable = () => {
    const { showSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [models, setModels] = useState([])

    const {
        page,
        search,
        order,
        orderBy,
        data,
        results,
        isLoading,
        sortHandler,
        searchHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useCausesQuery,
        initialStates: {
            order: 'asc',
        },
        queryAttributes: {
            models,
        },
    })

    const messageCause = useSelector(selectMessageCause)

    const refValue = useRef({
        models: [null],
    })
    useEffect(() => {
        refValue.current.models = []
        models.forEach((model) => {
            refValue.current.models.push(model)
        })
    }, [models])

    useEffect(() => {
        if (messageCause) {
            showSnackbar(t(`message.${messageCause.message}`), 'success')
            dispatch(setMessageCause(null))
        }
    }, [dispatch, messageCause, order, orderBy, showSnackbar, t])

    return (
        <Paper sx={{ width: '100%', mb: 2 }}>
            <CauseTableFilters
                {...{
                    search,
                    searchHandler,
                    onEnterSearch,
                    models,
                    setModels,
                }}
            />
            <Divider />
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby='tableTitle'
                    size='medium'
                >
                    <TableHeader
                        headCells={CauseHeadCells}
                        localization={localization}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <CauseTableBody isLoading={isLoading} causes={results} />
                </Table>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}
export default CausesTable
