import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { object, string } from 'yup'
import { useEditCauseMutation } from '../mutation'
import CauseForm from '../CauseForm'
import { useDispatch } from 'react-redux'
import { useSnackbar } from '~/hooks/useSnackbar'
import { setMessageCause } from '~/store/cause/slice'
import PropTypes from 'prop-types'
import { useQueryClient } from 'react-query'
import { parseApiErrorMessage } from '~/utils/helpers'
import { useTranslation } from 'react-i18next'

const EditCauseForm = ({ causeContent, pagePath, setSelectedModelIds }) => {
    const queryClient = useQueryClient()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { showSnackbar, snackbar } = useSnackbar()

    const initial = useMemo(
        () => ({
            tid: causeContent.tid,
            name: causeContent.name,
            model_ids: !causeContent.models.length
                ? [0]
                : causeContent.models.map((model) => {
                      return model.id
                  }),
            notes: causeContent.notes,
            for_all_models: causeContent.for_all_models,
        }),
        [causeContent],
    )

    useEffect(() => {
        if (causeContent.models.length) {
            setSelectedModelIds(causeContent.models.map((model) => model.id))
        }
    }, [causeContent, setSelectedModelIds])

    const { mutate: updateCause, isLoading } = useEditCauseMutation()
    const { id } = useParams()
    const submit = useCallback(
        (payload) => {
            updateCause(
                { id, payload },
                {
                    onSuccess: (data) => {
                        queryClient.invalidateQueries(['cause_detail', id])
                        dispatch(setMessageCause(data))
                        showSnackbar('Update successfully!', 'success')
                    },
                    onError: (e) => {
                        showSnackbar(parseApiErrorMessage(e), 'error')
                    },
                },
            )
        },

        [dispatch, id, queryClient, showSnackbar, updateCause],
    )
    const schema = object().shape({
        tid: string().required(),
    })

    const validate = useFormValidationCallback(schema)

    const onSubmit = useCallback(
        (values) => {
            const params = {
                tid: values.tid,
                name: values.name,
                notes: values.notes,
                for_all_models: values.for_all_models,
            }

            if (
                !values.for_all_model &&
                typeof values.model_ids === 'undefined'
            ) {
                showSnackbar(t('pages.causes.validateForAllModels'), 'error')
                return
            }

            if (
                typeof values.model_ids !== 'undefined' &&
                !values.model_ids.includes(0)
            ) {
                params.model_ids = values.model_ids
            }
            submit(params)
        },
        [showSnackbar, submit, t],
    )

    return (
        <>
            {snackbar}
            <CauseForm
                validate={validate}
                onSubmit={onSubmit}
                isLoading={isLoading}
                name='Update'
                initialValues={initial}
                isCauseDisabled={causeContent.disabled}
                causeId={causeContent.id}
                pagePath={pagePath}
                isUpdatePage
                setSelectedModelIds={setSelectedModelIds}
            />
        </>
    )
}

EditCauseForm.propTypes = {
    causeContent: PropTypes.object,
    pagePath: PropTypes.string,
    setSelectedModelIds: PropTypes.func,
}

export default EditCauseForm
