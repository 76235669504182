import { useNavigate } from 'react-router-dom'
import TableLoading from '~/components/Table/TableLoading'
import { CauseHeadCells } from '~/components/HeaderTable/CauseHeadCells'
import DataNotFound from '~/components/DataNotFound'
import {
    Link,
    TableCell,
    TableRow,
    Typography,
    TableBody as MuiTableBody,
} from '@mui/material'
import { FLEET_MODEL_DETAIL } from '~/constants/Routes'
import PropTypes from 'prop-types'

const TableBody = ({ isLoading, items }) => {
    const navigate = useNavigate()
    if (isLoading) {
        return <TableLoading colSpan={CauseHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {items.length > 0 ? (
                items.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.code}>
                            <TableCell align='left'>
                                <Link
                                    component='button'
                                    variant='inherit'
                                    to='#'
                                    underline='hover'
                                    onClick={() =>
                                        navigate(
                                            FLEET_MODEL_DETAIL.replace(
                                                ':id',
                                                row.id,
                                            ),
                                        )
                                    }
                                >
                                    <Typography variant='body2' align='center'>
                                        {row.code}
                                    </Typography>
                                </Link>
                            </TableCell>
                            <TableCell align='left'>{row.name}</TableCell>
                            <TableCell align='left'>
                                {row.description}
                            </TableCell>
                            <TableCell align='left'>
                                {row.devices_count}
                            </TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={CauseHeadCells.length} />
            )}
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    isLoading: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
}

export default TableBody
