export default {
    translation: {
        general: {
            searchFor: `Zoeken naar `,
            users: `gebruikers`,
            components: `onderdelen `,
            vendors: `verkopers `,
            serials: `series `,
            dispatches: `verzendingen `,
            partCode: `Onderdeelcode `,
            entitlements: `rechten `,
            orderConfirmations: `orderbevestigingen `,
            invoices: `facturen `,
            clients: `clients`,
            serviceAccounts: `service Accounts`,
            regions: `regios`,
            serviceCenter: `service centers`,
            teams: `teams`,
            importFile: `Import file`,
            uploadCSV: `Upload CSV`,
            uploadExcel: `Upload Excel`,
            downloadExampleFile: `Download example file`,
            scheduler: `scheduler`,
            workbenches: `Werk tafels`,
            deviceName: `apparaat naam`,
            tags: `Tags`,
            tagRules: `Tag Regels`,
            dell_stocks: `dell voorraad`,
            addTooltip: `Add tooltip`,
            rackScans: `rack scans`,
            scanLines: `scan regels`,
            tickets: `tickets`,
            purchaseOrders: `purchase orders`,
            causes: `causes`,
            tooltips: `tooltips`,
            camera: `Camera`,
            userIsNotPartOfaTeam: `Gebruiker maakt geen deel uit van een team`,
            stateAfterIssued: `Er zijn al openstaande verzendingen voor dit serienummer, klik
            om de details te zien.`,
            stateBeforeIssued: `Er is al een openstaande verzending voor dit serienummer, klik
            om de details te zien.`,
            nameOrSku: `naam of SKU`,
            partsDelivered: 'Parts delivered',
            boms: 'boms',
            issue: 'issues',
            clearFilter: 'Filter wissen',
            order: 'Bestellings',
            Addons: 'Add-ons',
            servers: 'Servers',
            projects: 'Projects',
            impersonatingUser: 'Je doet je voor als gebruiker: ',
            deactiveImpersonation:
                'Klik hier om de Impersonatiemodus te stoppen',
        },
        login: {
            loginButton: `Inloggen`,
            welcomeTitle: `Welkom bij easy4Device!`,
        },
        breadCrumb: {
            // this section need to be translated
            add: `Add`,
            Dashboard: `Dashboard`,
            Issues: `Issues`,
            BOM: `BOM`,
            Tickets: `Tickets`,
            Overview: `Overview`,
            OpenIssue: `Issue`,
            Dispatches: `Dispatches`,
            Archive: `Archive`,
            Images: `Images`,
            Fleet: `Fleet`,
            Vendor: `Vendor`,
            Serial: `Serial`,
            MicrosoftWindow: `Microsoft window`,
            MicrosoftChrome: `Microsoft chrome`,
            GoogleDomain: `Google domain`,
            Models: `Models`,
            Swaps: `Swaps`,
            Parts: `Parts`,
            Components: `Components`,
            PartCode: `Part codes`,
            Products: `Products`,
            Knowledge: `Knowledge`,
            Claims: `Claims`,
            RenumerationCheck: `Renumeration check`,
            ClaimInvoices: `Claim invoices`,
            Disputes: `Disputes`,
            Procurement: `Procurement`,
            PurchaseOrders: `Purchase Orders`,
            OrderConfirmations: `Order Confirmations`,
            Invoices: `Invoices`,
            Deliveries: `Deliveries`,
            RMA: `RMA`,
            Production: `Production`,
            SystemImages: `System Images`,
            Projects: `Projecten`,
            Orders: `Bestellingen`,
            Organizations: `Organizations`,
            Administrator: `Administrator`,
            Users: `Users`,
            DisabledUser: `Gehandicapte gebruikers`,
            Roles: `Roles`,
            Team: `Team`,
            System: `System`,
            ErrorLog: `Error log`,
            Api: `API's`,
            Definitions: `Definitions`,
            Causes: `Causes`,
            Create: `Create`,
            ServiceAccounts: `Service Accounts`,
            OrderLines: `Order lines`,
            Regions: `Regions`,
            ServiceCenter: `Service centers`,
            Teams: `Teams`,
            dellStock: `Dell stock`,
            settingManagement: `Setting & Management`,
            ImportDevices: `Import devices`,
            dataScheduler: `Data scheduler`,
            workbench: `Workbenches`,
            Test_Data_Diagnose: 'Test Data Diagnose',
            Test_Data_Dispatch_Shipment_Report:
                'Test Data Dispatch Shipment Report',
            Addons: 'Add-ons',
            Servers: 'Servers',
        },
        button: {
            export: `Exporteren`,
            import: `Importeren`,
            action: `Actie`,
            exportSerialQueue: `Export Serial Queue`,
            importSerialQueue: `Importeren Serial Queue`,
            delete: `Verwijderen`,
            disable: `Uitzetten`,
            enable: `Inschakelen`,
            add: `Toevoegen`,
            added: `Toegevoegd`,
            create: `Maken`,
            edit: `Aanpassen`,
            close: `Sluiten`,
            save: `Opslaan`,
            closeAndSave: `Sluiten en Opslaan`,
            next: `Volgende`,
            uploadPicture: `Uploaden Afbeelding`,
            capturePicture: `Vastleggen Afbeelding`,
            newAddress: `Nieuw adres`,
            cancel: 'Annuleren',
            yes: 'Ja',
            no: 'Nee',
            print: 'Afdrukken',
            accept: 'Accepteren',
            deny: 'Weigeren',
            confirm: 'Bevestigen',
            snooze: 'Snooze',
        },
        dialog: {
            deleteUser: `Gebruiker verwijderen`,
            deleteUserContent: `Weet u zeker dat u deze gebruiker wilt verwijderen?`,
            deletePicture: `Afbeelding verwijderen`,
            deletePictureContent: `Weet je zeker dat je deze foto wilt verwijderen?`,
            deleteDocument: `Document verwijderen`,
            deleteDocumentContent: `Weet u zeker dat u dit document wilt verwijderen?`,
            deletePictureReject: `Kan afbeelding niet verwijderen. Er is op dit moment ten minste één exemplaar van de afbeelding in gebruik.`,
            deleteOrderLine: `Orderregel verwijderen`,
            deleteOrderLineContent: `Weet u zeker dat u deze orderregel wilt verwijderen?`,
            cancel: `Annuleren`,
            delete: `Verwijderen`,
            close: `Sluiten`,
            confirm: `Bevestig`,
        },
        bodyContent: `Lichaamsinhoud`,
        profileSetting: {
            settingManagement: `Instellingen & beheer`,
            toggleDarkmode: `Toggle Darkmode`,
            signOut: `Uitloggen`,
            openSetting: 'Open instelling',
        },
        navbar: {
            User: `Gebruiker`,
            ACL: `ACL`,
            Roles: `Rollen`,
            Permissions: `Rechten`,
            Device: `Apparaat`,
            Fleet: `Vloot`,
            Overview: `Overzicht`,
            Vendor: `Verkoper`,
            Serials: `Serie`,
            Dispatches: `Verzendingen`,
            Component: `Component`,
            Product: `Product`,
            partCode: `Onderdeelcode`,
            inbox: `Postvak IN`,
            starred: `Met ster`,
            sendEmail: `E-mail verzenden`,
            drafts: `Concepten`,
            allMail: `Alle e-mail`,
            trash: `Prullenbak`,
            spam: `Spam`,
        },
        message: {
            authenticationFail: `Verificatie mislukt`,
            waitingTitle: `Bedankt voor het aanvragen van een account.`,
            login_disabled_account: `Uw account is uitgeschakeld. Neem contact op met uw systeembeheerder.`,
            login_rejected_account: `Uw account is afgewezen. Neem contact op met uw systeembeheerder.`,
            login_pending_account: `Uw account wacht momenteel op goedkeuring door de systeembeheerder.`,
            sent_request_approval: `Bedankt voor het aanvragen van een account. Uw account wacht momenteel op goedkeuring door de systeembeheerder.`,
            approved: `Dit account is goedgekeurd`,
            rejected: `Dit account is afgewezen`,
            resend: `Dit account is opnieuw uitgenodigd.`,
            successInviteUser: `Gebruiker succesvol uitgenodigd.`,
            somethingWrong: `Oeps! Er is iets fout gegaan. Probeer het later opnieuw.`,
            existedUser: `Bestaande gebruiker:`,
            Notfound: `Geen resultaten gevonden`,
            deleteUser: `Deze gebruiker is succesvol verwijderd`,
            createCauseSuccessfully: `De oorzaak is succesvol aangemaakt`,
            updateCauseSuccessfully: `De oorzaak is succesvol geüpdatet`,
            disableAccount: `Deze gebruiker is uitgeschakeld`,
            userNotTeam:
                'Deze gebruiker zit in geen enkel team. Voeg eerst een team toe, daarna kunt u goedkeuren.',
            setTeamForUserSuccessful:
                'Gebruiker is succesvol aan het team toegevoegd.',
            setTeamForUserFailed:
                'Gebruiker aan het team toewijzen is mislukt. Probeer het opnieuw.',
            activeTeamSuccessful: 'Actief team succesvol gewisseld.',
            activeTeamFailed:
                'Het wisselen van het actieve team is mislukt. Probeer het opnieuw.',
            removeTeamSuccessful: 'Team succesvol verwijderd.',
            removeTeamFailed:
                'Het verwijderen van het team is mislukt. Probeer het opnieuw.',
            userNotMember: 'Deze gebruiker is geen lid van dit team.',
            disableSchedule: `Deze planner is uitgeschakeld`,
            addSerialSuccessfully: `Serienummer toegevoegd `,
            searchSerial: `Geen serienummer gevonden voor `,
            updateSuccess: `Update succesvol`,
            createSuccess: `Succesvol aanmaken`,
            importSuccess: `Import successfully`,
            importFailed: `Import failed`,
            noTeam: 'Gebruiker is nog niet toegevoegd aan een team',
            refreshSucess: `Vernieuwen geslaagd`,
            refreshFailed: `Vernieuwen mislukt`,
            you_do_not_have_permission: `U heeft geen toestemming om toegang te krijgen tot deze bron.`,
            diagnosticIncorrect: `Diagnostisch ID onjuist. Controleer opnieuw!`,
            attachSuccess: `Bijvoegen van onderdeelcode aan model gelukt!`,
            attachFailed: `Bijvoegen van onderdeelcode aan model mislukt!`,
            data_not_found: `Gegevens niet gevonden`,
            selectCamera: `Selecteer camera`,
            dashboard: {
                scanSerial: {
                    notFoundTicket: 'Geen ticket gerelateerd aan deze serie.',
                    notNewStateTicket: "Geen ticket met state='NEW' gevonden.",
                    notFoundSerial: 'Dit serienummer is ongeldig.',
                },
            },
            newVersionAvailable: 'Nieuwe versie beschikbaar',
            newVersionBody:
                'Er is een nieuwe versie van de app beschikbaar. Druk op Bevestigen om opnieuw te laden',
            ppidAttemptedCharacter:
                'De PPID-poging tot tekenlimiet accepteert slechts 20 tot 23 tekens',
            terminateImpersonateMode:
                'Autorisatie mislukt. Imitatiemodus is gestopt.',
        },
        pages: {
            setting: {
                settingManagement: `Instellingen`,
                locale: `Locale`,
                timezone: `Timezone`,
            },
            userTeam: {
                id: 'ID kaart',
                name: 'Naam',
                description: 'Beschrijving',
                serviceCenter: 'Servicecentrum',
                created_at: 'Gemaakt bij',
                active: 'Actief',
                action: 'Actie',
            },
            user: {
                id: `ID`,
                user: `Gebruiker`,
                status: `Status`,
                role: `Rol`,
                search: `Zoeken`,
                invite: `Uitnodigen`,
                fullname: `Volledige naam`,
                email: `E-mail`,
                all: `Allemaal`,
                disabledDate: `Uitgeschakelde datum`,
                team: `Team`,
                workbench: `Werkbank`,
                userDetail: {
                    teams: 'Teams',
                    noTeams: 'No Teams',
                    workbench: `Werkbank`,
                    organizationalUnit: `Organisatie-eenheid`,
                    resetPassword: `wachtwoord opnieuw instellen`,
                    updateUser: `gebruiker bijwerken`,
                    userData: `Gebruikersgegevens`,
                    emailAddress: `E-mailadres`,
                    phone: `Telefoon`,
                    security: `Beveiliging`,
                    passwordSetting: `Wachtwoordinstelling`,
                    groups: `Groepen`,
                    noGroup: `Geen groep`,
                    approve: `goedkeuren`,
                    reject: `Weigeren`,
                    pending: 'in afwachting',
                    active: 'actief',
                    disabled: 'invalide',
                    rejected: 'afgewezen',
                    invited: 'uitgenodigd',
                    all: `allemaal`,
                    resend: `Opnieuw verzenden`,
                    role: `Rol`,
                    save: `Opslaan`,
                    timezone: `Timezone`,
                    joinTeam: 'Word lid van team',
                    switchTeam: 'Actief team wisselen',
                    confirmActiveTeam: `Wil je het actieve team naar team {{team}} overzetten?`,
                    confirmRemoveTeam: `Wil je deze gebruiker uit team {{team}} verwijderen?`,
                    impersonate: 'Imiteren',
                },
                inviteUser: {
                    inviteUser: `Gebruiker uitnodigen`,
                    invite: `Uitnodigen`,
                },
                roleUser: {
                    admin: `Beheerder`,
                    user: `Gebruiker`,
                    all: `Allemaal`,
                },
                roleDetail: {
                    name: `Naam`,
                    guard_name: `Naam bewaker`,
                    permissions: `Rechten`,
                    save: `Opslaan`,
                },
            },
            device: {
                intune: {
                    tenant_id: 'Tenant ID',
                    tenant_domain: 'Tenant Domain',
                    bios: 'Bios',
                    profile: 'Profile',
                    ip_source: 'IP',
                    created_at: 'Created at',
                },
                fleet: {
                    table: {
                        Vendor: `Verkoper`,
                        All: `Alle`,
                        Category: `Categorie`,
                        Family: `Familie`,
                        Name: `Naam`,
                        Oldest: `Oudste`,
                        Newest: `Nieuwste`,
                        In_warranty: `In garantie`,
                        Out_of_warranty: `Buiten garantie`,
                        Expired_this_year: `Dit jaar verlopen`,
                        Active: `Actief`,
                        Stock: `Voorraad`,
                        Other: `Anders`,
                        count: `#`,
                        Tags: `Labels`,
                    },
                },
                serial: {
                    overview: {
                        Vendor: `Verkoper`,
                        Serial: `Seriële`,
                        All: `Alle`,
                        Family: `Familie`,
                        Name: `Naam`,
                        Shipdate: `Verzenddatum`,
                        State: `Staat`,
                        Warranty_End: `Garantie Einde`,
                        Entitlements: `Rechten`,
                        brand: `Merk`,
                        initial_state: 'Initial State',
                    },
                    detail: {
                        Vendor: `Verkoper`,
                        Family: `Familie`,
                        Model: `Model`,
                        lastUpdate: `Laatste update`,
                        latestEntitlementEndDate: `Laatste einddatum van het recht`,
                        Description: `Beschrijving`,
                        Supplier: `Leverancier`,
                        Invoice: `Factuur`,
                        localShipping: `Lokale verzending`,
                        deviceInformation: `Apparaatinformatie`,
                        shipDate: `Verzenddatum`,
                        countryCode: `Landcode`,
                        productCode: `Productcode`,
                        name: `Naam`,
                        Category: `Categorie`,
                        notebook: `Easy4u Notebook-ID`,
                        orderbuil: `Orderopbouw`,
                        localChanel: `Lokaal kanaal`,
                        productSKU: `Product SKU`,
                        createdAt: `Gemaakt bij`,
                        entitlements: `Rechten`,
                        Id: `Id`,
                        itemNumber: `Item nummer`,
                        type: `Type`,
                        slaCode: `Sla-code`,
                        slaGroup: `Sla Groep`,
                        group: `Groep`,
                        start: `Begin`,
                        end: `Einde`,
                        description: `Beschrijving`,
                        Components: `Componenten`,
                        partNumber: `Onderdeelnummer`,
                        itemDescription: `Artikelbeschrijving`,
                        serializable: `Serialiseerbaar`,
                        Revision: `Herziening`,
                        Returnable: `Retourneren`,
                        needSerial: `Serieel nodig`,
                        replaceableParts: `Vervangbare onderdelen`,
                        // need to be translated from this line till end of category
                        code: `Code`,
                        procurementData: `Inkoop Gegevens`,
                        orderNr: `Order Nummer`,
                        vendorId: `Vendor Id`,
                        vendorSku: `Vendor SKU`,
                        supplierSku: `Supplier SKU`,
                        trcSku: `TRC SKU`,
                        invoicedAt: `Invoiced at`,
                        shipping: `Transport`,
                        updatedAt: `Aangepast op`,
                        procurementInvoiceId: `Inkoopfactuur Id`,
                        procurementDispatchId: `Pakbon Id`,
                        procurementDispatchLineId: `Pakbon regel Id`,
                        processed: `Verwerkt`,
                        productNumber: `Product nummer`,
                        unitPrice: `Stukprijs`,
                        purchaseOrder: `Inkoop order`,
                        pkid: `PKID`,
                        express_servicecode: `Express service code`,
                        attachments: 'Attachments',
                        action: 'Actie',
                        qty: 'Aantal',
                        productName: `Product naam`,
                        tags: `Product tag(s)`,
                        issues: `Issues`,
                        ticket_id: 'Ticket id',
                        service_id: 'Service id',
                        service_center_tid: 'Service center Tag',
                        brand: 'Merk',
                        status: 'Status',
                        state: 'State',
                        school_id: 'School id',
                        device_user_id: 'Device user id',
                        contract_id: 'Contract id',
                        damaged: 'Beschadigd',
                        damage_description: 'Schade omschrijving',
                        powers_on: 'Gaat aan',
                        cause_tid: 'Oorzaak tag',
                        accessories: 'Accessoires',
                        liquid_damage: 'Vloeistof schade',
                        loaner_id: 'Leen ID',
                        webshop_id: 'Webshop id',
                        image_version_tid: 'Image versie tag',
                        language: 'Taal',
                        entity_tid: 'Entiteit tag',
                        reject_reason: 'Afkeur reden',
                        previous_id: 'Vorige id',
                        created_at: 'Gemaakt op',
                        serial: 'Serienummer',
                        save: 'Opslaan',
                        causes: 'Causes',
                        broken: `Broken`,
                        deviceIntuneCheck: 'Intune Check',
                        deviceDispatches: `Dispatches`,
                        malicious_damage: 'Opzettelijke Schade',
                        condition: 'Staat',
                        device_information: 'Apparaat informatie',
                        additionalDescription: 'Aanvullende Beschrijving',
                        additionalDescriptionRequiredMsg:
                            'Aanvullende beschrijving is een verplicht veld.',
                    },
                },
                importDevices: {
                    // section need tobe translated
                    pageTitle: `Import devices`,
                    importSerialQueue: `Import Serial Queue`,
                    importDellCoep: `Import Dell COEP Export`,
                },
            },
            diagnostic: {
                // section need tobe translated
                name: 'Naam',
                id: 'id',
                info: 'Info',
                value: 'Waarde',
                warranty_code: 'Warranty Code',
                warranty_start: 'Warranty Start',
                warranty_end: 'Warranty End',
                sphere: 'Sphere',
                ticket_details: 'Ticket Details',
                pictures: 'Pictures',
                software_diagnostics: 'Software Diagnostics',
                bios_version: 'BIOS Version',
                bios_info: 'BIOS-informatie',
                bios_vendor: 'BIOS-leverancier',
                bios_date: 'BIOS-datum',
                bios_revision: 'BIOS-revisie',
                battery_state: 'Batterijstatus',
                battery_status: 'Batterij status',
                battery_capacity: 'Batterij capaciteit',
                battery_capacity_level: 'Niveau batterijcapaciteit',
                power_check_not_found: 'Vermogenscontrole niet gevonden!',
                HID_result: 'HID-resultaat',
                HID_check_not_found: 'HID-controle niet gevonden!',
                device: 'Device',
                yes: 'Yes',
                no: 'No',
                powers_on: 'Powers On',
                liquid_damage: 'Liquid Damage',
                liquid_damage_total_loss: 'Liquid Damage Total Loss',
                accidental_damage: 'Accidental Damage',
                software_issue: 'Software Issue',
                bios_updated: 'Updates Performed',
                malicious_damage: 'Malicious Damage',
                complaint_found: 'Complaint Found',
                service_id: 'Service Id',
                device_sku: 'Device Sku',
                image_version: 'Image Version',
                device_model: 'Apparaat model',
                serial: 'Serienummer',
                diagnosticRootCause: `Diagnostic Root Cause`,
                diagnosticsDescription: `Diagnostics Description`,
                liquid_damage_change: `Liquid Damage Change`,
                technicianComments: `Technician Comments`,
                additionalDescription: `Additional Description`,
                partToChange: `Part To Change`,
                issueLog: `Issue Log`,
                date: `Date`,
                startTime: `Start Time`,
                endTime: `End Time`,
                status: `Status`,
                description: `Description`,
                deviceHistory: `Device History`,
                issueNo: `Issue No`,
                problem: `Problem`,
                partCode: `Part Code`,
                partName: `Part Name`,
                partType: `Part Type`,
                warrantyCode: `Warranty Code`,
                warrantyDescription: `Warranty Description`,
                customer_complaint: `Customer Complaint`,
                add_to_dispatch: 'Toevoegen aan verzend',
                ticketNumber: 'Ticket nummer',
                cosmetic_damage: 'Cosmetic Damage',
                isRequire: ' is een verplicht veld!',
                warranty: `Garantie`,
                accidental: 'Per ongeluk',
                malicious: 'Kwaadwillend',
                conditionRequired: 'Staat is een verplicht veld',
                conditionNotMatch: `De voorwaarde {{condition}} is vereist ten minste één keer vanwege de diagnostiek.`,
                esdLocation: 'Esd Location',
                invalidEsdLocation: 'Ongeldige ESD Location',
                uploadPictures: `Foto's uploaden`,
                uploadPicture: 'Upload foto',
                addPictures: `Foto's toevoegen`,
                serviceCenter: 'Servicecentrum',
                entitlements: 'Rechten',
                additionalDescriptionRequiredMsg:
                    'Aanvullende beschrijving is een verplicht veld.',
                confirmPopupTitle: 'Diagnostische bevestiging van het probleem',
                scanEsd: 'Scan ESD',
                button: {
                    totalLoss: 'Totaalverlies',
                    moveToQC: 'Verplaatsen naar QC',
                },
                BIOS: `BIOS`,
                schoolID: `School ID`,
                projectID: `Project ID`,
                timestamp: `Timestamp`,
                lastImageBefore: `Laatste afbeelding voordat de reparatie is voltooid`,
                firstImageAfter: `Eerste afbeelding nadat de reparatie is voltooid`,
            },
            issues: {
                // section need to be translated
                rackScans: {
                    scan: {
                        overview: {
                            id: `Id`,
                            name: `Name`,
                            serviceCenter: `Service Center`,
                            team: `Team`,
                            createBy: `Created by`,
                            created: `Created`,
                            updated: `Last Update`,
                        },
                        create: {
                            location: `Location`,
                            ticket: `Ticket`,
                            save: `Save`,
                            name: `Name`,
                            serviceCenter: `Service center`,
                            team: `Team`,
                        },
                        detail: {
                            rackScan: `Rack Scan`,
                            rackScanLine: `Rack scan line`,
                            id: `Id`,
                            location: `Location`,
                            ticket: `Ticket`,
                            action: `Action`,
                        },
                    },
                    ticket: {
                        overview: {
                            ticket: `Ticket`,
                            esdLocation: `Esd location`,
                            lastScanLocation: `Last scan location`,
                            team: `Team`,
                            ticketCreated: `Ticket created`,
                            status: `Status`,
                            state: `State`,
                            dispatches: `Dispatches`,
                            updatedAt: `Updated at`,
                            dispatchStatus: `Dispatch status`,
                            ticketStatus: 'Ticketstatus',
                        },
                    },
                },
                create: {
                    ticket_id: 'Ticket id',
                    esd_location: 'ESD Location',
                    name: 'Name',
                    description: 'Description',
                    service_id: 'Service id',
                    service_center_tid: 'Service center tid',
                    brand: 'Brand',
                    status: 'Status',
                    state: 'State',
                    school_id: 'School id',
                    device_user_id: 'Device user id',
                    contract_id: 'Contract id',
                    damaged: 'Damaged',
                    damage_description: 'Damage description',
                    powers_on: 'Powers on',
                    cause_tid: 'Cause tid',
                    accessories: 'Accessories',
                    liquid_damage: 'Liquid damage',
                    loaner_id: 'Loaner id',
                    webshop_id: 'Webshop id',
                    image_version_tid: 'Image version tid',
                    language: 'Language',
                    entity_tid: 'Entity tid',
                    reject_reason: 'Reject reason',
                    previous_id: 'Previous id',
                    created_at: 'Created at',
                    serial: 'Serial',
                    save: 'Save',
                    diagnostic: `View Diagnostic`,
                    repair: 'Repair',
                    bom_state: 'Bom state',
                    cancelIssue: 'Annuleren Issue',
                    yes: 'Ja',
                    no: 'Nee',
                    confirmCancel:
                        'Weet u zeker dat u dit issue wilt annuleren?',
                    process_supervisor_issue: 'Ga Door Met Het Probleem',
                },
                overview: {
                    id: `Id`,
                    name: `Name`,
                    description: `Description`,
                    ticket_id: 'Ticket id',
                    esd_location: 'ESD Location',
                    service_id: 'Service id',
                    service_center_tid: 'Service center tid',
                    brand: 'Brand',
                    status: 'Status',
                    state: 'State',
                    school_id: 'School id',
                    device_user_id: 'Device user id',
                    contract_id: 'Contract id',
                    damaged: 'Damaged',
                    damage_description: 'Damage description',
                    powers_on: 'Powers on',
                    liquid_damage: 'Liquid damage',
                    cause_tid: 'Cause tid',
                    accessories: 'Accessories',
                    loaner_id: 'Loaner id',
                    webshop_id: 'Webshop id',
                    image_version_tid: 'Image version tid',
                    language: 'Language',
                    entity_tid: 'Entity tid',
                    reject_reason: 'Reject reason',
                    previous_id: 'Previous id',
                    created_at: 'Created at',
                    updated_at: 'Updated at',
                    issues: 'Issues',
                    add: 'Add',
                    add_diagnostic: 'Add diagnostic',
                    action: 'Action',
                    scan_serial: 'Scan serial',
                    general: `General`,
                    diagnostics: `Diagnostics`,
                    repair: `Repair`,
                    qualityCheck: `Quality Check`,
                    report: `Report`,
                    triage: `Triage`,
                    image: `Images`,
                    wo_status: 'Work order status',
                    serial: 'Serial',
                    issue: 'Issue',
                    ticket_status: 'Ticket status',
                    accidential_damage_warranty: 'Accidential damage warranty',
                    battery_warranty_until: 'Battery warranty until',
                    subcription_end_date: 'Subcription end date',
                    not_expert_right_message:
                        'U heeft geen bevoegdheid om dit ticket af te handelen. Meld u alstublieft bij uw supervisor.',
                    has_expert_right_message:
                        'Dit ticket heeft uw expertise nodig. Gelieve het zo spoedig mogelijk af te handelen.',
                    accept_qc: 'QC Accepteren',
                    device_is_total_loss: 'Apparaat is Totaal Verlies',
                    create_new_issue: 'Een nieuw probleem maken',
                    create_new_issue_successfully:
                        'Een nieuw probleem is succesvol gemaakt.',
                    accept_qc_successfully:
                        'De QC van deze ticket is voltooid.',
                    make_device_is_loss_successfully:
                        'Dit apparaat is onherstelbaar beschadigd.',
                    error_when_proceeding:
                        'Er is een fout opgetreden bij het verwerken van uw verzoek.',
                    save: 'Redden',
                    cancel: 'Annuleren',
                    comment: 'Opmerking',
                    error_not_found_diagnostic:
                        'Diagnose niet gevonden of u heeft geen toestemming om deze pagina te bekijken.',
                    deny_diagnose_message:
                        'Probleem mag niet worden gediagnosticeerd.',
                    deny_repair_message:
                        'Reparatie is nog niet gestart. Voltooi het diagnose- en bestelproces voor onderdelen om de reparatie te starten.',
                    deny_qc_message: 'Het probleem mag niet in QC voorkomen.',
                    coming_soon: 'Binnenkort beschikbaar',
                    start_triage: 'Triage starten',
                    deny_image_message:
                        'Het probleem mag niet in Images voorkomen.',
                },
                qualityCheck: {
                    qcForm: {
                        qcForm: 'QC-formulier',
                        miscellaneous: 'Gemengd',
                        comment: 'Opmerking',
                        comments: 'Opmerkings',
                        photos: "Foto's",
                        uploadPhoto: 'Upload foto',
                        capturePhoto: 'Foto vastleggen',
                        ticketDetails: 'Ticketgegevens',
                        issueDetails: 'Probleemdetails',
                        entitlementDetails: 'Gegevens over rechten',
                        issueDiagnose: 'Probleemdiagnose',
                        issueRepair: 'Kwestie reparatie',
                        serviceSticker:
                            'Service sticker present & in good condition',
                        updatedBIOS: 'Updated BIOS',
                        housing: 'Housing',
                        screws: 'Screws',
                        hinges: 'Hinges',
                        dcIn: 'DC-in',
                        opratingSystem: 'Operating System',
                        battery: 'Battery',
                        display: 'Display',
                        touchScreen: 'Touchscreen',
                        tabletMode: 'Tablet mode',
                        keyboard: 'Keyboard',
                        touchPad: 'Touch pad',
                        webcam: 'Front facing camera (FFC)',
                        wfc: 'World facing camera (WFC)',
                        microphone: 'Microphone',
                        speaker: 'Sound through speakers',
                        audioJack: 'Sound through audio jack',
                        otherButton: 'Other buttons (if present)',
                        wifi: 'WiFi',
                        lanPort: 'LAN port',
                        securityBoot:
                            'Security Boot (Agree = Enabled, Disagree = Not enabled)',
                        usbA: 'USB-A ports',
                        usbC: 'USB-C ports',
                        hdmiPort: 'HDMI Port',
                        domainEnrollment: 'Domain enrollment',
                        systemCleaned: 'System cleaned',
                        issueQualityCheckSummary:
                            'Samenvatting kwaliteitscontrole probleem',
                        overruleQualityCheck: 'Kwaliteitscontrole negeren',
                        tableHeaders: {
                            section: 'Sectie',
                            agree: 'Akkoord',
                            disagree: 'Niet akkoord',
                            na: 'N/A',
                        },
                        ifAvailable: ' (Indien beschikbaar)',
                        message: {
                            success: 'Kwaliteitscontrole succes!',
                            failed: 'Kwaliteitscontrole mislukt!',
                            assign2supervisor:
                                'Kwaliteitscontrole voor de 2e keer mislukt! Status gewijzigd in ASSIGNED_TO_SUPERVISOR!',
                        },
                    },
                },
                repair: {
                    titleCannotRepair:
                        'Waarom kan deze reparatie niet worden voltooid?',
                    search: 'Zoek oorzaak',
                    pictures: 'Fotos',
                    softwareDiagnostics: 'Software Diagnostics',
                    biosVersion: '- Bios-versie',
                    batteryState: '- Batterijstatus',
                    rootCauses: 'Oorzaken',
                    diagnosticDescription: 'Diagnostische beschrijving',
                    technicianComments: 'Technische opmerkingen',
                    partsToChange: 'Te vervangen onderdelen',
                    technicianCommentsRepair:
                        'Technische opmerkingen (Reparatie)',
                    additionalDescriptionTooltip:
                        'Aanvullende beschrijving: {{AD}}',
                    removePartTable: {
                        receivedPartNumber: 'Ontvangen onderdeelnummer',
                        partCode: 'Onderdeelcode',
                        partName: 'Onderdeelnaam',
                        removedPartName: 'Verwijderd onderdeelnummer',
                        removedPpid: 'Verwijderde PPID',
                        notReplaced: 'Niet vervangen?',
                        doa: 'DOA',
                    },
                    button: {
                        cannotFinish: 'Reparatie kan niet worden voltooid',
                        cancel: 'Annuleren',
                        repair: 'Apparaat gerepareerd',
                        finish: 'Voltooien',
                    },
                    message: {
                        notFound: 'Geen hoofdoorzaken gevonden',
                        errorFromApi:
                            'Er is een fout opgetreden tijdens het ophalen van gegevens',
                        requiredComment: 'Opmerkingenveld is verplicht',
                        requiredAllFields: 'Vul alle verplichte velden in!',
                        duplicatedPpid: 'Dubbele PPID!',
                        samePpid: 'Is hetzelfde als ontvangen PPID',
                        createdDoaIssueSuccessfully:
                            'Een DOA-melding is succesvol aangemaakt.',
                    },
                },
                triage: {
                    title: 'Triage voor probleem {{issueId}}',
                    message: 'Voer alstublieft Software Diagnostiek uit',
                },
                images: {
                    title: 'Afbeelding voor probleem {{issueId}}',
                },
            },
            dispatches: {
                dispatch: {
                    overview: {
                        id: `Id`,
                        Serial: `Seriële`,
                        dispatchId: `Verzend-ID`,
                        Code: `Code`,
                        State: `State`,
                        Status: `Status`,
                        Branch: `Tak`,
                        Contact: `Contact`,
                        DPS: `DPS`,
                        Waybill: `Vrachtbrief`,
                        Created: `Gemaakt`,
                        Updated: `Updated`,
                        add_dispatch: `Add Dispatch`, // to be translated
                        reference: `Reference`,
                        notes: `Notes`,
                        descriptionOfProblem: `Description of problem`,
                        createdBy: `Created by`,
                        serviceCenter: `Service Center`,
                        errorMessage: `Error Message`,
                        action: `Action`,
                        dispatchNumber: `Dispatch number`,
                        esdLocation: `ESD-Locatie`,
                        ticket: `Ticket`,
                        User: `Gebruiker`,
                        CreatedManually: `Handmatig gemaakt`,
                    },
                    detail: {
                        Dispatch: `Verzending`,
                        showALl: `Alles tonen`,
                        hideAll: `Alles verbergen`,
                        ServiceTagDetails: `Servicetag-details`,
                        ModelDescription: `Modelbeschrijving`,
                        ServiceTag: `Servicetag`,
                        Warranty: `Garantie`,
                        InWarranty: `In garantie`,
                        OutOfWarranty: `Buiten garantie`,
                        DaysUntilExpiration: `Dagen tot Expiratie`,
                        WorkOrderInformation: `Werkorderinformatie`,
                        Attachments: `Attachments`,
                        Customer: `Klant`,
                        Group: `Groep`,
                        Technician: `Technicus`,
                        ServiceLevelAgreement: `Service Level Agreement`,
                        Entitlement: `Rechten`,
                        CustomerIdentifiedDamage: `Door de klant geïdentificeerde schade`,
                        DispatchInformation: `Verzendinformatie`,
                        Reference: `Referentie/PO# (optioneel)`,
                        ScheduleAlternateDispatchDate: `Plan alternatieve verzenddatum`,
                        DispatchNumber: `Verzendnummer`,
                        ContactInformation: `Contactgegevens`,
                        PrimaryContactName: `Naam primaire contactpersoon`,
                        PrimaryContactPhone: `Primair Contact Telefoon #`,
                        PrimaryContactPhoneExt: `Primair Contact Telefoon Ext`,
                        AdditionalEmail: `Aanvullende e-mail`,
                        AltContactName: `Alt. Naam contactpersoon`,
                        AltContactEmail: `Alt. Contact E-mail`,
                        AltContactPhone: `Alt Contact Telefoon #`,
                        AltContactPhoneExt: `Primair Contact Telefoon Ext`,
                        PartsDetail: `Onderdelendetail`,
                        PartCode: 'Onderdeelcode',
                        PartDescription: `Onderdeelbeschrijving`,
                        PartCategory: `Onderdeelcategorie`,
                        SerialNumber: `PPID/serienummer`,
                        Quantity: `Hoeveelheid`,
                        CRU: `CRU/FRU`,
                        Component: `Onderdeel`,
                        Subcomponent: `Subcomponent`,
                        AddedBy: `Toegevoegd door`,
                        EstimatedDeliveryDate: `Geschatte leveringsdatum`,
                        WaybillNumber: `Vrachtbriefnummer`,
                        DpsNumber: 'DPS Number',
                        ProofOfDelivery: `Bewijs van levering`,
                        RequestTechnician: `Vraag een monteur ter plaatse`,
                        NoteTechnician: `Opmerking voor on-site technicus`,
                        ShippingInformation: `Verzendgegevens`,
                        AddressName: `Adresnaam`,
                        Address: `Adres`,
                        City: `Stad`,
                        StateProvince: `Staat / Provincie`,
                        ZipPostalCode: `Zip / Postcode`,
                        Location: `Locatie`,
                        TimeZone: `Tijdzone`,
                        DeliveryInformation: `Bezorgingsinformatie`,
                        Status: `Status`,
                        Carrier: `Vervoerder`,
                        LastActivity: `Laatste Activiteit`,
                        StatusHistory: `Statusgeschiedenis`,
                        UpdateTimeLocal: `Tijd bijwerken lokaal`,
                        StatusDescription: `Statusbeschrijving`,
                        UserName: `Gebruikersnaam`,
                        NoPartCode: `Geen onderdeelcodes`,
                        partNo: `#een deel`,
                        dispatchCode: `Dispatch code`,
                        region: `Region`,
                        serviceCenter: `Service center`,
                        dellTechDirectConnector: `Dell Tech Direct Connector`,
                        closeThisDispatch: `Sluit deze verzending`,
                        confirmCloseDispatch: `Weet u zeker dat u deze verzending wilt sluiten? Dit kan niet ongedaan gemaakt worden`,
                    },
                    add: {
                        // section need to be translated
                        confirmAddDispatchContent: `Do you want to add dispatch for the Dell serial {{serial}}?`,
                        confirmAddDispatch: `Add Dispatch Confirmation`,
                        scanSerial: `Scan Serial`,
                        pageName: `Add Dell Dispatch`,
                        serviceCenter: `Service center`,
                        serviceCenterInput: `Select service center`,
                        service_center: `Service center`,
                        partCodes: `Part codes`,
                        description: `Troubleshooting Note`,
                        descriptionOfProblem: `Description Of Problem`,
                        troubleshootingNote: `Troubleshooting Note`,
                        filter_part_type: `Filter part type`,
                        filter_part_code: `Filter part code`,
                        filter_part_number: `Filter part number`,
                        filter_sku: `Filter SKU`,
                        dispatch_info: `Additional Dispatch Info`,
                        options: `Options`,
                        yes: `Yes`,
                        no: `No`,
                        request_complete_care: `Request Complete Care`,
                        request_return_to_depot: `Request Return To Depot`,
                        reference: `Reference ( Ticket no @ esd location )`,
                        rootCauses: `Root Causes`,
                        attachments: 'Attachments',
                        uploadPictures: `Foto's uploaden`,
                        uploadPicture: 'Upload foto',
                        addPictures: `Foto's toevoegen`,
                    },
                },
            },
            components: {
                // section need to be translated
                id: `Id`,
                Name: `Name`,
                PartNumber: `Part Number`,
                ItemNumber: `Item Number`,
                Description: `Description`,
                Code: `Part Code`,
                PartCode: `Part Name`,
                Type: `Type`,
                deviceName: `Device name`,
                count: `Quantity`,
                name: `Device name`,
                itemDescription: `Item Description`,
                oldest: `Oldest`,
                newest: `Newest`,
                inWarranty: `In warranty`,
                outOfWarranty: `Out of warranty`,
                expireInYearWarranty: `Expired this year`,
                active: `Active`,
                stock: `Stock`,
                other: `Other`,
                removeComponent: `Onderdeel verwijderen`,
                action: `Actie`,
                linkComponent: `Linkcomponent`,
                addComponents: `Componenten toevoegen`,
                voteComponent: `Stemcomponent`,
                areUSure: `Weet u zeker dat u dit onderdeel wilt ontkoppelen?`,
            },
            product: {
                partCode: {
                    overview: {
                        Code: `Code`,
                        Name: `Naam`,
                        Type: `Type`,
                        Model: `Modellen`,
                    },
                },
            },
            procurement: {
                purchaseOrder: {
                    // section need to be translated
                    overview: {
                        purchaseOrder: `Purchase order`,
                        supplier: `Supplier`,
                        entity: `Entity`,
                        deliveryStatus: `Delivery Status`,
                        contactName: `Contact name`,
                        orderDate: `Order Date`,
                        expectedDeliveryDate: `Expect Delivery Date`,
                        action: `Action`,
                    },
                    detail: {
                        id: `Id`,
                        productId: `Product Id`,
                        productNr: `Product Nr`,
                        price: `Price`,
                        qty: `Qty`,
                        delivered: `Delivered`,
                        supplierCode: 'Supplier Code',
                    },
                    orderLines: {
                        PurchaseOrderLine: `Purchase Order Line`,
                        PurchaseOrder: `Purchase Order`,
                        PurchaseOrderEntitlement: `Entitlement`,
                        PurchaseOrderSpecification: `Specification`,
                    },
                },
                orderConfirmation: {
                    overview: {
                        supplier: `Leverancier`,
                        ourPO: `Onze P.O`,
                        supplierOrder: `Leverancierbestelling`,
                        orderDate: `Besteldatum`,
                        deliveryStatus: `Bezorgstatus`,
                        customer: `Klant`,
                        information: `Informatie`,
                        city: `Stad`,
                        amountExclVat: `Bedrag excl btw`,
                        receivedAPI: `API ontvangen`,
                        status: `Status`,
                        responseCode: `Response code`,
                    },
                },
                delivery: {
                    overview: {
                        supplier: `Leverancier`,
                        route: `Route`,
                        dispatchNumber: `Verzendnummer`,
                        dispatchDate: `Verzenddatum`,
                        toName: `Naam`,
                        toCity: `Naar de stad`,
                        toCountry: `Naar Land`,
                        customer: `Klant`,
                        receivedAPI: `API ontvangen`,
                    },
                    detail: {
                        orderedQty: `besteld aantal`,
                        sku: `SKU`,
                        supplierSku: `Leveranciersku`,
                        description: `Beschrijving`,
                        vendorSku: `Verkoper-sku`,
                        qty: `Aantal`,
                    },
                },
                invoice: {
                    overview: {
                        supplier: `Leverancier`,
                        invoiceNumber: `Factuurnummer`,
                        invoiceDate: `Factuurdatum`,
                        invoiceExpirationDate: `Vervaldatum`,
                        toNumber: `Naar nummer`,
                        toName: `Naam`,
                        toCity: `Naar de stad`,
                        toCountry: `Naar Land`,
                        customer: `Klant`,
                        receivedAPI: `API ontvangen`,
                        amountInclude: `Bedrag inclusief`,
                        amountExclude: `Bedrag exclusief`,
                    },
                },
            },
            administrator: {
                dataScheduler: {
                    // section need to be translated
                    overview: {
                        name: `Name`,
                        save: `Save`,
                        id: `Id`,
                        cron: `Cron`,
                        method: `Method`,
                        database: `Database`,
                    },
                    create: {
                        name: `Name`,
                        queryStatement: `Query Statement`,
                        emails: `Emails`,
                        storagePath: `Storage Path`,
                        cronPattern: `Cron Pattern`,
                        save: `Save`,
                        enable: `Enable`,
                        disable: `Disable`,
                        database: `Database`,
                    },
                },
                roles: {
                    permissions: `Rechten`,
                    name: `Naam`,
                    users: `Gebruikers`,
                    create: `Creëren`,
                    guard_name: `Guard Name`,
                },
                roleDetail: {
                    // section need to be translated
                    clientTokens: {
                        id: `ID kaart`,
                        name: `Naam`,
                        guard_name: `Guard Name`,
                        permissions: `Permissions`,
                        save: `Save`,
                        data_not_found: `Gegevens niet gevonden`,
                    },
                },
                serviceAccounts: {
                    id: `Id`,
                    name: `Name`,
                    secret: `Geheim`,
                    revoked: `Revoked`,
                    created_at: `Created At`,
                    updated_at: `Updated At`,
                    create: `Create`,
                    actions: `Actions`,
                    description: `Description`,
                    contact: `Contact`,
                    contact_email: `Contact Email`,
                },
                serviceAccountDetail: {
                    // section need to be translated
                    name: `Name`,
                    client_id: `Client Id`,
                    client_secret: `Client Secret`,
                    revoked: `Revoked`,
                    revoke: `Revoke`,
                    created_at: `Created At`,
                    updated_at: `Updated At`,
                    create: `Create`,
                    save: `Save`,
                    actions: `Actions`,
                    description: `Description`,
                    contact: `Contact`,
                    contact_email: `Contact Email`,
                    whitelist: `Whitelist`,
                    token: `Token`,
                    generate: `Generate`,
                    role: `Role`,
                },
                definition: {
                    causes: {
                        overview: {
                            id: `Id`,
                            tid: `tid`,
                            model: `Model`,
                            notes: 'Diagnose omschrijving',
                            name: 'Omschrijving',
                            createdAt: 'Gemaakt op',
                            updatedAt: `Updated at`, // to be translated
                            code: `Code`, // to be translated
                            type: `Type`, // to be translated
                            serializable: `Serializable`, // to be translated
                        },
                    },
                    regions: {
                        overview: {
                            id: `Id`,
                            name: `Name`, // to be translated
                            description: `Description`,
                            mainService: `Main service center`,
                            createdAt: 'Gemaakt op',
                            updatedAt: `Updated at`, // to be translated
                            region: `Region`, // to be translated
                            serviceCenter: `Service centers`, // to be translated
                            enabled: `Enabled?`,
                        },
                        edit: {
                            // section need to be translated
                            name: `Name`,
                            client_id: `Client Id`,
                            client_secret: `Client Secret`,
                            revoked: `Revoked`,
                            revoke: `Revoke`,
                            created_at: `Created At`,
                            updated_at: `Updated At`,
                            create: `Create`,
                            save: `Save`,
                            actions: `Actions`,
                            description: `Description`,
                            contact: `Contact`,
                            contact_email: `Contact Email`,
                            whitelist: `Whitelist`,
                            token: `Token`,
                            generate: `Generate`,
                            role: `Role`,
                            mainServiceCenter: `Main service center`,
                            dell_tech_direct_api_connector: `Dell Tech Direct API connector`,
                        },
                    },
                    serviceCenter: {
                        // section need to be translated
                        overview: {
                            id: `Id`,
                            name: `Name`,
                            description: `Description`,
                            contactPerson: `Contact person`,
                            address: `Address`,
                            region: `Region`,
                            createdAt: 'Gemaakt op',
                            updatedAt: `Updated at`,
                            serviceCenter: `Service center`,
                            separateTriage: `Separate Triage`,
                        },
                        detail: {
                            serviceCenter: `Service center`,
                            team: `Teams`,
                            warehouseSetting: 'Warehouse setting',
                            separateTriage: `Separate Triage`,
                        },
                        form: {
                            region: `Region`,
                            address: `Address`,
                            contactPerson: `Contact person`,
                            name: `Name`,
                            description: `Description`,
                            save: `Save`,
                            dellBranch: `Dell branch`,
                            phoneNumber: `Phone Number`,
                            dellEmailDirection: `Sent dispatch emails to contact person`,
                            Easy4UID: 'Easy4U ID',
                            manualDispatchBranch: 'Manual dispatch branch',
                        },
                    },
                    team: {
                        // section need to be translated
                        overview: {
                            id: `Id`,
                            name: `Name`,
                            description: `Description`,
                            contactPerson: `Contact person`,
                            avatar: `Avatar`,
                            createdAt: 'Gemaakt op',
                            updatedAt: `Updated at`,
                            serviceCenter: `Service center`,
                        },
                        detail: {
                            id: `Id`,
                            name: `Name`,
                            description: `Description`,
                            contactPerson: `Contact person`,
                            avatar: `Avatar`,
                            createdAt: 'Gemaakt op',
                            updatedAt: `Updated at`,
                            serviceCenter: `Service center`,
                            teamMember: `Team members`,
                        },
                        create: {
                            contactPerson: `Contact person`,
                            name: `Name`,
                            description: `Description`,
                            save: `Save`,
                            serviceCenter: `Service center`,
                        },
                        edit: {
                            contactPerson: `Contact person`,
                            name: `Name`,
                            description: `Description`,
                            save: `Save`,
                            serviceCenter: `Service center`,
                        },
                    },
                    address: {
                        // section need to be translated
                        create: {
                            name: `Name`,
                            street_1: `Street 1`,
                            street_2: `Street 2`,
                            street_3: `Street 3`,
                            zipcode: `zipcode`,
                            city: `City`,
                            state: `State`,
                            iso_country_code: `Country`,
                            timezone_zone: `Timezone`,
                        },
                    },
                },
                dellStock: {
                    // section need to be translated
                    id: `Id`,
                    depot: `Depot`,
                    depot_name: `Depot Name`,
                    owner: `Owner`,
                    part_number: `Part Number`,
                    part_description: `Part Description`,
                    commodity: `Commodity`,
                    available: `Available`,
                    price_usd: `USD`,
                    price_eur: `EUR`,
                    price_gbp: `GBP`,
                    timestamp: `Timestamp`,
                },
                namingTag: {
                    // section need to be translated
                    vendor_id: `Vendor`,
                    id: `Id`,
                    name: `Name`,
                    device_tag: `Device Tag`,
                    device_name: `Device Name`,
                    component: `Component`,
                    component_id: `Component`,
                    device_tag_id: `Device Tag Id`,
                    action: `Action`,
                    save: `Save`,
                    delete: `Delete`,
                    revoke: `Revoke`,
                    apply: `Apply`,
                    add: `Add`,
                    saveBeforeApplyWarning: `All the changes must be saved before applying.`,
                },
                tooltip: {
                    // section need to be translated
                    id: `Id`,
                    model: 'Model',
                    property: 'Property',
                    tooltip: 'Tooltip',
                    add: `Add`,
                    save: `Save`,
                    delete: `Delete`,
                    pageUrl: `Page URL`,
                    componentId: `Component`,
                },
                product: {
                    id: `Id`,
                    name: `Naam`,
                    sku: `SKU`,
                    code: `Code`,
                    created_by: `Gemaakt door`,
                    updated_by: `Bijgewerkt door`,
                    brand_id: `Merk`,
                    created_at: `Gemaakt bij`,
                    description: `Beschrijving`,
                },
                testData: {
                    diagnose: {
                        serial: 'Serial',
                        amount: 'Amount',
                        type: 'Type',
                        stage: 'Stage',
                        create_diagnose_for_testing:
                            'Diagnose maken voor testen',
                    },
                    save: `Save`,
                    overview: {
                        id: 'ID',
                        ticket_id: 'Ticket ID',
                        name: 'Name',
                        status: 'Status',
                        state: 'State',
                        bom_state: 'Bom State',
                        tracking_no: 'Tracking No',
                        action: 'Action',
                        accept_dispatch: 'Verzending accepteren',
                        create_shipment: 'Verzendrapport maken',
                        create_shipment_successfully:
                            'Verzending verzendrapport succesvol toegevoegd!',
                        accept_quotation: 'Accepteer offerte',
                        accept_quotation_successfully:
                            'Accepteer de offerte succesvol',
                        deny_quotation: 'Offerte weigeren',
                        deny_quotation_successfully:
                            'Offerte succesvol weigeren',
                    },
                },
            },
            dispute: {
                remittance: {
                    import: `Importeren`,
                    fileUpload: `Bestand Geüpload:`,
                    fileUploadButton: `Uploaden bestand`,
                    importedFile: `Geïmporteerd bestand`,
                    oldestImport: `Oldest Import`,
                    latestImport: `Latest Import`,
                },
            },
            boms: {
                overview: {
                    boms: 'BOM',
                    id: 'ID',
                    serial: 'Serial',
                    issue: 'Issue ID',
                    state: 'State',
                    dispatch_code: 'Dispatch Code',
                    wms_order: 'WMS Order',
                    created_at: 'Created At',
                },
            },
            bom: {
                part_code: 'Part Code',
                qty: 'Qty',
                condition: 'Condition',
                stock_label: 'Stock Label',
                part_number: 'Part Number',
                sku: 'SKU',
                price: 'Price',
                dispatch_id: 'Dispatch ID',
                dispatch_code: 'Dispatch Code',
                dps_number: 'DPS Number',
                stock_level: 'Stock Level',
                external_order_id: 'External Order Id',
                wms_order: 'WMS Order',
                state: 'State',
                serial: 'Serial',
                issue_id: 'Issue ID',
                bom_state: 'BOM state',
                device_model_name: 'Device model name',
                bom_title: 'BOM Information',
            },
            production: {
                orders: {
                    id: 'ID',
                    orderNumber: 'Bestellingsnummer',
                    productionProjectId: 'Productieproject-ID',
                    addonId: 'Add-on-ID',
                    image: 'Afbeelding',
                    imageId: 'Image Id',
                    projectNumber: 'projectnummer',
                    serviceLocation: 'Servicelocatie',
                    Qty: 'Qty',
                    deliveryDate: 'bezorgdatum',
                    created: 'Gemaakt bij',
                    updated: 'Bijgewerkt op',
                    scanOderNumber: 'Bestelnummer scannen',
                    scanSerial: 'Serieel scannen',
                    enrollSerial: 'Serieel Enroll',
                    details: 'Details',
                    qty: 'Qty',
                    assigned: 'Toegewezen',
                    state: 'Staat',
                },
                orderLine: {
                    id: 'ID',
                    orderId: 'Production Order Id',
                    lineNr: 'Line Number',
                    Serial: 'Serial',
                    created: 'Gemaakt bij',
                    updated: 'Bijgewerkt op',
                    deleted: 'Verwijderd',
                    deviceId: 'Apparaat ID',
                    productId: 'Product-ID',
                    projectDeviceId: 'Projectapparaat-ID',
                    validated: 'Gevalideerd',
                    action: 'Actie',
                    state: 'Staat',
                    server: 'Server',
                    bios: 'Bios',
                    battery: 'Accu',
                },
                addons: {
                    id: 'ID',
                    Addons: 'Add-ons',
                    name: 'Naam',
                    version: 'Versie',
                    filename: 'Bestandsnaam',
                    project: 'Project',
                    createdBy: 'Gemaakt door',
                    updatedBy: 'Bijgewerkt door',
                    created: 'Gemaakt bij',
                    updated: 'Bijgewerkt op',
                    addParam: 'Param toevoegen',
                    Create: 'Creëren',
                    Cancel: 'Annuleren',
                    save: 'Redden',
                    required: 'Vereist',
                    type: 'Type',
                    regex: 'Regex',
                    key: 'Sleutel',
                    display_name: 'Weergavenaam',
                    datatype: 'Data Type',
                    default: 'Standaard',
                    help: 'Hulp',
                },
                servers: {
                    id: 'ID',
                    hostname: 'Hostnaam',
                    name: 'Naam',
                    organisation: 'Organisatie',
                    lastip: 'Laatste IP-adres',
                    lastseen: 'Laatst gezien',
                },
                images: {
                    id: 'ID',
                    company_id: 'Bedrijf',
                    name: 'Naam',
                    type: 'Type',
                    language_code: 'Taalcode',
                    versions: 'Versies',
                    lastseen: 'Laatst gezien',
                    production_image_id: 'Productie-image-ID',
                    version: 'Versie',
                    version_minor: 'Versie klein',
                    edition: 'Editie',
                },
                projects: {
                    id: 'ID',
                    project_nr: 'Projectnummer',
                    entity_tid: 'Entity Tid',
                    service_center_id: 'Servicecentrum-id',
                    external_id: 'Externe ID',
                    production_image_id: 'Afbeelding-ID',
                    active: 'Actief',
                    intune_group_tag: 'Intune-groepstag',
                    lastseen: 'Laatst gezien',
                    language: 'Taal',
                    target_group: 'Doelgroep',
                    os_version: 'OS Version',
                    delivery_date: 'Bezorgdatum',
                    state: 'Staat',
                    created: 'Gemaakt bij',
                    addon_id: 'Add-on-ID',
                    params: 'Params',
                    assignAddon: 'Add-on toewijzen',
                    editProject: 'Project bewerken',
                    importProject: 'Project importeren',
                    projectAddon: 'Project-add-ons',
                    assignAddonToProject: 'Add-on aan project toewijzen',
                    action: 'Actie',
                    deleteAddon: 'Wilt u deze add-on verwijderen?',
                    editAddon: 'Project bewerken',
                },
            },
            causes: {
                validateForAllModels: 'U moet minimaal één model selecteren!',
            },
        },
        validation: {
            emailRequired: `E-mail is een verplicht veld.`,
            emailInvalid: `E-mail is een ongeldig formaat.`,
            emailNotMatch: `Alleen gebruikers @rentcompany.nl / .be kunnen worden uitgenodigd.`,
            phoneInvalid: `Telefoonnummer mag alleen nummer, spatie en haakje bevatten. Vb: (12) 3456 789.`,
            ipInvalid: `Invalid IP`, // to be translated
            multipleIpNotMatch: `Please enter valid IPv4 and use commas separator for multiple IP`, // to be translated
            serialNumberRequired: `Serienummer is een verplicht veld.`,
            serialNumberNotMatch: `Serienummer heeft een ongeldig formaat.`,
            multipleEmail: `Voer een geldig e-mailadres in en gebruik een puntkommascheidingsteken voor meerdere e-mails.`,
            cron: `Voer een geldig cron-patroon in.`,
            selectStatement: `Voer 'SELECT'-verklaring in.`,
            workbenchLocation: `Workbench location has invalid format.`, // to be translated
        },
        table: {
            partName: 'Part name',
            partCode: 'Part code',
            action: 'Action',
            actions: 'Actions',
            sku: 'SKU',
        },
    },
}
