export const TicketHeadCells = [
    {
        id: 'ticket_id',
        label: 'ticket',
        orderable: true,
    },
    {
        id: 'location',
        label: 'esdLocation',
        orderable: true,
    },
    {
        id: 'last_scan_location',
        label: 'lastScanLocation',
        orderable: true,
    },
    {
        id: 'ticket_status',
        label: 'ticketStatus',
    },
    {
        id: 'state',
        label: 'state',
        orderable: true,
    },
]
