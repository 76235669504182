import { useTranslation } from 'react-i18next'
import Search from '../../components/Search/Search'
import { useCallback, useState } from 'react'
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
} from '@mui/material'
import { useIssueScanSerialDashboardMutation } from '../Issue/mutation'
import { useDialogContext } from '../../components/providers/StyledDialogContext'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    ISSUE_DISPATCH_ADD,
    ISSUE_DISPATCH_DETAIL,
    ISSUE_ISSUE_DETAIL,
} from '../../constants/Routes'
import { useDispatchScanSerialDashboardMutation } from '../Dispatch/mutate'

const Home = () => {
    const key = 'dashboard-scan-serial'
    const location = useLocation()
    const navigate = useNavigate()
    const { openSnackbar } = useDialogContext()
    const { t } = useTranslation()
    const [action, setAction] = useState(localStorage.getItem(key) || '')

    const handleChange = (event) => {
        setAction(event.target.value)
        localStorage.setItem(key, event.target.value)
    }

    const { mutate: scanSerialForIssue } = useIssueScanSerialDashboardMutation()
    const { mutate: scanSerialForDispatch } =
        useDispatchScanSerialDashboardMutation()

    const onError = useCallback(
        (e) => {
            if (e.response.data.code === undefined) {
                openSnackbar({
                    message: e?.response?.data?.message ?? 'Server error',
                    type: 'error',
                })
            } else {
                openSnackbar({
                    message: t(
                        `message.dashboard.scanSerial.${e.response.data.code}`,
                    ),
                    type: 'error',
                })
            }
        },
        [openSnackbar, t],
    )

    const handleEnterSearch = (event) => {
        if (event.key === 'Enter') {
            const searchParams = new URLSearchParams(location.search)

            const serial = event.target.value
            if (action === 'issue') {
                scanSerialForIssue(
                    { serial },
                    {
                        onSuccess: (data) => {
                            searchParams.set('tag', data.tag)
                            searchParams.set('serial', data.serial)

                            navigate({
                                pathname: ISSUE_ISSUE_DETAIL.replace(
                                    ':issueId',
                                    data.issue_id,
                                ),
                                search: searchParams.toString(),
                            })
                        },
                        onError,
                    },
                )
            } else if (action === 'dispatch') {
                scanSerialForDispatch(
                    { serial },
                    {
                        onSuccess: (data) => {
                            const statesCreatingNew = data?.states_creating_new
                            const currentState = data?.dispatch?.state

                            if (
                                (typeof statesCreatingNew === 'undefined' &&
                                    typeof currentState === 'undefined' &&
                                    data.code === 'createNewDispatch') ||
                                statesCreatingNew.includes(currentState)
                            ) {
                                searchParams.set('serial', data.dispatch.serial)
                                navigate({
                                    pathname: ISSUE_DISPATCH_ADD,
                                    search: searchParams.toString(),
                                })
                            } else {
                                navigate({
                                    pathname: ISSUE_DISPATCH_DETAIL.replace(
                                        ':id',
                                        data.dispatch.id,
                                    ),
                                    search: searchParams.toString(),
                                })
                            }
                        },
                        onError,
                    },
                )
            }
        }
    }

    return (
        <>
            <Stack spacing={2}>
                <Box>
                    <FormControl sx={{ width: 240 }}>
                        <InputLabel>Scan action</InputLabel>
                        <Select
                            value={action}
                            label='Scan action'
                            onChange={handleChange}
                        >
                            <MenuItem value={'issue'}>
                                Open/create Issue
                            </MenuItem>
                            <MenuItem value={'dispatch'}>
                                Open/create Dispatch
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <Search
                        label={'Scan Serial'}
                        autoFocus
                        onKeyPress={handleEnterSearch}
                    />
                </Box>
            </Stack>
        </>
    )
}

export default Home
