import {
    COPACO_DISPATCH_NUMBER,
    COPACO_INVOICE_NUMBER,
    EASY4U_CUSTOMER_ORDER_NUMBER,
} from '~/constants/externalLink'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectDevice } from '~/store/device/selector'
import InformationData from './Component/InformationData'
import { formatDateTime } from '~/utils/helpers'
import { selectFormatDateState } from '~/store/formatdate/selector'
import { selectUser } from '~/store/auth/selector'

const ProcurementSerial = () => {
    const format = useSelector(selectFormatDateState)
    const user = useSelector(selectUser)
    const results = useSelector(selectDevice)
    const procurementSerial = useMemo(() => {
        const content = results.procurement_serial
        const shipping = formatDateTime(
            content?.shipped_at,
            format,
            user?.timezone,
            true,
        )
        const invoice = formatDateTime(
            content?.invoiced_at,
            format,
            user?.timezone,
            true,
        )
        return [
            {
                label: 'orderNr',
                value: content?.order_nr,
            },
            {
                label: 'name',
                value: content?.name,
            },
            {
                label: 'vendorSku',
                value: content?.vendor_sku,
            },
            {
                label: 'supplierSku',
                value: content?.supplier_sku,
            },
            {
                label: 'trcSku',
                value: content?.trc_sku,
            },
            {
                label: 'unitPrice',
                value: content?.unit_price,
            },
            {
                label: 'Invoice',
                value: content?.procurement_invoice?.invoice_number,
                subValue: invoice,
                externalLink: COPACO_INVOICE_NUMBER,
                tailParam: `&DocumentType=FAC`,
            },
            {
                label: 'shipping',
                value: content?.procurement_dispatch?.dispatch_number,
                subValue: shipping,
                externalLink: COPACO_DISPATCH_NUMBER,
                tailParam: `&DocumentType=PAK`,
            },
            {
                label: 'purchaseOrder',
                value: content?.purchase_order_id,
                externalLink: EASY4U_CUSTOMER_ORDER_NUMBER,
            },
            {
                label: 'createdAt',
                value: content?.created_at,
                dateTime: true,
            },
            {
                label: 'updatedAt',
                value: content?.updated_at,
                dateTime: true,
            },
        ]
    }, [format, results.procurement_serial, user?.timezone])

    return <InformationData content={procurementSerial} />
}

export default ProcurementSerial
