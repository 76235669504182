import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import { ISSUE_ISSUE } from '~/constants/Routes'
import { useIssuesQuery } from '../query'
import IssueTable from './IssueTable'
import { useDispatch, useSelector } from 'react-redux'
import { selectSnackbar } from '~/store/snackbar/selector'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useEffect } from 'react'
import { unsetSnackbar } from '~/store/snackbar/slice'

const breadCrumbConfig = [
    { route: ISSUE_ISSUE, title: 'Issues' },
    { route: '#', title: 'Issue' },
]

const IssueOverview = () => {
    const snackbarData = useSelector(selectSnackbar)
    const { showSnackbar, snackbar } = useSnackbar()
    const dispatch = useDispatch()
    useEffect(() => {
        if (snackbarData && snackbarData?.type === 'success') {
            showSnackbar(snackbarData.message, snackbarData.type)
            dispatch(unsetSnackbar({}))
        }
    }, [dispatch, showSnackbar, snackbarData])
    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <PageName name={'Issues'} />
            <IssueTable {...{ useIssuesQuery }} />
        </>
    )
}
export default IssueOverview
