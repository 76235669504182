import { FormControl, MenuItem, Select } from '@mui/material'
import propTypes from 'prop-types'

const MaliciousDamageSelectionComponent = ({
    showMaliciousCondition,
    handleChange,
    finishDiagnostic,
    condition,
}) => {
    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 80 }} size='small'>
                <Select
                    onChange={handleChange}
                    autoWidth
                    disabled={finishDiagnostic}
                    value={condition || ''}
                >
                    <MenuItem value='warranty'>Warranty</MenuItem>
                    <MenuItem value='accidental'>Accidental</MenuItem>
                    {(finishDiagnostic || showMaliciousCondition) && (
                        <MenuItem value='malicious'>Malicious</MenuItem>
                    )}
                </Select>
            </FormControl>
        </div>
    )
}

MaliciousDamageSelectionComponent.propTypes = {
    showMaliciousCondition: propTypes.bool,
    handleChange: propTypes.func,
    finishDiagnostic: propTypes.bool,
    condition: propTypes.string,
}

export default MaliciousDamageSelectionComponent
