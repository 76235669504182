import TableFilters from '~/components/TableFilters'
import { useTeamListQuery } from '~/pages/Team/query'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const stateData = [
    { id: '', name: 'ALL' },
    { id: 'error', name: 'ERROR' },
    { id: 'open', name: 'OPEN' },
    { id: 'pending', name: 'PENDING' },
    { id: 'action_required', name: 'ACTION_REQUIRED' },
    { id: 'closed', name: 'CLOSED' },
]

const RackTicketTableFilters = ({
    search,
    searchHandler,
    state,
    setState,
    team,
    setTeam,
    onEnterSearch,
}) => {
    const { data: teamData, isSuccess: isSuccessTeam } = useTeamListQuery({
        menuOption: true,
    })

    const teams = useMemo(() => {
        if (isSuccessTeam) {
            const result = [...teamData?.data]
            result.unshift({ id: '', name: 'ALL' })
            return result
        }
        return []
    }, [isSuccessTeam, teamData])

    const selectGroup = useMemo(() => {
        return [
            {
                label: 'State',
                handleFunc: setState,
                dataSet: stateData,
                value: state,
            },
            {
                label: 'Team',
                handleFunc: setTeam,
                dataSet: teams,
                value: team,
            },
        ]
    }, [setState, setTeam, state, team, teams])

    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='tickets'
            page='tickets'
        />
    )
}
RackTicketTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    state: PropTypes.string,
    setState: PropTypes.func,
    team: PropTypes.string,
    setTeam: PropTypes.func,
    onEnterSearch: PropTypes.func,
}
export default RackTicketTableFilters
