import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import ComponentTooltip from '~/components/ComponentTooltip'
import Loading from '~/components/Loading'
import PageName from '~/components/PageName'
import PageLayout from '~/components/layout/PageLayout'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_DEFINITION,
    ADMINISTRATOR_DEFINITION_CAUSE,
    ADMINISTRATOR_DEFINITION_CAUSE_EDIT,
    ADMINISTRATOR_DEFINITION_CAUSE_NEW,
} from '~/constants/Routes'
import { useCauseDetailQuery } from '../query'
import CausePartCodeSection from './CausePartCodeSection'
import EditCauseForm from './EditCause'

const CauseEdit = () => {
    const { id } = useParams()
    const { data: causeContent } = useCauseDetailQuery(id)
    const [selectedModelIds, setSelectedModelIds] = useState([])

    const BreadComponents = useMemo(
        () => [
            { route: ADMINISTRATOR, title: 'Administrator' },
            {
                route: ADMINISTRATOR_DEFINITION,
                title: 'Definitions',
            },
            {
                route: ADMINISTRATOR_DEFINITION_CAUSE,
                title: 'Causes',
            },
            {
                page: 'detail',
                title: id,
            },
        ],
        [id],
    )

    if (!causeContent) {
        return <Loading></Loading>
    }

    return (
        <PageLayout
            breadcrumbs={BreadComponents}
            pageName={
                <ComponentTooltip
                    componentId={`page_title`}
                    pagePath={ADMINISTRATOR_DEFINITION_CAUSE_EDIT}
                >
                    <div>
                        <PageName name='Edit Causes' />
                    </div>
                </ComponentTooltip>
            }
        >
            <EditCauseForm
                causeContent={causeContent}
                pagePath={ADMINISTRATOR_DEFINITION_CAUSE_NEW}
                setSelectedModelIds={setSelectedModelIds}
            />
            <CausePartCodeSection
                {...{ causeContent }}
                disabled={causeContent.disabled}
                selectedModelIds={selectedModelIds}
            />
        </PageLayout>
    )
}
export default CauseEdit
