import axiosClient from '~/api/base'

export const bomApi = {
    getBoms: (params) => axiosClient.get('api/boms', { params }),
    getBomState: () => axiosClient.get('api/bom/all-states'),
    listBomByIssue: (issueId) =>
        axiosClient.get(`api/boms-by-issue/${issueId}`),
    updateBom: (id, payload) => axiosClient.put(`api/bom/${id}`, payload),
    refreshBoms: (issueId) => axiosClient.put(`api/boms/refresh/${issueId}`),
    updateBomLabel: (id, payload) =>
        axiosClient.put(`api/bom/update-stock-label/${id}`, payload),
}
