import {
    Divider,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import { PartCodeHeadCells } from '~/components/HeaderTable/PartCodeHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PartCodeListToolbar from './PartCodeListToolbar'
import PartCodeTableFilters from './PartCodeTableFilters'
import { usePartCodeQuery } from './query'
import PropTypes from 'prop-types'

const localization = 'pages.product.partCode.overview.'

const PartCodeTableBody = ({ isLoading, partcodes }) => {
    const navigate = useNavigate()
    if (isLoading) {
        return <TableLoading colSpan={PartCodeHeadCells.length} />
    }

    return (
        <TableBody>
            {partcodes?.length > 0 ? (
                partcodes.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.code}>
                            <TableCell component='th' scope='row'>
                                <Link
                                    component='button'
                                    variant='inherit'
                                    to='#'
                                    underline='hover'
                                    onClick={() => navigate('#')}
                                >
                                    <Typography variant='body2' align='center'>
                                        {row.code}
                                    </Typography>
                                </Link>
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.type}</TableCell>
                            <TableCell>{row.models?.length}</TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={PartCodeHeadCells.length} />
            )}
        </TableBody>
    )
}

PartCodeTableBody.propTypes = {
    isLoading: PropTypes.bool,
    partcodes: PropTypes.arrayOf(PropTypes.object),
}

const PartCodeTable = () => {
    const [type, setType] = useState('')

    const {
        results: partcodes,
        data,
        isLoading,
        page,
        setPage,
        order,
        orderBy,
        search,
        searchHandler,
        sortHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: usePartCodeQuery,
        queryAttributes: {
            type,
        },
    })

    const refValue = useRef({
        type: '',
    })

    useEffect(() => {
        if (type !== refValue.current.type) {
            setPage(1)
            refValue.current.type = type
        }
    }, [setPage, type])

    return (
        <>
            <PartCodeListToolbar payload={{ type, search, order, orderBy }} />

            <Paper sx={{ width: '100%', mb: 2 }}>
                <PartCodeTableFilters
                    search={search}
                    searchHandler={searchHandler}
                    type={type}
                    setType={setType}
                    onEnterSearch={onEnterSearch}
                />
                <Divider />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby='tableTitle'
                        size='medium'
                    >
                        <TableHeader
                            headCells={PartCodeHeadCells}
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                            localization={localization}
                        />
                        <PartCodeTableBody
                            isLoading={isLoading}
                            partcodes={partcodes}
                        />
                    </Table>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    size='medium'
                    handleChangePage={changePageHandler}
                />
            </Paper>
        </>
    )
}
export default PartCodeTable
