import {
    Divider,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import DataNotFound from '~/components/DataNotFound'
import { SerialHeadCells } from '~/components/HeaderTable/SerialHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { FLEET_SERIAL_DETAIL } from '~/constants/Routes'
import useTable from '~/hooks/useTable'
import { useNavigate } from 'react-router-dom'
import SerialListToolbar from './SerialListToolbar'
import SerialTableFilters from './SerialTableFilters'
import { useSerialOverviewQuery } from './query'
import PropTypes from 'prop-types'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const localization = `pages.device.serial.overview.`

const SerialTableBody = ({ isLoading, serials }) => {
    const navigate = useNavigate()
    if (isLoading) {
        return <TableLoading colSpan={SerialHeadCells.length} />
    }
    const formatDeviceName = (row) => {
        if (!row.device_tags?.length) {
            return row.name
        }
        return (
            row.name +
            ' ' +
            row.device_tags.map((tag, index) => tag.name).join(`, `)
        )
    }
    return (
        <TableBody>
            {serials.length > 0 ? (
                serials.map((row) => {
                    const deviceName = formatDeviceName(row)
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell component='th' scope='row'>
                                <Link
                                    component='button'
                                    variant='inherit'
                                    to='#'
                                    underline='hover'
                                    onClick={() =>
                                        navigate(
                                            FLEET_SERIAL_DETAIL.replace(
                                                ':id',
                                                row.id,
                                            ),
                                        )
                                    }
                                >
                                    <Typography variant='body2' align='center'>
                                        {row.serial}
                                    </Typography>
                                </Link>
                            </TableCell>
                            <TableCell align='left'>
                                {row.vendor.name}
                            </TableCell>
                            <TableCell align='left'>{deviceName}</TableCell>
                            <TableCell align='left'>
                                <DateTimeLocale
                                    datetime={row.shipdate}
                                    formatHour={true}
                                />
                            </TableCell>
                            <TableCell align='left'>{row.state?.tid}</TableCell>
                            <TableCell align='left'>
                                <DateTimeLocale
                                    datetime={row.endDateEntitlement}
                                    formatHour={true}
                                />
                            </TableCell>
                            <TableCell align='left'>
                                {row.slaCodesEnttilements}
                            </TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={SerialHeadCells.length} />
            )}
        </TableBody>
    )
}

SerialTableBody.propTypes = {
    isLoading: PropTypes.bool,
    serials: PropTypes.arrayOf(PropTypes.object),
}

const SerialTable = () => {
    const [vendor, setVendor] = useState('')
    const [state, setState] = useState('')

    const serialSearch = localStorage.getItem('SEARCH_SERIAL')

    const {
        data,
        isLoading,
        results: serials,
        page,
        order,
        orderBy,
        search,
        setPage,
        searchHandler,
        sortHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useSerialOverviewQuery,
        initialStates: {
            search: serialSearch ?? '',
            orderBy: '',
        },
        queryAttributes: {
            vendor,
            state,
        },
    })

    const refValue = useRef({
        vendor: '',
        state: '',
    })

    useEffect(() => {
        if (
            vendor !== refValue.current.vendor ||
            state !== refValue.current.state
        ) {
            setPage(1)
            refValue.current.vendor = vendor
            refValue.current.state = state
        }
    }, [state, vendor, setPage])

    return (
        <>
            <SerialListToolbar
                payload={{ vendor, state, search, order, orderBy }}
            />

            <Paper sx={{ width: '100%', mb: 2 }}>
                <SerialTableFilters
                    search={search}
                    searchHandler={searchHandler}
                    vendor={vendor}
                    setVendor={setVendor}
                    state={state}
                    setState={setState}
                    onEnterSearch={onEnterSearch}
                />
                <Divider />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby='tableTitle'
                        size='medium'
                    >
                        <TableHeader
                            headCells={SerialHeadCells}
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                            localization={localization}
                        />
                        <SerialTableBody
                            isLoading={isLoading}
                            serials={serials}
                        />
                    </Table>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    size='medium'
                    handleChangePage={changePageHandler}
                />
            </Paper>
        </>
    )
}
export default SerialTable
