import { useQuery } from 'react-query'
import { bomApi } from '~/api/bomApi'
import { DEFAULT_CACHE_TTL } from '~/utils/constants'
import { removeEmptyValue } from '~/utils/helpers'

export const useBomsQuery = (params) => {
    return useQuery(
        ['boms', params],
        async () => {
            const cleanParams = removeEmptyValue(params)
            if (typeof cleanParams.orderBy === 'undefined') {
                cleanParams.orderBy = 'issue'
            }
            const { data } = await bomApi.getBoms(cleanParams)
            return { data: data.data, total: data.meta.total }
        },
        {
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
            retry: false,
        },
    )
}

export const useBomStateQuery = () => {
    return useQuery(
        ['bom_state'],
        async () => {
            const { data } = await bomApi.getBomState()
            return data
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
        },
    )
}

export const useListBomDetailByIssueQuery = ({ issueId }) => {
    return useQuery(
        ['bom_detail', issueId],
        async () => {
            const { data } = await bomApi.listBomByIssue(issueId)
            return { data: data.data, total: data.meta.total }
        },
        {
            retry: false,
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
            enabled: !!issueId,
        },
    )
}
