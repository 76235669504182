import {
    CircularProgress,
    Grid,
    ImageList,
    ImageListItem,
    Paper,
    Stack,
    TextareaAutosize,
} from '@mui/material'
import { useMemo } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router-dom'
import CheckboxesSection from '../CheckboxesSection'
import DiagnosticDescription from '../DiagnosticDescription'
import PartToChange from '../PartToChange'
import SectionTitle from '../components/SectionTitle'
import { useIssueDiagnoseQuery, usePictureQuery } from '../query'
import Loading from '../../../components/Loading'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const locale = localStorage.getItem('LOCALE')

const ConfirmationForm = ({ diagnoseId }) => {
    const LOCALIZATION = 'pages.diagnostic.'
    const { t } = useTranslation()
    const { issueId } = useParams()
    const { data, isLoading } = usePictureQuery(issueId)

    const { data: formData, isFetching } = useIssueDiagnoseQuery({
        id: diagnoseId,
        isConfirmForm: true,
    })

    const images = useMemo(() => {
        return (
            data.data &&
            data.data.map((image) => {
                return {
                    src: image.preview_url,
                    id: image.id,
                }
            })
        )
    }, [data.data])

    if (isFetching) {
        return <Loading />
    }

    return (
        <Grid container spacing={1} sx={{ marginBottom: 4 }}>
            <Grid item xs={12} align='center'>
                {!isLoading ? (
                    <Paper sx={{ w: '100%', px: 3, mb: 2 }} variant='outlined'>
                        <SectionTitle title={t(`${LOCALIZATION}pictures`)} />
                        <ImageList cols={4} rowHeight={120}>
                            {images?.length > 0 &&
                                images.map((image, index) => (
                                    <ImageListItem key={index}>
                                        <img
                                            src={image.src}
                                            alt=''
                                            loading='lazy'
                                            style={{
                                                objectFit: 'scale-down',
                                            }}
                                        />
                                    </ImageListItem>
                                ))}
                        </ImageList>
                    </Paper>
                ) : (
                    <CircularProgress thickness={3} size={20} />
                )}
            </Grid>

            <Grid xs={12}>
                {!isFetching && (
                    <Form
                        onSubmit={() => {}}
                        initialValues={formData}
                        render={({ values, form }) => {
                            return (
                                <form>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={
                                                formData?.liquid_damage_total_loss
                                                    ? 12
                                                    : 6
                                            }
                                        >
                                            <Paper
                                                sx={{ width: '100%' }}
                                                variant='outlined'
                                            >
                                                <CheckboxesSection
                                                    {...{
                                                        setShowDiagnosticsSection:
                                                            () => {},
                                                        setDisabledNextButton:
                                                            () => {},
                                                        form,
                                                        values,
                                                        finishDiagnostic: true,
                                                        setShowMaliciousCondition:
                                                            () => {},
                                                        setIsWaterTotalDamaged:
                                                            () => {},
                                                        setDiagnosticSaveButton:
                                                            () => {},
                                                    }}
                                                />
                                            </Paper>
                                        </Grid>

                                        {!formData?.liquid_damage_total_loss && (
                                            <>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    align='center'
                                                >
                                                    <Paper
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            px: 3,
                                                            py: 1,
                                                        }}
                                                        variant='outlined'
                                                    >
                                                        <SectionTitle
                                                            title={`Root causes`}
                                                        />
                                                        <Stack
                                                            sx={{
                                                                textAlign:
                                                                    'left',
                                                            }}
                                                        >
                                                            {formData.issue.causes.map(
                                                                (cause) => {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                cause.id
                                                                            }
                                                                        >
                                                                            {'- ' +
                                                                                cause
                                                                                    .name[
                                                                                    locale ??
                                                                                        'en'
                                                                                ]}
                                                                        </div>
                                                                    )
                                                                },
                                                            )}
                                                        </Stack>
                                                    </Paper>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Paper variant='outlined'>
                                                        <DiagnosticDescription
                                                            value={
                                                                formData.issue
                                                                    .causes
                                                            }
                                                            locale={locale}
                                                        />
                                                    </Paper>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Paper
                                                        sx={{
                                                            px: 3,
                                                            py: 1,
                                                        }}
                                                        variant='outlined'
                                                    >
                                                        <SectionTitle
                                                            title={t(
                                                                `${LOCALIZATION}technicianComments`,
                                                            )}
                                                            align='left'
                                                        />
                                                        <TextareaAutosize
                                                            value={
                                                                formData.comments
                                                            }
                                                            disabled
                                                            minRows={5}
                                                            maxRows={5}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />
                                                    </Paper>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Paper
                                                        sx={{
                                                            px: 3,
                                                            py: 1,
                                                        }}
                                                        variant='outlined'
                                                    >
                                                        <PartToChange
                                                            clickedCause={
                                                                formData.issue
                                                                    .causes
                                                            }
                                                            finishDiagnostic
                                                            isConfirmForm
                                                        />
                                                    </Paper>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </form>
                            )
                        }}
                    />
                )}
            </Grid>
        </Grid>
    )
}

ConfirmationForm.propTypes = {
    diagnoseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default ConfirmationForm
