import {
    Table as MuiTable,
    TableBody as MuiTableBody,
    Paper,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import { ProductionProjectAddonHeadCells } from '~/components/HeaderTable/ProductionProjectHeadCells'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { formatDateTime } from '~/utils/helpers'
import { useProjectAddonsQuery } from '../query'
import ProjectLink from '../Overview/ProjectLink'
import { PRODUCTION_ADD_ONS_DETAIL } from '~/constants/Routes'
import PaginationTable from '~/components/PaginationTable'
import useTable from '~/hooks/useTable'
import ProjectAddonActionButton from '../ProjectAction/ProjectAddonActionButton'
import { useCallback, useState } from 'react'
import ProjectDeleteAddonModel from '../ProjectAction/ProjectDeleteAddonModel'
import ProjectEditAddonModel from '../ProjectAction/ProjectEditAddonModel'

const TableBody = ({
    isLoading,
    objects,
    onDeleteAddonClick,
    onEditAddonClick,
}) => {
    if (isLoading) {
        return <TableLoading colSpan={ProductionProjectAddonHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {objects.length > 0
                ? objects.map((row, rowIndex) => {
                      return (
                          <TableRow hover tabIndex={-1} key={row?.id}>
                              <TableCell>{row?.id}</TableCell>
                              <TableCell>
                                  <ProjectLink
                                      navigateLink={PRODUCTION_ADD_ONS_DETAIL.replace(
                                          ':id',
                                          row?.addon_id,
                                      )}
                                      value={row?.addon_id}
                                  />
                              </TableCell>
                              <TableCell>
                                  {JSON.stringify(row?.params)}
                              </TableCell>
                              <TableCell>
                                  {formatDateTime(row?.created_at)}
                              </TableCell>
                              <TableCell>
                                  <ProjectAddonActionButton
                                      row={row}
                                      onDeleteAddonClick={onDeleteAddonClick}
                                      onEditAddonClick={onEditAddonClick}
                                  />
                              </TableCell>
                          </TableRow>
                      )
                  })
                : ''}
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    isLoading: PropTypes.bool,
    objects: PropTypes.array,
    onEditAddonClick: PropTypes.func.isRequired,
    onDeleteAddonClick: PropTypes.func.isRequired,
}

const ProjectAddonTable = ({ projectId }) => {
    const [openDeleteAddon, setOpenDeleteAddon] = useState(false)
    const [openUpdateAddon, setOpenUpdateAddon] = useState(false)
    const [projectAddonSeleted, setProjectAddonSeleted] = useState()

    const {
        results,
        data,
        isLoading,
        order,
        page,
        orderBy,
        sortHandler,
        changePageHandler,
    } = useTable({
        query: useProjectAddonsQuery,
        initialStates: {
            order: 'asc',
            page: 1,
            orderBy: 'name',
        },
        queryAttributes: {
            projectId,
        },
    })

    const deleteAddon = useCallback((_, value) => {
        setOpenDeleteAddon(true)
        setProjectAddonSeleted(value)
    }, [])

    const editAddon = useCallback((_, value) => {
        setOpenUpdateAddon(true)
        setProjectAddonSeleted(value)
    }, [])

    return (
        <>
            <Paper>
                <TableContainer
                    sx={{ width: '100%', marginBottom: '20px' }}
                    aria-labelledby='tableTitle'
                    size='medium'
                >
                    <MuiTable>
                        <TableHeader
                            headCells={ProductionProjectAddonHeadCells}
                            localization={'pages.production.projects.'}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={sortHandler}
                        />
                        <TableBody
                            isLoading={isLoading}
                            objects={results}
                            onDeleteAddonClick={deleteAddon}
                            onEditAddonClick={editAddon}
                        />
                    </MuiTable>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    handleChangePage={changePageHandler}
                    size='medium'
                />
            </Paper>
            <ProjectDeleteAddonModel
                row={projectAddonSeleted}
                setOpenDeleteAddon={setOpenDeleteAddon}
                openDeleteAddon={openDeleteAddon}
            />
            {openUpdateAddon && (
                <ProjectEditAddonModel
                    row={projectAddonSeleted}
                    setOpenUpdateAddon={setOpenUpdateAddon}
                    openUpdateAddon={openUpdateAddon}
                />
            )}
        </>
    )
}

ProjectAddonTable.propTypes = {
    projectId: PropTypes.number,
}

export default ProjectAddonTable
