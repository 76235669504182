import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import TableToolbarContainer from '~/components/Table/TableToolbarContainer'
import {
    IconButton,
    Paper,
    TableBody as MuiTableBody,
    TableCell as MuiTableCell,
    TableRow as MuiTableRow,
} from '@mui/material'
import SearchToolBar from '~/components/SearchToolBar'
import TableHeader from '~/components/TableHeader'
import PaginationTable from '~/components/PaginationTable'
import TableContainer from '~/components/Table/TableContainer'
import PageName from '~/components/PageName'
import ToolBarButton from '~/components/ToolBarButton'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_TOOLTIP,
    ADMINISTRATOR_TOOLTIP_CREATE,
    ADMINISTRATOR_TOOLTIP_DETAIL,
} from '~/constants/Routes'
import AddIcon from '@mui/icons-material/Add'
import {
    TableCell,
    TableClickableCell,
    useTableCollapsibleCell,
} from '~/components/TableCell'

import DataNotFound from '~/components/DataNotFound'
import BreadCrumb from '~/components/BreadCrumb'
import useTable from '~/hooks/useTable'
import { useTooltipQuery } from './query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMemo } from 'react'
import TableLoading from '~/components/Table/TableLoading'
import { ComponentTooltipColumnConfig } from '~/components/HeaderTable/ComponentTooltipColumnConfig'

import ComponentTooltip from '~/components/ComponentTooltip'
import PropTypes from 'prop-types'

const TooltipBodyTable = ({ isLoading, tooltips }) => {
    if (isLoading) {
        return <TableLoading colSpan={ComponentTooltipColumnConfig.length} />
    }

    return (
        <MuiTableBody>
            {tooltips.length > 0 ? (
                tooltips.map((row) => {
                    return <TableRow row={row} key={row.id} />
                })
            ) : (
                <DataNotFound colSpan={ComponentTooltipColumnConfig.length} />
            )}
        </MuiTableBody>
    )
}

TooltipBodyTable.propTypes = {
    isLoading: PropTypes.bool,
    tooltips: PropTypes.array,
}

const Tooltip = () => {
    const {
        order,
        page,
        orderBy,
        search,
        data,
        results,
        isLoading,
        sortHandler,
        searchHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({ query: useTooltipQuery })
    const { t } = useTranslation()
    const localization = 'pages.administrator.tooltip.'

    const navigate = useNavigate()
    const BreadComponents = [
        { route: ADMINISTRATOR, title: 'Administrator' },
        {
            route: ADMINISTRATOR_TOOLTIP,
            title: 'Tooltips',
        },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_TOOLTIP}
            >
                <div>
                    <PageName name='Tooltips' />
                </div>
            </ComponentTooltip>
            <TableToolbarContainer>
                <ToolBarButton
                    icon={<AddIcon />}
                    name={t(`${localization}add`)}
                    handleAction={() => {
                        navigate(ADMINISTRATOR_TOOLTIP_CREATE)
                    }}
                />
            </TableToolbarContainer>
            <Paper>
                <TableToolbarContainer>
                    <SearchToolBar
                        filterSearch={search}
                        onFilterSearch={searchHandler}
                        onEnterSearch={onEnterSearch}
                        placeholder={`tooltips`}
                        sx={{
                            width: '20%',
                        }}
                    />
                </TableToolbarContainer>
                <TableContainer>
                    <TableHeader
                        headCells={ComponentTooltipColumnConfig}
                        localization={localization}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <TooltipBodyTable
                        isLoading={isLoading}
                        tooltips={results}
                    />
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    handleChangePage={changePageHandler}
                    size='medium'
                />
            </Paper>
        </>
    )
}

const TableRow = ({ row }) => {
    const cellItems = useMemo(() => {
        return Object.keys(row?.tooltip).map((item) => {
            return item + ': ' + row?.tooltip[item]
        })
    }, [row])

    const { cell, setOpen, open } = useTableCollapsibleCell({
        items: cellItems,
    })
    return (
        <MuiTableRow hover tabIndex={-1} key={row.id}>
            <MuiTableCell>
                <IconButton
                    aria-label='expand row'
                    size='small'
                    onClick={() => setOpen(!open)}
                >
                    {open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                </IconButton>
            </MuiTableCell>
            <TableClickableCell
                cellValue={row?.id}
                link={ADMINISTRATOR_TOOLTIP_DETAIL.replace(`:id`, row.id)}
            />
            <TableCell cellValue={row?.page_url} />
            <TableCell cellValue={row?.component_id} />
            {cell}
        </MuiTableRow>
    )
}

TableRow.propTypes = { row: PropTypes.object }

export default Tooltip
