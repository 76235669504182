import DeleteIcon from '@mui/icons-material/Delete'
import {
    Autocomplete,
    Box,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import propTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { LoadingButton } from '~/components/Button'
import ComponentTooltip from '~/components/ComponentTooltip'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { ISSUE_ISSUE_REPAIR } from '~/constants/Routes'
import { useAllPartCodesQuery } from '../../PartCode/query'
import {
    useAdvanceRepairMutation,
    useIssueRepairSaveReceivedParts,
    useIssueRepairScanReceivedPart,
} from '../mutation'
import { useIssuePartCodesQuery } from '../query'
import { PPIDPattern } from './constants'
import { textValidateRegex } from '~/utils/helpers'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const PartTable = ({ issueId, setOpenScanReceivedPart, ppidRegex }) => {
    const { openSnackbar } = useDialogContext()
    const { data: partCodes, isSuccess } = useIssuePartCodesQuery(issueId)
    const { data: allPartCodes } = useAllPartCodesQuery({ openSnackbar })
    const { mutate: scanReceivedPart } = useIssueRepairScanReceivedPart()
    const { mutate: saveReceivedParts } = useIssueRepairSaveReceivedParts()
    const { mutate: advanceRepair } = useAdvanceRepairMutation()
    const queryClient = useQueryClient()

    const [scannedPartCodes, setScannedPartCodes] = useState([{}])
    const [remainingPartCodes, setRemainingPartCodes] = useState([{}])
    const [ppids, setPpids] = useState([])
    const [isError, setIsError] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        if (isSuccess) {
            setScannedPartCodes(() => {
                return [...partCodes].filter((code) => code.new_part_number)
            })

            setRemainingPartCodes(() => {
                return [...partCodes].filter((code) => !code.new_part_number)
            })
        }
    }, [isSuccess, partCodes])

    useEffect(() => {
        setIsError(
            scannedPartCodes.some((item) => {
                return (
                    typeof item.code !== 'undefined' &&
                    item.code.length > 0 &&
                    ((typeof item.new_part_number !== 'undefined' &&
                        item.new_part_number.length === 0) ||
                        typeof item.new_part_number === 'undefined')
                )
            }),
        )
    }, [scannedPartCodes])

    const displayPartCodes = useMemo(() => {
        let display = [...scannedPartCodes]
        if (scannedPartCodes?.every((code) => code?.new_part_number)) {
            display = [...display, {}]
        }

        return display
    }, [scannedPartCodes])

    function removePartCodeFromRemainingList(partCode) {
        if (remainingPartCodes.length > 0) {
            setRemainingPartCodes((prev) => {
                return [...prev].filter((code) => code.code !== partCode)
            })
        }
    }

    const savePartNumber = (e, index) => {
        const isAlreadyScannedPartNumber = () => {
            const isAlreadyScanned = displayPartCodes.some((code, idx) => {
                if (idx !== index) {
                    return (
                        code.new_part_number?.toLowerCase() ===
                        e.target.value.toLowerCase()
                    )
                }
                return false
            })

            if (isAlreadyScanned) {
                openSnackbar({
                    message: 'This part number is already scanned',
                    type: 'error',
                })
                setIsError(true)
                return true
            }
            return false
        }

        const getNotifiedMessage = (return_code) => {
            let message = ''
            if (return_code === -1) {
                message =
                    'Part number matched with a part code not belong to this issue, please scan the correct part'
            } else if (return_code === 1) {
                message = 'Found matching part code'
            } else if (return_code === 0) {
                message =
                    'Matching part number not found, please choose part codes from the next box'
                if (remainingPartCodes.length > 0) {
                    message =
                        'Matching part number not found, please choose part codes from the box below'
                }
            }
            return message
        }

        if (e.key === 'Enter' || e.type === 'blur') {
            if (isAlreadyScannedPartNumber()) {
                return false
            }

            if (!e.target.value.length) {
                setScannedPartCodes((prev) => {
                    const arr = [...prev]
                    if (typeof arr[index]?.new_part_number !== 'undefined') {
                        delete arr[index].new_part_number

                        openSnackbar({
                            message: 'Cleaned part number',
                            type: 'info',
                        })
                    }

                    return arr
                })
                return false
            }

            if (e.target.value.length) {
                scanReceivedPart(
                    { issueId, part_number: e.target.value },
                    {
                        onSuccess: (res) => {
                            const { return_code, data } = res.data
                            const partCode = data?.part_code

                            setScannedPartCodes((prev) => {
                                const arr = [...prev]
                                if (!arr[index]) {
                                    arr[index] = {}
                                }

                                arr[index].new_part_number = e.target.value
                                if (partCode) {
                                    const part = partCodes.find(
                                        (part) => part.code === partCode,
                                    )
                                    const isAlreadyScanned =
                                        scannedPartCodes.some(
                                            (item) => item.code === part.code,
                                        )
                                    if (!isAlreadyScanned) {
                                        arr[index] = {
                                            ...part,
                                            new_part_number: e.target.value,
                                        }
                                    }

                                    removePartCodeFromRemainingList(partCode)
                                }

                                return arr
                            })

                            openSnackbar({
                                message: getNotifiedMessage(return_code),
                                type: return_code === 1 ? 'success' : 'info',
                            })
                        },
                        onError: () => {
                            // console.log('e', e);
                        },
                    },
                )
            }
        }
    }

    const saveNewPPID = (index, value) => {
        if (value.length > 0) {
            const match = value.match(PPIDPattern)
            const newPartNumber = match ? match[1] : null
            setScannedPartCodes((prev) => {
                const newArr = [...prev]
                if (!newArr[index]) {
                    newArr[index] = {}
                }
                newArr[index].new_ppid = value
                newArr[index].new_part_number =
                    newPartNumber ?? newArr[index].new_part_number

                return newArr
            })
            let pMessage = 'Saved PPID'
            let pType = 'info'

            if (!textValidateRegex(ppidRegex, value)) {
                pMessage = t('message.ppidAttemptedCharacter')
                pType = 'error'
            }

            openSnackbar({
                message: pMessage,
                type: pType,
            })
        }
    }

    const onEnterPressedPpid = (e, index) => {
        if (e.key === 'Enter') {
            saveNewPPID(index, e.target.value)
        }
    }

    const allowDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const drag = (e) => {
        e.dataTransfer.setData('code', e.target.innerText)
    }

    const dropToWaitingList = (e) => {
        e.preventDefault()

        const code = e.dataTransfer.getData('code')

        if (remainingPartCodes.findIndex((part) => part.code === code) !== -1) {
            return
        }

        setRemainingPartCodes((prev) => {
            const newPart = partCodes.find((part) => part.code === code)

            return [...prev, newPart]
        })

        setScannedPartCodes((prev) => {
            const index = prev.findIndex((c) => c.code === code)
            if (index === -1) {
                return prev
            }
            const newArr = prev.filter((c) => c.code !== code)
            return newArr
        })
    }

    const dropToTable = (e, index) => {
        e.preventDefault()
        if (!scannedPartCodes[index] || scannedPartCodes[index].code) {
            return
        }

        const code = e.dataTransfer.getData('code')

        setScannedPartCodes((prev) => {
            const newArr = [...prev]

            newArr[index].code = code

            const thisNewPart = remainingPartCodes.find(
                (partCode) => partCode.code === code,
            )

            newArr[index].name = thisNewPart.name
            newArr[index].returnable = thisNewPart.returnable
            newArr[index].serializable = thisNewPart.serializable

            return [...newArr]
        })

        setRemainingPartCodes((prev) => {
            return [...prev].filter((p) => p.code !== code)
        })

        openSnackbar({
            message: 'Assigned part code to part number successfully',
            type: 'success',
        })
    }

    const onNextClick = () => {
        const savedParts = [...scannedPartCodes]
            .filter(
                (part) =>
                    part.code?.length > 0 && part.new_part_number?.length > 0,
            )
            .map((item) => {
                return {
                    code: item.code,
                    old_part_number: item.old_part_number || null,
                    new_part_number: item.new_part_number,
                    old_ppid: item.old_part_number || null,
                    new_ppid: item.new_ppid || '',
                    returnable: item.returnable || false,
                    serializable: item.serializable || false,
                }
            })

        const hasError = savedParts.some((partCode) => {
            return (
                (partCode.serializable || partCode.returnable) &&
                (partCode.new_ppid === null || !partCode.new_ppid.length)
            )
        })

        if (hasError) {
            openSnackbar({
                message: 'Please fill all the required fields!',
                type: 'error',
            })

            return
        }

        const validatePpid = displayPartCodes?.some(
            (item) =>
                !_.isEmpty(item.new_ppid) &&
                !textValidateRegex(ppidRegex, item.new_ppid),
        )

        if (validatePpid) {
            openSnackbar({
                message: t('message.ppidAttemptedCharacter'),
                type: 'error',
            })

            return
        }

        if (savedParts.length > 0) {
            saveReceivedParts(
                {
                    issueId,
                    parts: savedParts,
                },
                {
                    onSuccess: () => {
                        advanceRepair(
                            { issueId, step: 2 },
                            {
                                onSuccess: () => {
                                    queryClient.invalidateQueries([
                                        'issue',
                                        issueId,
                                    ])
                                },
                            },
                        )
                    },
                },
            )
        }
    }

    const resetTable = () => {
        setPpids([])
        setScannedPartCodes([])
        setIsError(false)

        setRemainingPartCodes(() => {
            return [...partCodes].filter((code) => !code.new_part_number)
        })
    }

    const closeModal = () => {
        resetTable()
        setOpenScanReceivedPart(false)
    }

    const onDeleteIconClick = (index) => {
        const deletedRow = { ...scannedPartCodes[index] }
        if (typeof deletedRow.additional === 'undefined') {
            setRemainingPartCodes((prev) => {
                const newPart = partCodes.find(
                    (part) => part.code === deletedRow.code,
                )
                return [...prev, newPart]
            })
        }

        setScannedPartCodes((prev) => {
            return [...prev].filter((v, i) => {
                return i !== index
            })
        })

        setPpids((prev) => {
            return [...prev].filter((v, i) => {
                return i !== index
            })
        })
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
            }}
        >
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ border: '1px solid black' }}>
                                <ComponentTooltip
                                    componentId={`part_number_header`}
                                    pagePath={ISSUE_ISSUE_REPAIR}
                                    noStack
                                >
                                    Part number
                                </ComponentTooltip>
                            </TableCell>

                            <TableCell sx={{ border: '1px solid black' }}>
                                <ComponentTooltip
                                    componentId={`part_code_header`}
                                    pagePath={ISSUE_ISSUE_REPAIR}
                                    noStack
                                >
                                    Part code
                                </ComponentTooltip>
                            </TableCell>
                            <TableCell sx={{ border: '1px solid black' }}>
                                <ComponentTooltip
                                    componentId={`part_name_header`}
                                    pagePath={ISSUE_ISSUE_REPAIR}
                                    noStack
                                >
                                    Part name
                                </ComponentTooltip>
                            </TableCell>
                            <TableCell sx={{ border: '1px solid black' }}>
                                <ComponentTooltip
                                    componentId={`ppid_header`}
                                    pagePath={ISSUE_ISSUE_REPAIR}
                                    noStack
                                >
                                    PPID (If Available)
                                </ComponentTooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {displayPartCodes.map((partCode, index) => {
                        const isSerializableOrReturnable =
                            partCode.serializable || partCode.returnable
                        const hasNewPpid = partCode.new_ppid?.length > 0
                        const isValidPpid =
                            ppidRegex &&
                            !textValidateRegex(ppidRegex, partCode.new_ppid)

                        const ppidError =
                            (isSerializableOrReturnable && !hasNewPpid) ||
                            (hasNewPpid && isValidPpid)
                        return (
                            <TableBody key={index}>
                                <TableCell sx={{ border: '1px solid black' }}>
                                    <TextField
                                        value={partCode.new_part_number || ''}
                                        onChange={(e) => {
                                            setScannedPartCodes((prev) => {
                                                const newArr = [...prev]
                                                if (index >= newArr.length) {
                                                    newArr.push({
                                                        new_part_number:
                                                            e.target.value,
                                                    })
                                                }
                                                newArr[index].new_part_number =
                                                    e.target.value
                                                return newArr
                                            })
                                        }}
                                        key={index}
                                        variant='outlined'
                                        onKeyDown={(e) => {
                                            if (
                                                e.key.toLowerCase() === 'enter'
                                            ) {
                                                e.preventDefault()
                                                savePartNumber(e, index)
                                            }
                                        }}
                                    />
                                </TableCell>
                                {(remainingPartCodes.length === 0 &&
                                    (typeof partCode.code === 'undefined' ||
                                        (typeof partCode.additional !==
                                            'undefined' &&
                                            partCode.additional === true))) ||
                                (remainingPartCodes.length > 0 &&
                                    typeof partCode.additional !==
                                        'undefined' &&
                                    partCode.additional === true) ? (
                                    <TableCell
                                        id={`part_${index}`}
                                        sx={{ border: '1px solid black' }}
                                    >
                                        <Autocomplete
                                            value={
                                                partCode.code
                                                    ? { code: partCode.code }
                                                    : null
                                            }
                                            sx={{ width: 120 }}
                                            id={`partcode_auto_${index}`}
                                            options={allPartCodes}
                                            getOptionLabel={(option) =>
                                                option.code
                                            }
                                            renderOption={(props, option) => (
                                                <Box component='li' {...props}>
                                                    {option.code}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label='Part Code'
                                                    inputProps={{
                                                        ...params.inputProps,
                                                    }}
                                                />
                                            )}
                                            isOptionEqualToValue={(
                                                option,
                                                value,
                                            ) =>
                                                value === undefined ||
                                                value === '' ||
                                                option.id === value.id
                                            }
                                            onChange={(_, newValue) => {
                                                if (
                                                    newValue?.code &&
                                                    newValue?.name
                                                ) {
                                                    setScannedPartCodes(
                                                        (prev) => {
                                                            const newArr = [
                                                                ...prev,
                                                            ]

                                                            if (
                                                                newValue.allow_multiple
                                                            ) {
                                                                newArr[
                                                                    index
                                                                ].code =
                                                                    newValue.code
                                                                newArr[
                                                                    index
                                                                ].name =
                                                                    newValue.name
                                                                newArr[
                                                                    index
                                                                ].additional =
                                                                    true
                                                                newArr[
                                                                    index
                                                                ].returnable =
                                                                    newValue.returnable
                                                                newArr[
                                                                    index
                                                                ].serializable =
                                                                    newValue.serializable
                                                            } else {
                                                                const existingCodes =
                                                                    scannedPartCodes
                                                                        .map(
                                                                            (
                                                                                item,
                                                                            ) =>
                                                                                item.code,
                                                                        )
                                                                        .filter(
                                                                            (
                                                                                item,
                                                                            ) =>
                                                                                typeof item !==
                                                                                'undefined',
                                                                        )
                                                                if (
                                                                    !existingCodes.includes(
                                                                        newValue.code,
                                                                    )
                                                                ) {
                                                                    newArr[
                                                                        index
                                                                    ].code =
                                                                        newValue.code
                                                                    newArr[
                                                                        index
                                                                    ].name =
                                                                        newValue.name
                                                                    newArr[
                                                                        index
                                                                    ].additional =
                                                                        true
                                                                    newArr[
                                                                        index
                                                                    ].returnable =
                                                                        newValue.returnable
                                                                    newArr[
                                                                        index
                                                                    ].serializable =
                                                                        newValue.serializable
                                                                } else {
                                                                    openSnackbar(
                                                                        {
                                                                            message:
                                                                                'This part cannot be duplicated',
                                                                            type: 'error',
                                                                        },
                                                                    )
                                                                }
                                                            }
                                                            return newArr
                                                        },
                                                    )
                                                }
                                            }}
                                        />
                                    </TableCell>
                                ) : (
                                    <TableCell
                                        id={`part_${index}`}
                                        sx={{ border: '1px solid black' }}
                                        onDrop={(e) => dropToTable(e, index)}
                                        onDragOver={allowDrop}
                                    >
                                        {typeof partCode.code !==
                                            'undefined' && (
                                            <div
                                                id={`existed_${index}`}
                                                draggable
                                                onDragStart={(e) =>
                                                    drag(e, index)
                                                }
                                                onDrop={(e) => {
                                                    e.stopPropagation()
                                                }}
                                                style={{
                                                    cursor: 'grab',
                                                }}
                                            >
                                                {partCode.code}
                                            </div>
                                        )}
                                    </TableCell>
                                )}
                                <TableCell sx={{ border: '1px solid black' }}>
                                    {partCode.name}
                                </TableCell>
                                <TableCell sx={{ border: '1px solid black' }}>
                                    <Grid container>
                                        <Grid
                                            item
                                            style={
                                                partCode.new_part_number
                                                    ?.length > 0
                                                    ? { width: '75%' }
                                                    : {}
                                            }
                                        >
                                            <Stack>
                                                <TextField
                                                    variant='outlined'
                                                    onKeyDown={(e) =>
                                                        onEnterPressedPpid(
                                                            e,
                                                            index,
                                                        )
                                                    }
                                                    onBlur={(e) => {
                                                        saveNewPPID(
                                                            index,
                                                            e.target.value,
                                                        )
                                                    }}
                                                    value={ppids[index] || ''}
                                                    error={ppidError}
                                                    onChange={(e) => {
                                                        setPpids((prev) => {
                                                            const newArr = [
                                                                ...prev,
                                                            ]
                                                            newArr[index] =
                                                                e.target.value
                                                            return newArr
                                                        })
                                                    }}
                                                    sx={{
                                                        '& .MuiInputBase-input.Mui-disabled':
                                                            {
                                                                backgroundColor:
                                                                    '#d3d3d3',
                                                            },
                                                    }}
                                                />
                                                {(partCode.serializable ||
                                                    partCode.returnable) &&
                                                    !partCode.new_ppid
                                                        ?.length && (
                                                        <Box
                                                            sx={{
                                                                color: (
                                                                    theme,
                                                                ) =>
                                                                    theme
                                                                        .palette
                                                                        .error
                                                                        .light,
                                                            }}
                                                        >
                                                            {'* required'}
                                                        </Box>
                                                    )}
                                            </Stack>
                                        </Grid>
                                        <Grid
                                            item
                                            alignItems='stretch'
                                            style={
                                                partCode.new_part_number
                                                    ?.length > 0
                                                    ? {
                                                          display: 'flex',
                                                          marginLeft: '2px',
                                                      }
                                                    : { display: 'none' }
                                            }
                                        >
                                            {partCode.new_part_number?.length >
                                                0 && (
                                                <IconButton
                                                    style={{
                                                        width: '100%',
                                                        paddingLeft: '1px',
                                                        paddingRight: '1px',
                                                    }}
                                                    onClick={() => {
                                                        onDeleteIconClick(index)
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableBody>
                        )
                    })}
                </Table>
            </TableContainer>

            <Box>
                <ComponentTooltip
                    componentId={`part_pool_header`}
                    pagePath={ISSUE_ISSUE_REPAIR}
                >
                    <Typography variant='h6'>
                        Drag these parts to their corresponding part number:
                    </Typography>
                </ComponentTooltip>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        width: '100%',
                        height: 30,
                        padding: '30px 10px',
                        border: '1px solid black',
                        borderRadius: '5px',
                        alignItems: 'center',
                    }}
                    id='init_part_code_box'
                    onDrop={dropToWaitingList}
                    onDragOver={allowDrop}
                >
                    {remainingPartCodes.map((part, index) => {
                        return (
                            <div
                                id={`drag_${index}`}
                                key={part.code}
                                draggable
                                onDragStart={drag}
                                onDrop={(e) => {
                                    e.stopPropagation()
                                }}
                                style={{
                                    cursor: 'grab',
                                }}
                            >
                                <Tooltip
                                    arrow
                                    title={part.name}
                                    placement='top'
                                >
                                    <span>{part.code}</span>
                                </Tooltip>
                            </div>
                        )
                    })}
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    gap: '10px',
                    flexDirection: 'row-reverse',
                }}
            >
                <LoadingButton
                    disabled={remainingPartCodes.length > 0 || isError}
                    label='Next'
                    onClick={onNextClick}
                />
                {scannedPartCodes.length > 0 && (
                    <LoadingButton label='Reset' onClick={resetTable} />
                )}
                <LoadingButton
                    style={{ backgroundColor: '#102F44' }}
                    label='Cancel'
                    onClick={closeModal}
                />
            </Box>
        </Box>
    )
}

PartTable.propTypes = {
    issueId: propTypes.oneOfType([propTypes.number, propTypes.string]),
    setOpenScanReceivedPart: propTypes.func,
    ppidRegex: propTypes.string,
}

export default PartTable
