import { TableBody, TableRow } from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import TableLoading from '~/components/Table/TableLoading'
import { headCells } from './constants'
import { TableCell, TableClickableCell } from '~/components/TableCell'
import { ISSUE_BOM_DETAIL } from '~/constants/Routes'
import PropTypes from 'prop-types'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import usePermissions from '~/hooks/usePermission'

const BomTableBody = ({ isLoading, boms }) => {
    const { permissions } = usePermissions()
    const hasEditBomRight = permissions.issue.bom.canEditBom

    if (isLoading) {
        return <TableLoading colSpan={headCells.length} />
    }

    if (boms.length < 1) {
        return (
            <TableBody>
                <DataNotFound colSpan={headCells.length} />
            </TableBody>
        )
    }
    return (
        <TableBody>
            {boms?.map((row, index) => (
                <TableRow
                    key={index}
                    sx={{
                        '&:last-child td, &:last-child th': {
                            border: 0,
                        },
                    }}
                >
                    {hasEditBomRight ? (
                        <TableClickableCell
                            cellValue={row.issue_id}
                            link={ISSUE_BOM_DETAIL.replace(
                                ':issueId',
                                row.issue_id,
                            )}
                        />
                    ) : (
                        <TableCell cellValue={row.issue_id} />
                    )}
                    <TableCell cellValue={row.name} />
                    <TableCell cellValue={row.bom_state} />
                    <TableCell
                        cellValue={
                            row.created_at ? (
                                <DateTimeLocale datetime={row.created_at} />
                            ) : (
                                '---'
                            )
                        }
                    />
                </TableRow>
            ))}
        </TableBody>
    )
}

BomTableBody.propTypes = {
    isLoading: PropTypes.bool,
    boms: PropTypes.array,
}

export default BomTableBody
