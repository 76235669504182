import useQuery from '~/hooks/useQuery'
import issueApi from '~/api/issueApi'
import { removeEmptyValue } from '~/utils/helpers'
import diagnosticApi from '~/api/diagnosticApi'
import { DEFAULT_CACHE_TTL } from '~/utils/constants'

export const useIssuesQuery = (params) => {
    return useQuery(['issues', params], async () => {
        const cleanParams = removeEmptyValue(params)
        if (typeof cleanParams.orderBy === 'undefined') {
            cleanParams.orderBy = 'id'
        }
        const { data } = await issueApi.getIssues(cleanParams)
        return { data: data.data, total: data.meta.total }
    })
}
export const useIssueQuery = (id, isConfirmForm = false) => {
    return useQuery(
        isConfirmForm ? ['issue_for_qc_form', id] : ['issue', id],
        async () => {
            const { data } = await issueApi.getIssue(id)
            return data
        },
        {
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        },
    )
}

export const useFormFieldsOptionsQuery = () => {
    return useQuery(['issue', 'get_form_fields_options'], async () => {
        const { data } = await issueApi.getFormFieldsOptions()
        return data
    })
}

export const useIssuePartCodesQuery = (issueId) => {
    return useQuery(['issue', 'get_issue_part_codes'], async () => {
        const { data } = await diagnosticApi.getPartIssueDiagnostic(issueId)
        return data.data
    })
}

export const useGetAllIssueStatusQuery = () => {
    return useQuery(['get_all_issue_status'], async () => {
        const { data } = await issueApi.getAllIssueStatus()
        return data
    })
}

export const useGetAllServiceCenterQuery = () => {
    return useQuery(['get_all_service_center'], async () => {
        const { data } = await issueApi.getAllServiceCenter()
        return data
    })
}
