import AddIcon from '@mui/icons-material/Add'
import {
    Divider,
    TableBody as MuiTableBody,
    TableRow as MuiTableRow,
    Paper,
} from '@mui/material'
import ComponentTooltip from '~/components/ComponentTooltip'
import DataNotFound from '~/components/DataNotFound'
import { TagColumnConfig } from '~/components/HeaderTable/TagColumnConfig'
import Loading from '~/components/Loading'
import PageName from '~/components/PageName'
import PaginationTable from '~/components/PaginationTable'
import SearchToolBar from '~/components/SearchToolBar'
import TableContainer from '~/components/Table/TableContainer'
import TableLoading from '~/components/Table/TableLoading'
import TableToolbarContainer from '~/components/Table/TableToolbarContainer'
import { TableCell, TableClickableCell } from '~/components/TableCell'
import TableHeader from '~/components/TableHeader'
import ToolBarButton from '~/components/ToolBarButton'
import {
    ADMINISTRATOR_NAMING_TAG,
    ADMINISTRATOR_NAMING_TAG_CREATE,
    ADMINISTRATOR_NAMING_TAG_DETAIL,
} from '~/constants/Routes'
import useTable from '~/hooks/useTable'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useNamingTagQuery } from '../query'
import PropTypes from 'prop-types'

const TagTableBody = ({ isLoading, tags }) => {
    if (isLoading) {
        return <TableLoading colSpan={TagColumnConfig.length} />
    }
    return (
        <MuiTableBody>
            {tags.length > 0 ? (
                tags.map((row) => (
                    <MuiTableRow hover tabIndex={-1} key={row.id}>
                        <TableCell cellValue={row?.id} />
                        <TableClickableCell
                            cellValue={row?.name}
                            link={ADMINISTRATOR_NAMING_TAG_DETAIL.replace(
                                `:id`,
                                row.id,
                            )}
                        />
                    </MuiTableRow>
                ))
            ) : (
                <DataNotFound colSpan={TagColumnConfig.length} />
            )}
        </MuiTableBody>
    )
}

TagTableBody.propTypes = {
    isLoading: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.object),
}

const Table = () => {
    const { t } = useTranslation()
    const localization = 'pages.administrator.namingTag.'
    const navigate = useNavigate()

    const {
        results,
        data,
        isLoading,
        page,
        search,
        order,
        orderBy,
        sortHandler,
        searchHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useNamingTagQuery,
    })
    if (isLoading) {
        return <Loading />
    }
    return (
        <Paper>
            <ComponentTooltip
                componentId={`page_title_naming_tag`}
                pagePath={ADMINISTRATOR_NAMING_TAG}
            >
                <div>
                    <PageName name='Naming Tags' />
                </div>
            </ComponentTooltip>
            <TableToolbarContainer>
                <ToolBarButton
                    icon={<AddIcon />}
                    name={t(`${localization}add`)}
                    handleAction={() => {
                        navigate(ADMINISTRATOR_NAMING_TAG_CREATE)
                    }}
                />
            </TableToolbarContainer>
            <TableToolbarContainer>
                <SearchToolBar
                    filterSearch={search}
                    onFilterSearch={searchHandler}
                    placeholder={`tags`}
                    sx={{
                        width: '20%',
                    }}
                    onEnterSearch={onEnterSearch}
                />
            </TableToolbarContainer>
            <Divider />
            <TableContainer>
                <TableHeader
                    headCells={TagColumnConfig}
                    localization={'pages.administrator.namingTag.'}
                    onRequestSort={sortHandler}
                    order={order}
                    orderBy={orderBy}
                />
                <TagTableBody isLoading={isLoading} tags={results} />
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}

export default Table
