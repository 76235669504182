import PropTypes from 'prop-types'
import { Link, Typography } from '@mui/material'

const ExternalLink = ({
    extLink = '',
    variant,
    param = '',
    tailParam = '',
    nameButton = '',
    ...props
}) => {
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer')
    }
    return (
        <Link
            component='button'
            variant='inherit'
            underline='hover'
            onClick={() => openInNewTab(`${extLink}${param}${tailParam}`)}
        >
            <Typography variant={variant ?? 'body2'} align='center' {...props}>
                {nameButton || param}
            </Typography>
        </Link>
    )
}

ExternalLink.propTypes = {
    extLink: PropTypes.string,
    variant: PropTypes.string,
    param: PropTypes.string,
    tailParam: PropTypes.string,
    nameButton: PropTypes.string,
}

export default ExternalLink
