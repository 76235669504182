import { useDispatch, useSelector } from 'react-redux'
import { selectShowedPartCodeForDiagnostic } from '~/store/partcode/selector'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { pushShowPartCodesForDiagnostic } from '~/store/partcode/slice'
import { TableCell, TableRow } from '@mui/material'
import { LoadingButton } from '~/components/Button'
import propTypes from 'prop-types'
import MaliciousDamageSelectionComponent from '~/pages/Diagnostic/Detail/PartCode/MaliciousDamageSelectionComponent'
import PartCodeQtyComponent from '~/pages/Diagnostic/Detail/PartCode/PartCodeQtyComponent'
import PartCodePPIDComponent from '~/pages/Diagnostic/Detail/PartCode/PartCodePPIDComponent'
import PartCodeAdditionalDescriptionComponent from '~/pages/Diagnostic/Detail/PartCode/PartCodeAdditionalDescriptionComponent'

const PartCodeRow = ({
    partCode,
    showMaliciousCondition,
    finishDiagnostic,
    hasAddAdditionalPartsRight,
    errorPartCodes,
    ppidRegex,
    hasManuallyAddedPart,
}) => {
    const showingPartCodes = useSelector(selectShowedPartCodeForDiagnostic)
    const dispatch = useDispatch()
    const [condition, setCondition] = useState(partCode.condition || '')
    const [qty, setQty] = useState(1)
    const [ppid, setPpid] = useState('')
    const [additionalDesc, setAdditionalDesc] = useState(null)
    const [validatePPid, setValidatePPid] = useState(false)

    const handleChange = (e) => {
        const conditionValue = e.target.value
        setCondition(conditionValue)
        const updatedShowingPartCodes = showingPartCodes.map((obj) => {
            if (obj.code === partCode.code) {
                return {
                    ...obj,
                    condition: conditionValue,
                    qty,
                    ppid,
                    additional_description: additionalDesc,
                }
            } else {
                return obj
            }
        })
        dispatch(pushShowPartCodesForDiagnostic(updatedShowingPartCodes))
    }

    const detachPartCodeFromDiagnostic = useCallback(
        (partCode) => {
            const newShowedPartCode = showingPartCodes.filter(
                (part) => part.code !== partCode.code,
            )
            dispatch(pushShowPartCodesForDiagnostic(newShowedPartCode))
        },
        [dispatch, showingPartCodes],
    )

    const loadCondition = useCallback(() => {
        showingPartCodes.map((obj) => {
            if (obj.code === partCode.code) {
                setCondition(obj.condition)
            }
            return obj
        })
    }, [partCode.code, showingPartCodes])

    useEffect(() => {
        loadCondition()
    }, [loadCondition])

    const renderCauseTags = useCallback((causes) => {
        return causes.map((x) => x.tag ?? '#').join(', ')
    }, [])

    const errorPPID = useMemo(() => {
        return !!errorPartCodes.find(
            (item) => item.code === partCode.code && !partCode.old_ppid,
        )
    }, [errorPartCodes, partCode.code, partCode.old_ppid])

    const isManuallyAdded = useMemo(
        () =>
            partCode.causes.some(
                (cause) => cause.id === '#' || cause.id === null,
            ),
        [partCode],
    )

    return (
        <TableRow
            key={partCode.code}
            sx={{
                '&:last-child td, &:last-child th': {
                    border: 0,
                },
            }}
        >
            <TableCell>{partCode.code ? partCode.code : '---'}</TableCell>
            <TableCell align='left'>
                {partCode.name ? partCode.name : '---'}
            </TableCell>
            <TableCell align='left'>
                {partCode.type ? partCode.type : '---'}
            </TableCell>
            <TableCell align='left'>
                <MaliciousDamageSelectionComponent
                    {...{
                        showMaliciousCondition,
                        handleChange,
                        finishDiagnostic,
                        condition,
                    }}
                />
            </TableCell>
            <TableCell>
                <PartCodeQtyComponent
                    setQty={setQty}
                    partCode={partCode}
                    finishDiagnostic={finishDiagnostic}
                />
            </TableCell>
            <TableCell>
                {partCode.serializable && (
                    <PartCodePPIDComponent
                        {...{
                            setPpid,
                            partCode,
                            validatePPid,
                            setValidatePPid,
                            finishDiagnostic,
                            errorPPID,
                            ppidRegex,
                        }}
                    />
                )}
            </TableCell>
            <TableCell align='center'>
                {renderCauseTags(partCode.causes)}
            </TableCell>
            {hasManuallyAddedPart && (
                <TableCell align='center'>
                    {isManuallyAdded && (
                        <PartCodeAdditionalDescriptionComponent
                            {...{
                                setAdditionalDesc,
                                partCode,
                                finishDiagnostic,
                            }}
                        />
                    )}
                </TableCell>
            )}
            {!finishDiagnostic && (
                <TableCell align='center'>
                    <LoadingButton
                        onClick={() => detachPartCodeFromDiagnostic(partCode)}
                        label='Remove'
                        bgColor='#102F44'
                        disabled={
                            finishDiagnostic ||
                            (renderCauseTags(partCode.causes) === '#' &&
                                !hasAddAdditionalPartsRight) ||
                            (!isNaN(partCode?.causes[0]?.id) &&
                                partCode?.causes[0]?.id !== null)
                        }
                    />
                </TableCell>
            )}
        </TableRow>
    )
}

PartCodeRow.propTypes = {
    partCode: propTypes.object,
    showMaliciousCondition: propTypes.bool,
    finishDiagnostic: propTypes.bool,
    hasAddAdditionalPartsRight: propTypes.bool,
    errorPartCodes: propTypes.array,
    ppidRegex: propTypes.string,
    hasManuallyAddedPart: propTypes.bool,
}

export default PartCodeRow
