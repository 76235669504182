import userApi from '~/api/userApi'

import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { setSnackbar } from '~/store/snackbar/slice'
import { useParams } from 'react-router-dom'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { useTranslation } from 'react-i18next'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useUpdateRoleMutation = () => {
    const dispatch = useDispatch()
    const urlParams = useParams()
    const queryClient = useQueryClient()

    return useMutation(
        ['role_update'],
        async (values) => {
            return await userApi.updateRole(urlParams.id, { role_id: values })
        },
        {
            onSuccess: (data) => {
                dispatch(
                    setSnackbar({
                        message: data.message
                            ? data.message
                            : 'Save successful!',
                        type: 'success',
                    }),
                )
                queryClient.invalidateQueries('user')

                return data
            },
            onError: (error) => {
                dispatch(
                    setSnackbar({
                        message: error.message ? error.message : 'Save failed!',
                        type: 'error',
                    }),
                )
            },
            retry: false,
        },
    )
}

export const useUpdateTimezoneMutation = () => {
    const dispatch = useDispatch()
    const urlParams = useParams()
    const queryClient = useQueryClient()

    return useMutation(
        ['timezone_update'],
        async (values) => {
            return await userApi.updateUserTimezone(urlParams.id, {
                timezone: values,
            })
        },
        {
            onSuccess: (data) => {
                dispatch(
                    setSnackbar({
                        message: data.message
                            ? data.message
                            : 'Save successful!',
                        type: 'success',
                    }),
                )
                queryClient.invalidateQueries('user')

                return data
            },
            onError: (error) => {
                dispatch(
                    setSnackbar({
                        message: error.message ? error.message : 'Save failed!',
                        type: 'error',
                    }),
                )
            },
            retry: false,
        },
    )
}

export const useJoinTeamMutation = () => {
    const { openSnackbar } = useDialogContext()
    const queryClient = useQueryClient()
    const { t } = useTranslation()

    return useMutation(
        ['user_join_team'],
        async ({ user_id, team_id }) => {
            const { data } = await userApi.joinTeam({ user_id, team_id })
            return data
        },
        {
            onSuccess: (data) => {
                openSnackbar({
                    message: t('message.setTeamForUserSuccessful'),
                    type: 'success',
                })
                queryClient.invalidateQueries('user')
                queryClient.invalidateQueries('user_teams')
                queryClient.invalidateQueries([
                    'teams_user_not_in',
                    data?.data?.id,
                ])

                return data
            },
            onError: () => {
                openSnackbar({
                    message: t('message.setTeamForUserFailed'),
                    type: 'error',
                })
            },
            retry: false,
        },
    )
}

export const useSwitchActiveTeamMutation = () => {
    const { openSnackbar } = useDialogContext()
    const queryClient = useQueryClient()
    const { t } = useTranslation()

    return useMutation(
        ['switch_active_team'],
        async ({ user_id, team_id }) => {
            const { data } = await userApi.switchActiveTeam({
                user_id,
                team_id,
            })
            return data
        },
        {
            onSuccess: (data) => {
                openSnackbar({
                    message: t('message.activeTeamSuccessful'),
                    type: 'success',
                })
                queryClient.invalidateQueries('user')
                queryClient.invalidateQueries('user_teams')

                return data
            },
            onError: (error) => {
                openSnackbar({
                    message: parseApiErrorMessage(error),
                    type: 'error',
                })
            },
            retry: false,
        },
    )
}

export const useRemoveTeamMutation = () => {
    const { openSnackbar } = useDialogContext()
    const queryClient = useQueryClient()
    const { t } = useTranslation()

    return useMutation(
        ['remove_team'],
        async ({ user_id, team_id }) => {
            const { data } = await userApi.removeTeam({
                user_id,
                team_id,
            })
            return data
        },
        {
            onSuccess: (data) => {
                openSnackbar({
                    message: t('message.removeTeamSuccessful'),
                    type: 'success',
                })
                queryClient.invalidateQueries('user')
                queryClient.invalidateQueries('user_teams')
                queryClient.invalidateQueries([
                    'teams_user_not_in',
                    data?.data?.id,
                ])

                return data
            },
            onError: (error) => {
                openSnackbar({
                    message: parseApiErrorMessage(error),
                    type: 'error',
                })
            },
            retry: false,
        },
    )
}
