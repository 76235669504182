import { LoadingButton } from '@mui/lab'
import { Box, Menu, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useDeviceStateQuery } from '../../Overview/query'
import { useUpdateDeviceStateMutation } from '../mutation'

const ITEM_HEIGHT = 48

const StateButton = ({ device }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const {
        data: deviceState,
        isLoading,
        isError,
    } = useDeviceStateQuery({
        menuOption: true,
    })

    const { mutate: updateDeviceState, isLoading: isLoadingUpdateDeviceState } =
        useUpdateDeviceStateMutation()

    const renderedDeviceState = useMemo(() => {
        if (deviceState?.data?.length === 0) return []
        return deviceState?.data.filter(
            (state) => state.tid !== device?.state?.tid,
        )
    }, [device?.state?.tid, deviceState?.data])

    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <LoadingButton
                variant='outlined'
                loading={isLoadingUpdateDeviceState}
                sx={{
                    borderRadius: '40px',
                    padding: '5px 20px',
                    backgroundColor: device?.state?.icon_color,
                    color: 'black',
                }}
                startIcon={
                    <Box component='span' className='material-symbols-rounded'>
                        {device?.state?.icon}
                    </Box>
                }
                onClick={handleClick}
            >
                {device?.state?.tid.replaceAll('_', ' ')}
            </LoadingButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {isLoading && <MenuItem>Loading...</MenuItem>}
                {isError && <MenuItem>Failed to fetch</MenuItem>}
                {renderedDeviceState?.length > 0 &&
                    renderedDeviceState.map((state) => (
                        <MenuItem
                            key={state.tid}
                            s
                            onClick={() => {
                                updateDeviceState({
                                    state_tid: state.tid,
                                })
                                setAnchorEl(null)
                            }}
                        >
                            {state?.tid.replaceAll('_', ' ')}
                        </MenuItem>
                    ))}
            </Menu>
        </>
    )
}

StateButton.propTypes = {
    device: PropTypes.object.isRequired,
}

export default StateButton
