import { DeviceReplacePartHeadCells } from '~/components/HeaderTable/DeviceReplacePartHeadCells'
import CustomTable from '~/components/Table'
import { useSelector } from 'react-redux'
import { selectDevice } from '~/store/device/selector'
import { useDeviceReplaceablePartQuery } from './query'

const localization = 'pages.device.serial.detail.'

const DeviceReplaceablePart = () => {
    const device = useSelector(selectDevice)

    return (
        <CustomTable
            id={device.id.toString()}
            customQuery={useDeviceReplaceablePartQuery}
            headCells={DeviceReplacePartHeadCells}
            LOCALIZATION={localization}
        />
    )
}

export default DeviceReplaceablePart
