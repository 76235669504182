import { DeviceEntitlementHeadCells } from '~/components/HeaderTable/DeviceEntitlementHeadCells'
import CustomTable from '~/components/Table'
import { useDeviceEntitlementsQuery } from './query'
import PropTypes from 'prop-types'

const localization = 'pages.device.serial.detail.'

const DeviceEntitlements = ({ deviceId }) => {
    return (
        <CustomTable
            id={deviceId.toString()}
            customQuery={useDeviceEntitlementsQuery}
            headCells={DeviceEntitlementHeadCells}
            sx={{ fontWeight: 'Bold', color: 'red' }}
            LOCALIZATION={localization}
        />
    )
}

DeviceEntitlements.propTypes = {
    deviceId: PropTypes.number,
}

export default DeviceEntitlements
