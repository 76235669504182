import { Grid, TextareaAutosize } from '@mui/material'
import { useMemo } from 'react'
import SectionTitle from './components/SectionTitle'
import { useTranslation } from 'react-i18next'
import { LOCALIZATION } from './constants'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

const DiagnosticDescription = ({ value, locale }) => {
    const { t } = useTranslation()

    const valueAuto = useMemo(() => {
        if (value === undefined || value === null) return ''

        return value.reduce((val, b) => {
            return (
                val +
                `${locale ? b.name[locale] : b.name} : ${b.notes ?? 'NULL'}\n`
            )
        }, '')
    }, [value, locale])

    return (
        <Grid container>
            <SectionTitle
                title={t(LOCALIZATION + `diagnosticsDescription`)}
                xs={12}
                md={12}
                lg={12}
                align='left'
                ml={2}
            />

            <Grid item xs={12} md={12} lg={12} align='left' m={2}>
                <Field
                    name='description'
                    component={Textarea}
                    initialValue={valueAuto}
                />
            </Grid>
        </Grid>
    )
}

DiagnosticDescription.propTypes = {
    value: PropTypes.array,
    locale: PropTypes.string,
}

const Textarea = ({ input, ...props }) => {
    const defaultValue = useMemo(() => {
        return input.value
    }, [input.value])

    return (
        <TextareaAutosize
            disabled
            minRows={5}
            maxRows={5}
            defaultValue={defaultValue}
            style={{ width: '100%' }}
        />
    )
}

Textarea.propTypes = { input: PropTypes.object }

export default DiagnosticDescription
