import {
    Link,
    Table as MuiTable,
    TableBody as MuiTableBody,
    Paper,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { ProductionServerHeadCells } from '~/components/HeaderTable/ProductionServerHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { PRODUCTION_SERVERS_DETAIL } from '~/constants/Routes'
import useTable from '~/hooks/useTable'
import { useServersListQuery } from '../query'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const TableBody = ({ isLoading, results }) => {
    const navigate = useNavigate()
    if (isLoading) {
        return <TableLoading colSpan={ProductionServerHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {results.length > 0 &&
                results.map((row, index) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row?.id}>
                            <TableCell>
                                <Link
                                    component='button'
                                    variant='inherit'
                                    to='#'
                                    underline='hover'
                                    onClick={() =>
                                        navigate(
                                            PRODUCTION_SERVERS_DETAIL.replace(
                                                ':id',
                                                row?.id,
                                            ),
                                        )
                                    }
                                >
                                    {row?.id}
                                </Link>
                            </TableCell>
                            <TableCell>{row?.hostname}</TableCell>
                            <TableCell>{row?.name}</TableCell>
                            <TableCell>{row?.organisation?.name}</TableCell>
                            <TableCell>{row?.last_ip}</TableCell>
                            <TableCell>
                                <DateTimeLocale datetime={row.updated_at} />
                            </TableCell>
                        </TableRow>
                    )
                })}
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    isLoading: PropTypes.bool,
    results: PropTypes.arrayOf(PropTypes.object),
}

const ServersTable = () => {
    const {
        data,
        results,
        isLoading,
        page,
        order,
        orderBy,
        changePageHandler,
        sortHandler,
    } = useTable({
        query: useServersListQuery,
        initialStates: {
            orderBy: 'desc',
        },
        queryAttributes: {},
    })

    return (
        <Paper>
            <TableContainer
                sx={{ width: '100%' }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHeader
                        headCells={ProductionServerHeadCells}
                        localization={'pages.production.servers.'}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <TableBody isLoading={isLoading} results={results} />
                </MuiTable>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                size='medium'
                handleChangePage={changePageHandler}
            />
        </Paper>
    )
}

export default ServersTable
