import {
    Divider,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import DataNotFound from '~/components/DataNotFound'
import { VendorHeadCells } from '~/components/HeaderTable/VendorHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import { useNavigate } from 'react-router-dom'
import VendorListToolbar from './VendorListToolbar'
import VendorTableFilters from './VendorTableFilters'
import { useFleetVendorQuery } from './query'
import PropTypes from 'prop-types'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const localization = 'pages.device.fleet.table.'

const VendorTableBody = ({ results, isLoading }) => {
    const navigate = useNavigate()
    if (isLoading) {
        return <TableLoading colSpan={VendorHeadCells.length} />
    }
    return (
        <TableBody>
            {results.length > 0 ? (
                results.map((row, index) => {
                    return (
                        <TableRow hover tabIndex={-1} key={index}>
                            <TableCell component='th' scope='row'>
                                <Link
                                    component='button'
                                    variant='inherit'
                                    to='#'
                                    underline='hover'
                                    onClick={() => navigate('#')}
                                >
                                    <Typography variant='body2' align='center'>
                                        {row.vendor_name}
                                    </Typography>
                                </Link>
                            </TableCell>
                            <TableCell align='left'>
                                {row.device_count}
                            </TableCell>
                            <TableCell align='center'>
                                <DateTimeLocale
                                    datetime={row.oldest}
                                    formatHour={true}
                                />
                            </TableCell>
                            <TableCell align='center'>
                                <DateTimeLocale
                                    datetime={row.newest}
                                    formatHour={true}
                                />
                            </TableCell>
                            <TableCell align='center'>
                                {row.in_warranty ?? 0}
                            </TableCell>
                            <TableCell align='center'>
                                {row.out_of_warranty ?? 0}
                            </TableCell>
                            <TableCell align='center'>
                                {row.expire_in_year_warranty ?? 0}
                            </TableCell>
                            <TableCell align='center'>
                                {row.active ?? 0}
                            </TableCell>
                            <TableCell align='center'>
                                {row.stock ?? 0}
                            </TableCell>
                            <TableCell align='center'>
                                {row.other ?? 0}
                            </TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={VendorHeadCells.length} />
            )}
        </TableBody>
    )
}

VendorTableBody.propTypes = {
    results: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
}

const VendorTable = () => {
    const [vendor, setVendor] = useState('')

    const {
        results,
        data,
        isLoading,
        order,
        orderBy,
        page,
        search,
        setPage,
        searchHandler,
        sortHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useFleetVendorQuery,
        queryAttributes: {
            vendor,
        },
    })

    const refValue = useRef({
        vendor: '',
    })

    useEffect(() => {
        if (vendor !== refValue.current.vendor) {
            setPage(1)
            refValue.current.vendor = vendor
        }
    }, [vendor, setPage])

    return (
        <>
            <VendorListToolbar payload={{ vendor, search, order, orderBy }} />

            <Paper sx={{ width: '100%', mb: 2 }}>
                <VendorTableFilters
                    search={search}
                    searchHandler={searchHandler}
                    vendor={vendor}
                    setVendor={setVendor}
                    onEnterSearch={onEnterSearch}
                />
                <Divider />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby='tableTitle'
                        size='medium'
                    >
                        <TableHeader
                            headCells={VendorHeadCells}
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                            localization={localization}
                        />
                        <VendorTableBody
                            results={results}
                            isLoading={isLoading}
                        />
                    </Table>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    size='medium'
                    handleChangePage={changePageHandler}
                />
            </Paper>
        </>
    )
}
export default VendorTable
