import {
    Divider,
    Table as MuiTable,
    TableBody as MuiTableBody,
    Paper,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { ProductionProjectHeadCells } from '~/components/HeaderTable/ProductionProjectHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import {
    ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL,
    PRODUCTION_IMAGES_DETAIL,
    PRODUCTION_PROJECTS_DETAIL,
} from '~/constants/Routes'
import useTable from '~/hooks/useTable'
import { formatDateTime } from '~/utils/helpers'
import { useListDataForFilterQuery, useProjectsListQuery } from '../query'
import ProjectLink from './ProjectLink'
import ProjectTableSearchAndFilter from './ProjectTableSearchAndFilter'

const TableBody = ({ isLoading, results }) => {
    if (isLoading) {
        return <TableLoading colSpan={ProductionProjectHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {results.length > 0 &&
                results.map((row, index) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row?.id}>
                            <TableCell>{row?.id}</TableCell>
                            <TableCell>
                                <ProjectLink
                                    navigateLink={PRODUCTION_PROJECTS_DETAIL.replace(
                                        ':project_nr',
                                        row?.project_nr,
                                    )}
                                    value={row?.project_nr}
                                />
                            </TableCell>
                            <TableCell>{row?.entity_tid}</TableCell>
                            <TableCell>
                                <ProjectLink
                                    navigateLink={ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL.replace(
                                        ':id',
                                        row?.service_center_id,
                                    )}
                                    value={row?.service_center_id}
                                />
                            </TableCell>
                            <TableCell>
                                <ProjectLink
                                    navigateLink={'#'}
                                    value={row?.external_id}
                                />
                            </TableCell>
                            <TableCell>
                                <ProjectLink
                                    navigateLink={PRODUCTION_IMAGES_DETAIL.replace(
                                        ':id',
                                        row?.production_image_id,
                                    )}
                                    value={row?.production_image_id}
                                />
                            </TableCell>
                            <TableCell>{row?.active}</TableCell>
                            <TableCell>{row?.intune_group_tag}</TableCell>
                            <TableCell>
                                {formatDateTime(row?.updated_at)}
                            </TableCell>
                        </TableRow>
                    )
                })}
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    isLoading: PropTypes.bool,
    results: PropTypes.arrayOf(PropTypes.object),
}

const ProjectTable = () => {
    const [serviceCenter, setServiceCenter] = useState('')
    const [productionImage, setProductionImage] = useState('')
    const [organisation, setOrganisation] = useState('')

    const {
        data,
        results,
        isLoading,
        page,
        setPage,
        order,
        orderBy,
        search,
        searchHandler,
        onEnterSearch,
        changePageHandler,
        sortHandler,
    } = useTable({
        query: useProjectsListQuery,
        initialStates: {
            orderBy: 'desc',
        },
        queryAttributes: {
            serviceCenter,
            productionImage,
            organisation,
        },
    })

    // HANDLE DATA FILTER
    const { data: dataFilter } = useListDataForFilterQuery()

    const refValue = useRef({
        search: '',
        serviceCenter: '',
        productionImage: '',
        organisation: '',
    })

    useEffect(() => {
        if (serviceCenter !== refValue.current.serviceCenter) {
            setPage(1)
            refValue.current.serviceCenter = serviceCenter
        }
        if (productionImage !== refValue.current.productionImage) {
            setPage(1)
            refValue.current.productionImage = productionImage
        }
        if (organisation !== refValue.current.organisation) {
            setPage(1)
            refValue.current.organisation = organisation
        }
    }, [organisation, productionImage, serviceCenter, setPage])

    return (
        <Paper>
            <ProjectTableSearchAndFilter
                search={search}
                searchHandler={searchHandler}
                onEnterSearch={onEnterSearch}
                setServiceCenter={setServiceCenter}
                serviceCenter={serviceCenter}
                setProductionImage={setProductionImage}
                productionImage={productionImage}
                setOrganisation={setOrganisation}
                organisation={organisation}
                dataForFilter={dataFilter}
            />

            <Divider />

            <TableContainer
                sx={{ width: '100%' }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHeader
                        headCells={ProductionProjectHeadCells}
                        localization={'pages.production.projects.'}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <TableBody isLoading={isLoading} results={results} />
                </MuiTable>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                size='medium'
                handleChangePage={changePageHandler}
            />
        </Paper>
    )
}

export default ProjectTable
