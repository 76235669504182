import { DeviceComponentHeadCells } from '~/components/HeaderTable/DeviceComponentHeadCells'
import CustomTable from '~/components/Table'
import { useSelector } from 'react-redux'
import { selectDevice } from '~/store/device/selector'
import { useDeviceComponentsQuery } from './query'
import { PART_COMPONENT } from '~/constants/Routes'
const localization = 'pages.device.serial.detail.'

const DeviceComponents = () => {
    const device = useSelector(selectDevice)

    return (
        <CustomTable
            id={device.id.toString()}
            customQuery={useDeviceComponentsQuery}
            headCells={DeviceComponentHeadCells}
            LOCALIZATION={localization}
            navigateTo={PART_COMPONENT}
            navigateAt={['id']}
        />
    )
}

export default DeviceComponents
