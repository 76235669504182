import {
    Box,
    Checkbox,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIssuePartCodesQuery, useIssueQuery } from '../query'
import { LoadingButton } from '~/components/Button'
import { useSaveRepairMutation } from '../mutation'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { DASHBOARD, ISSUE_ISSUE } from '~/constants/Routes'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { PPIDPattern } from './constants'
import {
    DoaPopup,
    RepairCannotBeFinishedPopup,
} from '~/pages/Issue/Repair/Component/Popups'
import { useTranslation } from 'react-i18next'
import { textValidateRegex } from '~/utils/helpers'

const LOCALIZATION = 'pages.issues.repair.'

const RemovedPartTable = ({ issueId, setOpenRemovePart, techComment }) => {
    const { t } = useTranslation()
    const clientQuery = useQueryClient()
    const { openSnackbar } = useDialogContext()

    const { data: partCodeData, isSuccess } = useIssuePartCodesQuery(issueId)
    const { data: issue, isSuccess: isGetIssueSuccess } = useIssueQuery(issueId)
    const ppidRegex = issue?.data?.ticket?.device?.vendor?.ppid_validation_regex
    const [partCodes, setPartCodes] = useState([])
    const [comment, setComment] = useState(techComment)
    const [commentError, setCommentError] = useState('')
    const [
        openRepairCannotBeFinishedPopup,
        setOpenRepairCannotBeFinishedPopup,
    ] = useState(false)
    const [openDoaPopup, setOpenDoaPopup] = useState(false)
    const [doaPartCodes, setDoaPartCodes] = useState([])
    const [serial, setSerial] = useState('')
    const [selectedCauses, setSelectedCauses] = useState({})

    const { mutate: saveRepair, isLoading } = useSaveRepairMutation()

    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess) {
            const part = _.cloneDeep(partCodeData).map((p) => {
                return { ...p, replaced: true }
            })
            setPartCodes(part)
        }
    }, [isSuccess, partCodeData])

    useEffect(() => {
        if (isGetIssueSuccess) {
            setSerial(issue?.data?.name)
        }
    }, [issue, isGetIssueSuccess])

    const onRepairCannotBeFinishedClick = () => {
        const hasError = partCodes.some((partCode) => {
            if (partCode?.replaced === true) {
                if (
                    partCode?.old_part_number === null ||
                    partCode?.old_part_number.length === 0
                ) {
                    return true
                }

                if (
                    partCode?.doa === true &&
                    (partCode?.old_ppid === null ||
                        partCode?.old_ppid.length === 0)
                ) {
                    return true
                }
            }
            return false
        })

        if (hasError) {
            openSnackbar({
                message: t(`${LOCALIZATION}message.requiredAllFields`),
                type: 'error',
            })

            return
        }
        const doaPartCodes = partCodes.map((partCode) =>
            partCode?.doa === true ? partCode.code : '',
        )
        setDoaPartCodes(doaPartCodes.filter((partCode) => partCode.length > 0))

        const validatePpid = partCodes.some((partCode) => {
            const isNotEmpty = !_.isEmpty(partCode.old_ppid)
            const isInvalid = !textValidateRegex(ppidRegex, partCode.old_ppid)
            return isNotEmpty && isInvalid
        })

        if (validatePpid) {
            openSnackbar({
                message: t('message.ppidAttemptedCharacter'),
                type: 'error',
            })
            return
        }

        partCodes.some((partCode) => partCode.doa === true)
            ? setOpenDoaPopup(true)
            : setOpenRepairCannotBeFinishedPopup(true)
    }

    const onRepairCannotBeFinishedSaveCommentClick = () => {
        if (comment === '' || !comment) {
            setCommentError(t(`${LOCALIZATION}message.requiredComment`))
            return
        }

        const validatePpid = partCodes.some((partCode) => {
            const isNotEmpty = !_.isEmpty(partCode.old_ppid)
            const isInvalid = !textValidateRegex(ppidRegex, partCode.old_ppid)
            return isNotEmpty && isInvalid
        })

        if (validatePpid) {
            openSnackbar({
                message: t('message.ppidAttemptedCharacter'),
                type: 'error',
            })
            return
        }

        const causes =
            Object.keys(selectedCauses).length > 0
                ? Object.keys(selectedCauses)
                : null

        saveRepair(
            {
                issueId,
                payload: {
                    part_codes: partCodes,
                    repaired: false,
                    comment,
                    causes,
                },
            },
            {
                onSuccess: () => {
                    clientQuery.invalidateQueries(['issue', issueId])
                    clientQuery.invalidateQueries(['part_issue', issueId])
                    clientQuery.invalidateQueries(['issue_logs'])
                    if (partCodes.some((item) => item?.doa === true)) {
                        openSnackbar({
                            message: t(
                                `${LOCALIZATION}message.createdDoaIssueSuccessfully`,
                            ),
                            type: 'success',
                        })
                    }
                    navigate({ pathname: ISSUE_ISSUE })
                },
            },
        )
    }

    const onDeviceRepairedClick = () => {
        const hasError = partCodes.some((partCode) => {
            const checkReplace = partCode.replaced
            if (!checkReplace) {
                return false
            }

            const checkPartNumber =
                !!partCode.old_part_number && partCode.old_part_number !== ''

            const checkReturnableAndSerializable =
                partCode.returnable || partCode.serializable

            const checkPPID =
                (checkReturnableAndSerializable &&
                    !!partCode.old_ppid &&
                    partCode.old_ppid !== '') ||
                !checkReturnableAndSerializable

            return !checkPartNumber || !checkPPID
        })

        if (hasError) {
            openSnackbar({
                message: t(`${LOCALIZATION}message.requiredAllFields`),
                type: 'error',
            })

            return
        }

        const samePPID = partCodes.some((partCode) => {
            return partCode.same_ppid_text?.length
        })

        if (samePPID) {
            openSnackbar({
                message: t(`${LOCALIZATION}message.duplicatedPpid`),
                type: 'error',
            })

            return
        }

        const validatePpid = partCodes.some((partCode) => {
            const isNotEmpty = !_.isEmpty(partCode.old_ppid)
            const isInvalid = !textValidateRegex(ppidRegex, partCode.old_ppid)
            return isNotEmpty && isInvalid
        })

        if (validatePpid) {
            openSnackbar({
                message: t('message.ppidAttemptedCharacter'),
                type: 'error',
            })
            return
        }

        saveRepair(
            {
                issueId,
                payload: { part_codes: partCodes, repaired: true },
            },
            {
                onSuccess: () => {
                    setOpenRemovePart(false)
                    clientQuery.invalidateQueries(['issue', issueId])
                    clientQuery.invalidateQueries(['part_issue', issueId])
                    clientQuery.invalidateQueries(['issue_logs'])
                    navigate({ pathname: DASHBOARD })
                },
            },
        )
    }

    const samePpidOnChange = useCallback(
        (e, index) => {
            const newPPID = e.target.value

            const match = newPPID.match(PPIDPattern)

            setPartCodes((prev) => {
                const newArr = [...prev]

                newArr[index].old_ppid = newPPID
                newArr[index].old_part_number = match
                    ? match[1]
                    : newArr[index].old_part_number

                newArr[index].same_ppid_text = undefined
                if (
                    newArr[index].old_ppid.toLowerCase() ===
                    newArr[index].new_ppid?.toLowerCase()
                ) {
                    newArr[index].same_ppid_text = t(
                        `${LOCALIZATION}message.samePpid`,
                    )
                }

                return newArr
            })
            if (!textValidateRegex(ppidRegex, newPPID)) {
                openSnackbar({
                    message: t('message.ppidAttemptedCharacter'),
                    type: 'error',
                })
            }
        },
        [openSnackbar, ppidRegex, t],
    )

    const handleDoaClicked = useCallback((e, index) => {
        setPartCodes((prev) => {
            const newArr = [...prev]
            newArr[index].doa = e.target.checked

            return newArr
        })
    }, [])

    const hasDoaChecked = useMemo(
        () => partCodes.some((part) => part.doa),
        [partCodes],
    )

    useEffect(() => {
        const doaText = partCodes.reduce((text, part) => {
            if (part.doa) {
                return text + `${part.code}:` + '\n'
            }

            return text
        }, '\n')
        setComment(techComment + doaText)
    }, [partCodes, techComment])

    return (
        <>
            <Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {t(
                                        `${LOCALIZATION}removePartTable.receivedPartNumber`,
                                    )}
                                </TableCell>
                                <TableCell>
                                    {t(
                                        `${LOCALIZATION}removePartTable.partCode`,
                                    )}
                                </TableCell>
                                <TableCell>
                                    {t(
                                        `${LOCALIZATION}removePartTable.partName`,
                                    )}
                                </TableCell>
                                <TableCell>
                                    {t(
                                        `${LOCALIZATION}removePartTable.removedPartName`,
                                    )}
                                </TableCell>
                                <TableCell>
                                    {t(
                                        `${LOCALIZATION}removePartTable.removedPpid`,
                                    )}
                                </TableCell>
                                <TableCell>
                                    {t(
                                        `${LOCALIZATION}removePartTable.notReplaced`,
                                    )}
                                </TableCell>
                                <TableCell>
                                    {t(`${LOCALIZATION}removePartTable.doa`)}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {partCodes.map((part, index) => {
                                const hasSerial = part.serializable
                                const returnable = part.returnable
                                const doa = part.doa

                                const oldPpidExists = part.old_ppid?.length
                                const hasSamePpidText =
                                    typeof part.same_ppid_text !== 'undefined'
                                const samePpidTextLength =
                                    part.same_ppid_text?.length

                                const ppidValid =
                                    ppidRegex &&
                                    !_.isEmpty(part.old_ppid) &&
                                    !textValidateRegex(ppidRegex, part.old_ppid)

                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {part.new_part_number}
                                        </TableCell>
                                        <TableCell>{part.code}</TableCell>
                                        <TableCell>{part.name}</TableCell>
                                        <TableCell>
                                            <Stack>
                                                <TextField
                                                    autoFocus={index === 0}
                                                    disabled={!part.replaced}
                                                    error={
                                                        !part.old_part_number
                                                            ?.length
                                                    }
                                                    key={index}
                                                    variant='outlined'
                                                    value={part.old_part_number}
                                                    onChange={(e) => {
                                                        setPartCodes((prev) => {
                                                            const newArr = [
                                                                ...prev,
                                                            ]

                                                            newArr[
                                                                index
                                                            ].old_part_number =
                                                                e.target.value

                                                            return newArr
                                                        })
                                                    }}
                                                    sx={{
                                                        '& .MuiInputBase-input.Mui-disabled':
                                                            {
                                                                backgroundColor:
                                                                    '#d3d3d3',
                                                            },
                                                    }}
                                                />
                                                <Box
                                                    sx={{
                                                        color: (theme) =>
                                                            theme.palette.error
                                                                .light,
                                                        height: 10,
                                                    }}
                                                >
                                                    {!part.old_part_number
                                                        ?.length &&
                                                        part.replaced && (
                                                            <>{'* required'}</>
                                                        )}
                                                </Box>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            <Stack>
                                                <TextField
                                                    disabled={!part.replaced}
                                                    error={
                                                        ((hasSerial ||
                                                            returnable ||
                                                            doa) &&
                                                            (!oldPpidExists ||
                                                                (hasSamePpidText &&
                                                                    samePpidTextLength))) ||
                                                        ppidValid
                                                    }
                                                    helperText={
                                                        part.same_ppid_text
                                                    }
                                                    key={index}
                                                    variant='outlined'
                                                    value={part.old_ppid}
                                                    onChange={(e) =>
                                                        samePpidOnChange(
                                                            e,
                                                            index,
                                                        )
                                                    }
                                                    sx={{
                                                        '& .MuiInputBase-input.Mui-disabled':
                                                            {
                                                                backgroundColor:
                                                                    '#d3d3d3',
                                                            },
                                                    }}
                                                />
                                                <Box
                                                    sx={{
                                                        color: (theme) =>
                                                            theme.palette.error
                                                                .light,
                                                        height: 10,
                                                    }}
                                                >
                                                    {(part.serializable ||
                                                        part.returnable) &&
                                                        (!part.old_ppid
                                                            ?.length ||
                                                            (typeof part.same_ppid_text !==
                                                                'undefined' &&
                                                                part
                                                                    .same_ppid_text
                                                                    ?.length)) &&
                                                        part.replaced && (
                                                            <>{'* required'}</>
                                                        )}
                                                </Box>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                onClick={(e) => {
                                                    setPartCodes((prev) => {
                                                        const newArr = [...prev]

                                                        newArr[index].replaced =
                                                            !e.target.checked

                                                        return newArr
                                                    })
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                onClick={(e) =>
                                                    handleDoaClicked(e, index)
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        mt: 2,
                    }}
                >
                    <LoadingButton
                        label={t(`${LOCALIZATION}button.cancel`)}
                        onClick={() => {
                            setOpenRemovePart(false)
                        }}
                        loading={isLoading}
                    />
                    <LoadingButton
                        label={t(`${LOCALIZATION}button.cannotFinish`)}
                        onClick={onRepairCannotBeFinishedClick}
                        loading={isLoading}
                    />
                    <LoadingButton
                        label={t(`${LOCALIZATION}button.repair`)}
                        onClick={onDeviceRepairedClick}
                        loading={isLoading}
                        disabled={hasDoaChecked}
                    />
                </Box>
            </Box>

            <RepairCannotBeFinishedPopup
                closeHandler={() => setOpenRepairCannotBeFinishedPopup(false)}
                isOpen={openRepairCannotBeFinishedPopup}
                {...{
                    comment,
                    isLoading,
                    setComment,
                    setCommentError,
                    commentError,
                    onRepairCannotBeFinishedSaveCommentClick,
                }}
            />

            <DoaPopup
                closeHandler={() => setOpenDoaPopup(false)}
                isLoadingSubmit={isLoading}
                {...{
                    isOpen: openDoaPopup,
                    partCodes: doaPartCodes,
                    serial,
                    selectedCauses,
                    setSelectedCauses,
                    onRepairCannotBeFinishedSaveCommentClick,
                }}
            />
        </>
    )
}

RemovedPartTable.propTypes = {
    issueId: PropTypes.string,
    setOpenRemovePart: PropTypes.func,
    techComment: PropTypes.string,
}

RemovedPartTable.defaultProps = {
    issueId: '',
    setOpenRemovePart: () => {},
    techComment: '',
}

export default RemovedPartTable
