import { combineReducers, configureStore } from '@reduxjs/toolkit'
import accordionSlice from './accrodion/slice'
import authSlice from './auth/slice'
import causeSlice from './cause/slice'
import deviceSlice from './device/slice'
import issueDiagnoseSlice from './diagnose/slice'
import dispatchSlice from './dispatch/slice'
import menuSlice from './menu/slice'
import partcodeSlice from './partcode/slice'
import procurementStateSlice from './procurement/slice'
import purchaseOrderSlice from './purchaseOrder/slice'
import rackTicketStateSlice from './rackTicket/slice'
import scheduleSlice from './scheduler/slice'
import snackbarSlice from './snackbar/slice'
import teamSlice from './team/slice'
import userStateSlice from './user/slice'
import filterSlice from './filter/slice'
import formatDateStateSlice from './formatdate/slice'

const combinedReducer = combineReducers({
    auth: authSlice.reducer,
    menu: menuSlice.reducer,
    device: deviceSlice.reducer,
    dispatch: dispatchSlice.reducer,
    accordion: accordionSlice.reducer,
    userState: userStateSlice.reducer,
    messageDelUser: userStateSlice.reducer,
    fleetState: deviceSlice.reducer,
    vendorState: deviceSlice.reducer,
    serialState: deviceSlice.reducer,
    searchSerial: deviceSlice.reducer,
    dispatchState: dispatchSlice.reducer,
    filterState: filterSlice.reducer,
    partcodeState: partcodeSlice.reducer,
    snackbar: snackbarSlice.reducer,
    causeState: causeSlice.reducer,
    orderState: procurementStateSlice.reducer,
    invoiceState: procurementStateSlice.reducer,
    deliveryState: procurementStateSlice.reducer,
    deliveryDetailState: procurementStateSlice.reducer,
    purchaseOrderLine: purchaseOrderSlice.reducer,
    deviceErrorMessage: deviceSlice.reducer,
    team: teamSlice.reducer,
    rackTicketState: rackTicketStateSlice.reducer,
    scheduler: scheduleSlice.reducer,
    diagnostic: issueDiagnoseSlice.reducer,
    formatDateState: formatDateStateSlice.reducer,
})

const reducers = (state, action) => {
    // let tempState = state;
    // if (action.type === [logout.fulfilled].toString()) {s
    //     const { navigation } = tempState;
    //     tempState = undefined;
    //     tempState = {
    //         ...tempState,
    //         navigation,
    //     };
    // }
    return combinedReducer(state, action)
}

export default configureStore({
    reducer: reducers,
})
