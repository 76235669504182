import PropTypes from 'prop-types'
import TableFilters from '~/components/TableFilters'
import { useMemo } from 'react'
import { useBomStateQuery } from '~/pages/BOM/query'
import { titleize } from '~/utils/helpers'

const BomTableFilters = ({
    search,
    searchHandler,
    state,
    setState,
    onEnterSearch,
}) => {
    const { data: stateData, isSuccess: isSuccessState } = useBomStateQuery()

    const states = useMemo(() => {
        if (isSuccessState && stateData?.states.length > 0) {
            const result = stateData.states.map((state) => ({
                id: state,
                name: titleize(state.toLowerCase().replace('_', ' ')),
            }))
            result.unshift({ id: '', name: 'ALL' })
            return result
        }
        return [{ id: '', name: 'ALL' }]
    }, [isSuccessState, stateData])

    const selectGroup = useMemo(() => {
        return [
            {
                label: 'State',
                handleFunc: setState,
                dataSet: states,
                value: state,
            },
        ]
    }, [setState, state, states])
    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='boms'
            page='boms'
        />
    )
}

BomTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    state: PropTypes.string,
    setState: PropTypes.func,
    onEnterSearch: PropTypes.func,
}

export default BomTableFilters
