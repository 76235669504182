import DownloadIcon from '@mui/icons-material/Download'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import { MenuItem } from '@mui/material'
import ActionMenu from '~/components/ActionMenu'
import { LoadingButton } from '~/components/Button'
import ListToolbarWrapper from '~/components/ListToolbarWrapper'
import { ISSUE_DISPATCH_ADD } from '~/constants/Routes'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LOCALIZATION } from './constants'
import { useExportDispatchMutation } from './mutate'
import PropTypes from 'prop-types'
import usePermissions from '~/hooks/usePermission'

const DispatchListToolbar = ({ payload }) => {
    const { mutate: exportDispatch, isLoading } = useExportDispatchMutation()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { permissions } = usePermissions()
    const hasPermission = permissions.issue.dispatch.canCreateDispatch

    const handleAddDispatch = () => {
        navigate(ISSUE_DISPATCH_ADD)
    }

    return (
        <ListToolbarWrapper>
            <ActionMenu>
                <MenuItem
                    onClick={() => exportDispatch(payload)}
                    disabled={isLoading}
                >
                    {isLoading ? <HourglassTopIcon /> : <DownloadIcon />}
                    {t('button.export')}
                </MenuItem>
            </ActionMenu>
            {hasPermission && (
                <LoadingButton
                    label={t(`${LOCALIZATION}add_dispatch`)}
                    onClick={handleAddDispatch}
                />
            )}
        </ListToolbarWrapper>
    )
}

DispatchListToolbar.propTypes = { payload: PropTypes.object }

export default DispatchListToolbar
