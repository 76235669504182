import CustomTable from '~/components/Table'
import { useSelector } from 'react-redux'
import { selectDevice } from '~/store/device/selector'
import { useDeviceIntuneQuery } from './query'
const localization = `pages.device.intune.`

const intuneTableHeader = [
    {
        id: 'created_at',
        numeric: true,
        disablePadding: false,
        label: 'created_at',
        orderable: true,
        dataType: 'datetime',
    },
    {
        id: 'tenant_id',
        numeric: false,
        disablePadding: false,
        label: 'tenant_id',
        orderable: true,
    },
    {
        id: 'tenant_domain',
        numeric: false,
        disablePadding: false,
        label: 'tenant_domain',
        orderable: true,
    },
    {
        id: 'bios',
        numeric: false,
        disablePadding: false,
        label: 'bios',
        orderable: true,
    },
    {
        id: 'profile',
        numeric: false,
        disablePadding: false,
        label: 'profile',
        orderable: true,
    },
    {
        id: 'ip_source',
        numeric: false,
        disablePadding: false,
        label: 'ip_source',
        orderable: true,
    },
]
const DeviceIntuneList = () => {
    const device = useSelector(selectDevice)

    return (
        <CustomTable
            id={device.id.toString()}
            customQuery={useDeviceIntuneQuery}
            headCells={intuneTableHeader}
            LOCALIZATION={localization}
        />
    )
}
export default DeviceIntuneList
