import axiosClient, { clientDownload } from '~/api/base'

class DispatchApi {
    getDispatches = (params) => {
        return axiosClient.get('api/dispatches', {
            params,
        })
    }

    getDispatchStatus = () => {
        return axiosClient.get('api/dispatches/status')
    }

    getDispatchUser = () => {
        return axiosClient.get('api/dispatches/users')
    }

    getDispatchState = () => {
        return axiosClient.get('api/dispatches/state')
    }

    getDispatchDetail = (id) => axiosClient.get(`api/dispatches/${id}`)

    getDispatchHistory = (params) =>
        axiosClient.get(`api/dispatches/${params.id}/history`, {
            params,
        })

    getDispatchExport = (payload) =>
        clientDownload.post('api/dispatches/export-dispatch', payload)

    createDispatch = (payload) => axiosClient.post('api/dispatches', payload)

    resubmitDispatch = (payload) =>
        axiosClient.post('api/dispatches/resubmit', payload)

    getDispartPart = (id, params = {}) =>
        axiosClient.get(`api/dispatches/${id}/parts`, { params })

    mappingPartCode = (dispatchId, payload) =>
        axiosClient.put(
            `api/dispatches/${dispatchId}/mapping-part-code`,
            payload,
        )

    scanSerial = (serial) =>
        axiosClient.post('api/dispatches/scan-serial', { serial })

    uploadDispatchPicture = (payload, dispatchId) =>
        axiosClient.post(`api/dispatches/${dispatchId}/pictures`, payload)

    close = (id) => axiosClient.post(`api/dispatches/close/${id}`)
}

export default new DispatchApi()
