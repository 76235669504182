import {
    Divider,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material'
import { LoadingButton } from '~/components/Button'
import DataNotFound from '~/components/DataNotFound'
import PaginationTable from '~/components/PaginationTable'
import StyledModal from '~/components/StyledModal'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { ISSUE_DISPATCH_DETAIL } from '~/constants/Routes'
import useTable from '~/hooks/useTable'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DispatchesTableFilters from './DispatchesTableFilters'
import MappingPartCode from './MappingPartCode'
import { DispatchShipmentReportHeadCells } from './constants'
import { useDispatchShipmentReportQuery } from './query'
import PropTypes from 'prop-types'

const localization = 'pages.dispatches.dispatch.overview.'

const DispatchesTableBody = ({ isLoading, dispatches }) => {
    const navigate = useNavigate()
    const [dispatchId, setDispatchId] = useState()

    const colSpan = useMemo(() => {
        return DispatchShipmentReportHeadCells.length
    }, [])

    if (isLoading) {
        return <TableLoading colSpan={colSpan} />
    }

    return (
        <>
            <TableBody>
                {dispatches.length > 0 ? (
                    dispatches.map((row) => {
                        return (
                            <TableRow hover tabIndex={-1} key={row.dispatch_id}>
                                <TableCell component='th' scope='row'>
                                    <Link
                                        component='button'
                                        variant='inherit'
                                        to='#'
                                        underline='hover'
                                        onClick={() =>
                                            navigate(
                                                ISSUE_DISPATCH_DETAIL.replace(
                                                    ':id',
                                                    row.dispatch_id,
                                                ),
                                            )
                                        }
                                    >
                                        <Typography
                                            variant='body2'
                                            align='center'
                                        >
                                            {row.dispatch_id}
                                        </Typography>
                                    </Link>
                                </TableCell>
                                <TableCell>{row.callnumber}</TableCell>
                                <TableCell>
                                    <LoadingButton
                                        label={
                                            row.empty_sku === true
                                                ? 'Set SKU'
                                                : 'Add Part code'
                                        }
                                        onClick={() =>
                                            setDispatchId(row.dispatch_id)
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        )
                    })
                ) : (
                    <DataNotFound colSpan={colSpan} />
                )}
            </TableBody>
            <StyledModal
                open={!!dispatchId}
                handleClose={() => {
                    setDispatchId(undefined)
                }}
                stackProps={{
                    alignItems: 'unset',
                    width: '750',
                    sx: {
                        minWidth: '750',
                        maxHeight: '95vh',
                        overflowX: 'hidden',
                        overflowY: 'scroll',
                    },
                }}
            >
                <Typography
                    variant='h4'
                    sx={{
                        mb: 2,
                    }}
                >
                    Mapping Part code
                </Typography>
                <MappingPartCode {...{ dispatchId, setDispatchId }} />
            </StyledModal>
        </>
    )
}

DispatchesTableBody.propTypes = {
    isLoading: PropTypes.bool,
    dispatches: PropTypes.array,
}

const DispatchComponentsTable = () => {
    const {
        results: dispatches,
        data,
        isLoading,
        page,
        search,
        searchHandler,
        sortHandler,
        order,
        orderBy,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useDispatchShipmentReportQuery,
        initialStates: {
            order: 'asc',
        },
    })

    return (
        <Paper sx={{ width: '100%', mb: 2 }}>
            <DispatchesTableFilters
                {...{
                    search,
                    searchHandler,
                    onEnterSearch,
                }}
            />
            <Divider />
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby='tableTitle'
                    size='medium'
                >
                    <TableHeader
                        headCells={DispatchShipmentReportHeadCells}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                        localization={localization}
                    />
                    <DispatchesTableBody
                        isLoading={isLoading}
                        dispatches={dispatches}
                    />
                </Table>
            </TableContainer>

            <PaginationTable
                data={data}
                page={page}
                size='medium'
                handleChangePage={changePageHandler}
            />
        </Paper>
    )
}
export default DispatchComponentsTable
