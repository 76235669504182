import {
    Divider,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DataNotFound from '~/components/DataNotFound'
import { DispatchHeadCells } from '~/components/HeaderTable/DispatchHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { ISSUE_DISPATCH_DETAIL } from '~/constants/Routes'
import useTable from '~/hooks/useTable'
import DispatchListToolbar from './DispatchListToolbar'
import DispatchesTableFilters from './DispatchesTableFilters'
import StateDisplayer from './component/StateDisplayer'
import { useDispatchesQuery } from './query'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const localization = 'pages.dispatches.dispatch.overview.'

export const DispatchesTableBody = ({ isLoading, dispatches }) => {
    const navigate = useNavigate()
    const colSpan = useMemo(() => {
        return DispatchHeadCells.length
    }, [])

    if (isLoading) {
        return <TableLoading colSpan={colSpan} />
    }

    return (
        <TableBody>
            {dispatches.length > 0 ? (
                dispatches.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell component='th' scope='row'>
                                <Link
                                    component='button'
                                    variant='inherit'
                                    to='#'
                                    underline='hover'
                                    onClick={() =>
                                        navigate(
                                            ISSUE_DISPATCH_DETAIL.replace(
                                                ':id',
                                                row.id,
                                            ),
                                        )
                                    }
                                >
                                    <Typography variant='body2' align='center'>
                                        {row.id}
                                    </Typography>
                                </Link>
                            </TableCell>
                            <TableCell align='center'>{row.serial}</TableCell>
                            <TableCell align='center'>
                                {row.ticket_id}
                            </TableCell>
                            <TableCell align='center'>
                                {row.esd_location}
                            </TableCell>
                            <TableCell align='center'>
                                {row.dispatch_code}
                            </TableCell>
                            <TableCell align='center'>
                                <StateDisplayer
                                    state={row.state}
                                    errorMessage={row.notes}
                                />
                            </TableCell>
                            <TableCell align='center'>{row.status}</TableCell>
                            <TableCell align='center'>
                                {row.service_center?.name}
                            </TableCell>
                            <TableCell align='center'>
                                {row.branch?.name}
                            </TableCell>
                            <TableCell align='center'>
                                {row.created_by?.name}
                            </TableCell>
                            <TableCell align='center'>
                                {row.created_manually ? 'Yes' : 'No'}
                            </TableCell>
                            <TableCell align='center'>
                                <DateTimeLocale datetime={row.created_at} />
                            </TableCell>
                            <TableCell align='center'>
                                <DateTimeLocale datetime={row.updated_at} />
                            </TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={colSpan} />
            )}
        </TableBody>
    )
}

const DispatchesTable = () => {
    const [branch, setBranch] = useState('')
    const [status, setStatus] = useState('')
    const [user, setUser] = useState('')
    const [state, setState] = useState('')
    const [serviceCenter, setServiceCenter] = useState('')
    const [createdManually, setCreatedManually] = useState('')

    const {
        results: dispatches,
        data,
        isLoading,
        page,
        setPage,
        order,
        orderBy,
        search,
        searchHandler,
        sortHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useDispatchesQuery,
        initialStates: {
            orderBy: '',
        },
        queryAttributes: {
            branch,
            status,
            user,
            serviceCenter,
            state,
            createdManually,
        },
    })

    const refValue = useRef({
        branch: '',
        status: '',
        user: '',
        serviceCenter: '',
        state: '',
        createdManually: '',
    })

    useEffect(() => {
        if (
            branch !== refValue.current.branch ||
            status !== refValue.current.status ||
            user !== refValue.current.user ||
            serviceCenter !== refValue.current.serviceCenter ||
            state !== refValue.current.state ||
            createdManually !== refValue.current.createdManually
        ) {
            setPage(1)
            refValue.current.branch = branch
            refValue.current.status = status
            refValue.current.user = user
            refValue.current.serviceCenter = serviceCenter
            refValue.current.state = state
            refValue.current.createdManually = createdManually
        }
    }, [branch, status, user, setPage, serviceCenter, state, createdManually])

    return (
        <>
            <DispatchListToolbar
                payload={{
                    status,
                    branch,
                    search,
                    order,
                    orderBy,
                    serviceCenter,
                    createdManually,
                }}
            />

            <Paper sx={{ width: '100%', mb: 2 }}>
                <DispatchesTableFilters
                    {...{
                        search,
                        searchHandler,
                        branch,
                        setBranch,
                        status,
                        setStatus,
                        user,
                        setUser,
                        state,
                        setState,
                        serviceCenter,
                        setServiceCenter,
                        onEnterSearch,
                        createdManually,
                        setCreatedManually,
                    }}
                />
                <Divider />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby='tableTitle'
                        size='medium'
                    >
                        <TableHeader
                            headCells={DispatchHeadCells}
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                            localization={localization}
                        />
                        <DispatchesTableBody
                            isLoading={isLoading}
                            dispatches={dispatches}
                        />
                    </Table>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    size='medium'
                    handleChangePage={changePageHandler}
                />
            </Paper>
        </>
    )
}

DispatchesTableBody.propTypes = {
    isLoading: PropTypes.bool,
    dispatches: PropTypes.array,
}

export default DispatchesTable
