import { Divider, Paper, Table, TableContainer } from '@mui/material'
import propTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import PaginationTable from '~/components/PaginationTable'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import IssueTableBody from './IssueTableBody'
import IssueTableFilter from './IssueTableFilter'
import { headCells } from './constants'

const IssueTable = ({ useIssuesQuery }) => {
    const localization = `pages.issues.overview.`
    const [state, setState] = useState('')
    const [status, setStatus] = useState('')
    const [serviceCenter, setServiceCenter] = useState('')
    const [rangeDate, setRangeDate] = useState([null, null])
    const {
        data,
        results: issues,
        isLoading,
        page,
        setPage,
        order,
        orderBy,
        search,
        searchHandler,
        sortHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useIssuesQuery,
        initialStates: {
            orderBy: '',
            page: 1,
        },
        queryAttributes: {
            status,
            state,
            serviceCenter,
            dateFrom: rangeDate[0],
            dateTo: rangeDate[1],
        },
    })

    const refValue = useRef({
        search: '',
        status: '',
        state: '',
        serviceCenter: '',
        rangeDate: [null, null],
    })

    useEffect(() => {
        if (
            status !== refValue.current.status ||
            state !== refValue.current.state ||
            serviceCenter !== refValue.current.serviceCenter ||
            (rangeDate[0] !== refValue.current.rangeDate[0] &&
                rangeDate[1] !== refValue.current.rangeDate[1])
        ) {
            setPage(1)
            refValue.current.status = status
            refValue.current.state = state
            refValue.current.serviceCenter = serviceCenter
            refValue.current.rangeDate[0] = rangeDate[0]
            refValue.current.rangeDate[1] = rangeDate[1]
        }
    }, [status, state, setPage, serviceCenter, rangeDate])

    return (
        <Paper>
            <IssueTableFilter
                search={search}
                searchHandler={searchHandler}
                onEnterSearch={onEnterSearch}
                status={status}
                setStatus={setStatus}
                state={state}
                setState={setState}
                serviceCenter={serviceCenter}
                setServiceCenter={setServiceCenter}
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
            />
            <Divider />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHeader
                        {...{
                            order,
                            orderBy,
                            localization,
                            headCells,
                            onRequestSort: sortHandler,
                        }}
                    />
                    <IssueTableBody {...{ isLoading, issues }} />
                </Table>
            </TableContainer>
            <PaginationTable
                {...{
                    data,
                    page,
                    handleChangePage: changePageHandler,
                    size: 'small',
                }}
            />
        </Paper>
    )
}

IssueTable.propTypes = {
    useIssuesQuery: propTypes.func,
}

export default IssueTable
