import rackTicketApi from '~/api/rackTicketApi'
import { useQuery } from 'react-query'
import { removeEmptyValue } from '~/utils/helpers'

export const useRackTicketListQuery = (params) => {
    return useQuery(
        ['rack_tickets', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await rackTicketApi.getRackTickets(p)
            return { data: data.data, total: data.meta?.total }
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useLastScanQuery = (params) => {
    return useQuery(
        ['rack_ticket_last_scans', params],
        async () => {
            const ticket_id = params.hoverTicket
            const { data } = await rackTicketApi.getScans(ticket_id)
            return data.data
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
            enabled: params.showTooltip === true,
        },
    )
}
