import { axiosFormClient } from '~/api/base'
import axiosClient from './base'

const diagnosticApi = {
    uploadPictures: (id, payload) =>
        axiosFormClient.post(`api/diagnostic/${id}/pictures`, payload),
    getPictures: (id) => axiosClient.get(`api/diagnostic/${id}/pictures`),
    scanIssue: (serial) =>
        axiosClient.get(`api/diagnostic/serial-scan/${serial}`),
    removePictures: (pictureId) =>
        axiosClient.delete(`api/diagnostic/pictures/${pictureId}`),

    getRootCauses: (diagnosticsId, params) =>
        axiosClient.get(`api/diagnostic/${diagnosticsId}/root-causes`, {
            params,
        }),

    getSavedCauses: (diagnosticsId, params) =>
        axiosClient.get(`api/diagnostic/${diagnosticsId}/saved-root-causes`, {
            params,
        }),

    updateIssueDiagnose: (issueDiagnostic, payload) => {
        return axiosClient.put(`api/diagnostic/${issueDiagnostic}`, payload)
    },

    saveIssueDiagnose: (id, esdLocation, hasPart) => {
        return axiosClient.post(`api/diagnostic/${id}/save`, {
            esd_location: esdLocation,
            has_part: hasPart,
        })
    },

    getIssueDiagnostic: (id) => axiosClient.get(`api/diagnostic/${id}`),

    getTicketDetails: (issueId) =>
        axiosClient.get(`api/diagnostic/${issueId}/ticket-details`),

    getCausePartIssueDiagnostic: (issueId) =>
        axiosClient.get(`api/issues/${issueId}/causes-part-codes`),

    getPartIssueDiagnostic: (issueId) =>
        axiosClient.get(`api/issues/${issueId}/part-codes`),

    getTotalLossCheck: (id) =>
        axiosClient.get(`api/diagnostic/${id}/total-loss-check`),

    getDeviceHistories: (issueId, params) =>
        axiosClient.get(`api/diagnostic/${issueId}/device-histories`, {
            params,
        }),

    getDeviceHistoryForIssue: (issueId, params) =>
        axiosClient.get(`api/issue/${issueId}/device-history`, {
            params,
        }),

    getIssueLogs: (issueId, params) =>
        axiosClient.get(`api/diagnostic/${issueId}/issue-logs`, {
            params,
        }),

    updateDispatchPictures: (issueId, params) =>
        axiosClient.post(`api/diagnostic/${issueId}/dispatch-pictures`, {
            requests: params,
        }),

    getRootCausesBySerialAndPartCodes: ({
        serial,
        partCodes,
        search,
        locale,
    }) =>
        axiosClient.post(
            `api/diagnostic/get-root-causes-by-model-part-codes?locale=${locale}`,
            {
                serial,
                part_codes: partCodes,
                search,
            },
        ),

    getDeviceImageForIssue: (issueId, params) =>
        axiosClient.get(`api/issue/${issueId}/device-images`, {
            params,
        }),
}
export default diagnosticApi
