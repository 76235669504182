import {
    Divider,
    Link,
    Table as MuiTable,
    TableBody as MuiTableBody,
    Paper,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { ProductionAddonHeadCells } from '~/components/HeaderTable/ProductionAddonHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { PRODUCTION_ADD_ONS_DETAIL } from '~/constants/Routes'
import useTable from '~/hooks/useTable'
import { useAddonsListQuery, useUserQuery } from '../query'
import { useEffect, useRef, useState } from 'react'
import AddonTableSearchAndFilter from './AddonTableSearchAndFilter'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const TableBody = ({ isLoading, results }) => {
    const navigate = useNavigate()
    if (isLoading) {
        return <TableLoading colSpan={ProductionAddonHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {results.length > 0 &&
                results.map((row, index) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row?.id}>
                            <TableCell>
                                <Link
                                    component='button'
                                    variant='inherit'
                                    to='#'
                                    underline='hover'
                                    onClick={() =>
                                        navigate(
                                            PRODUCTION_ADD_ONS_DETAIL.replace(
                                                ':id',
                                                row?.id,
                                            ),
                                        )
                                    }
                                >
                                    {row?.id}
                                </Link>
                            </TableCell>
                            <TableCell>{row?.name}</TableCell>
                            <TableCell>{row?.version}</TableCell>
                            <TableCell>{row?.filename}</TableCell>
                            <TableCell>
                                {row?.project_addons?.length ?? 0}
                            </TableCell>
                            <TableCell>{row?.created_by?.name}</TableCell>
                            <TableCell>{row?.updated_by?.name}</TableCell>
                            <TableCell>
                                <DateTimeLocale datetime={row.updated_at} />
                            </TableCell>
                        </TableRow>
                    )
                })}
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    isLoading: PropTypes.bool,
    results: PropTypes.arrayOf(PropTypes.object),
}

const AddonsTable = () => {
    const [user, setUser] = useState('')
    const {
        data,
        results,
        isLoading,
        page,
        setPage,
        order,
        orderBy,
        changePageHandler,
        sortHandler,
        search,
        searchHandler,
        onEnterSearch,
    } = useTable({
        query: useAddonsListQuery,
        initialStates: {
            orderBy: 'desc',
        },
        queryAttributes: {
            user,
        },
    })

    // HANDLE FILTER BY USER
    const { data: userQuery, isSuccess: isUserQuerySuccess } = useUserQuery()

    const refValue = useRef({
        search: '',
        user: '',
    })

    useEffect(() => {
        if (user !== refValue.current.serviceCenter) {
            setPage(1)
            refValue.current.user = user
        }
    }, [user, setPage])

    return (
        <Paper>
            {isUserQuerySuccess && (
                <AddonTableSearchAndFilter
                    search={search}
                    searchHandler={searchHandler}
                    onEnterSearch={onEnterSearch}
                    setUser={setUser}
                    user={user}
                    userQuery={userQuery}
                    isUserQuerySuccess={isUserQuerySuccess}
                />
            )}
            <Divider />
            <TableContainer
                sx={{ width: '100%' }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHeader
                        headCells={ProductionAddonHeadCells}
                        localization={'pages.production.addons.'}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <TableBody isLoading={isLoading} results={results} />
                </MuiTable>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                size='medium'
                handleChangePage={changePageHandler}
            />
        </Paper>
    )
}

export default AddonsTable
