import { Divider, Paper, Table, TableContainer } from '@mui/material'
import PaginationTable from '~/components/PaginationTable'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import BomTableBody from '~/pages/BOM/Overview/BomTableBody'
import BomTableFilters from '~/pages/BOM/Overview/BomTableFilters'
import { useBomsQuery } from '~/pages/BOM/query'
import { headCells } from './constants'
import { useEffect, useRef, useState } from 'react'

const BomTable = () => {
    const [state, setState] = useState('')
    const localization = `pages.boms.overview.`
    const {
        data,
        results: boms,
        isLoading,
        page,
        setPage,
        order,
        orderBy,
        search,
        searchHandler,
        sortHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useBomsQuery,
        initialStates: {
            orderBy: '',
        },
        queryAttributes: {
            state,
        },
    })

    const refValue = useRef({
        state: '',
    })

    useEffect(() => {
        if (state !== refValue.current.state) {
            setPage(1)
            refValue.current.state = state
        }
    }, [state, setPage])

    return (
        <>
            <Paper>
                <BomTableFilters
                    {...{
                        search,
                        searchHandler,
                        state,
                        setState,
                        onEnterSearch,
                    }}
                />
                <Divider />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHeader
                            {...{
                                order,
                                orderBy,
                                localization,
                                headCells,
                                onRequestSort: sortHandler,
                            }}
                        />
                        <BomTableBody {...{ isLoading, boms }} />
                    </Table>
                </TableContainer>
                <PaginationTable
                    {...{
                        data,
                        page,
                        handleChangePage: changePageHandler,
                        size: 'small',
                    }}
                />
            </Paper>
        </>
    )
}

export default BomTable
