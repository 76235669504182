import styled from '@emotion/styled'
import { Grid, Paper } from '@mui/material'
import PageName from '~/components/PageName'
import { useParams } from 'react-router-dom'
import DeviceHistory from './DeviceHistory'
import IssueLog from './IssueLog'
import PicturesSection from './PicturesSection'
import TicketDetails from './TicketDetails'
import { useTicketDetailsQuery } from './query'

const StyledPaper = styled(Paper)(({ theme }) => {
    return {
        borderRadius: 5,
        padding: theme.spacing(2),
        height: '100%',
    }
})

const Section = styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'show',
})(({ show }) => {
    return {
        ...(!show && {
            display: 'none',
        }),
    }
})

const Diagnostic = () => {
    const { issueId } = useParams()
    const { data } = useTicketDetailsQuery(issueId)
    return (
        <>
            <PageName name={`Diagnostic for issue ${issueId}`} />
            <Grid container spacing={3}>
                <Section item xs={12} md={4} show={true}>
                    <StyledPaper>
                        <TicketDetails data={data} />
                    </StyledPaper>
                </Section>

                <Grid item xs={12} md={4}>
                    <Grid container spacing={3}>
                        <Section item xs={12} show={true}>
                            <StyledPaper>
                                <PicturesSection />
                            </StyledPaper>
                        </Section>
                    </Grid>
                </Grid>
                <Section item xs={12} show={true}>
                    <StyledPaper>
                        <IssueLog />
                    </StyledPaper>
                </Section>
                <Section item xs={12} show={true}>
                    <StyledPaper>
                        <DeviceHistory />
                    </StyledPaper>
                </Section>
            </Grid>
        </>
    )
}

export default Diagnostic
