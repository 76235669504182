import axiosClient, { clientDownload } from '~/api/base'

class RackTicketApi {
    getRackTickets = (params) => {
        return axiosClient.get('api/issue/rack-scans/tickets', { params })
    }

    updateStateTicket = (id, payload) =>
        axiosClient.put(
            `api/issue/rack-scans/tickets/resolve-state/${id}`,
            payload,
        )

    getRackTicketsExport = (payload) =>
        clientDownload.post(
            'api/issue/rack-scans/tickets/export-ticket',
            payload,
        )

    getScans = (ticket_id) => {
        return axiosClient.get(
            `api/issue/rack-scans/tickets/${ticket_id}/scan-lines`,
        )
    }
}
export default new RackTicketApi()
