import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Link } from '@mui/material'

const ProjectLink = ({ navigateLink, value }) => {
    const navigate = useNavigate()
    return (
        <Link
            component='button'
            variant='inherit'
            to='#'
            underline='hover'
            onClick={() => navigate(navigateLink)}
        >
            {value}
        </Link>
    )
}

ProjectLink.propTypes = {
    navigateLink: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default ProjectLink
