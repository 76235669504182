import StyledModal from '~/components/StyledModal'
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import useTable from '~/hooks/useTable'
import { useState } from 'react'
import Search from '~/components/Search/Search'
import TableLoading from '~/components/Table/TableLoading'
import { LoadingButton } from '~/components/Button'
import PaginationTable from '~/components/PaginationTable'
import { useProductsListQuery } from '~/pages/Product/query'
import ConfirmPopup from '~/pages/BOM/components/ConfirmPopup'

const SkuList = ({ bomId, onClose, sku }) => {
    const { t } = useTranslation()

    const {
        results: products,
        data,
        isLoading,
        page,
        searchHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({ query: useProductsListQuery })
    const [selectSku, setSelectSku] = useState(null)

    return (
        <>
            <Search
                label={t(`pages.dispatches.dispatch.add.filter_sku`)}
                onChange={searchHandler}
                onKeyDown={onEnterSearch}
                sx={{ mb: 2 }}
            />
            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('table.sku')}</TableCell>
                            <TableCell align='center'>
                                {t('table.action')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {isLoading ? (
                        <TableLoading colSpan={3} height='550px' />
                    ) : (
                        <TableBody>
                            {products.map((product) => (
                                <TableRow key={product.id}>
                                    <TableCell>{product.sku}</TableCell>
                                    <TableCell align='center'>
                                        <LoadingButton
                                            onClick={() =>
                                                setSelectSku(product.sku)
                                            }
                                            label='Select'
                                            disabled={sku === product.sku}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                size='small'
                handleChangePage={changePageHandler}
            />
            <ConfirmPopup
                onClose={() => setSelectSku(null)}
                closeAll={onClose}
                bomId={bomId}
                sku={selectSku}
                message={`Do you want to set ${selectSku} SKU to this BOM?`}
            />
        </>
    )
}

SkuList.propTypes = {
    bomId: PropTypes.number,
    onClose: PropTypes.func,
    sku: PropTypes.string,
}

const SkuSelectionPopup = ({ bomId, open, onClose, sku }) => {
    return (
        <StyledModal
            open={open}
            handleClose={onClose}
            stackProps={{
                gap: 2,
                sx: {
                    minWidth: 800,
                    maxHeight: '95vh',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar-thumb': {
                        borderRadius: '10px',
                        backgroundColor: 'rgba(0, 0, 0, .5)',
                    },
                    '&::-webkit-scrollbar': {
                        width: '10px',
                    },
                },
            }}
        >
            <Box sx={{ width: '100%' }}>
                <SkuList onClose={onClose} bomId={bomId} sku={sku} />
            </Box>
        </StyledModal>
    )
}

SkuSelectionPopup.propTypes = {
    bomId: PropTypes.number,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    sku: PropTypes.string,
}

export default SkuSelectionPopup
