import { useMutation } from 'react-query'
import issueApi from '~/api/issueApi'
import { ISSUE_ISSUE_DETAIL, ISSUE_OVERVIEW } from '~/constants/Routes'
import { useNavigate } from 'react-router-dom'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import issueTriageApi from '~/api/issueTriageApi'

export const useIssueCreateMutation = () => {
    return useMutation(
        async (values) => await issueApi.createIssue(values),
        useBuildMutationOptions(),
    )
}
export const useIssueUpdateMutation = (id) => {
    return useMutation(
        async (values) => await issueApi.updateIssue(id, values),
        useBuildMutationOptions(),
    )
}

const useBuildMutationOptions = () => {
    const navigate = useNavigate()
    const { openSnackbar } = useDialogContext()
    return {
        onSuccess: (data) => {
            openSnackbar({
                message: data.message ? data.message : 'Create successful!',
                type: 'success',
            })

            navigate(ISSUE_OVERVIEW)
            return data
        },
        onError: (error) => {
            let message = 'Create failed!'
            if (error.response.data.message) {
                message = error.response.data.message
            } else if (error.message) {
                message = error.message
            }
            openSnackbar({
                message,
                type: 'error',
                duration: 10000,
            })
        },
        retry: false,
    }
}

export const useIssuePictureMutation = () => {
    return useMutation(async (pictures) => {})
}

export const useStartTriageIssueMutation = ({ setError }) => {
    const navigate = useNavigate()

    return useMutation(
        async (serial) => {
            const { data } = await issueTriageApi.startTriage(serial)

            return data.data
        },
        {
            onSuccess: (data) => {
                navigate(
                    `${ISSUE_ISSUE_DETAIL.replace(':issueId', data.id)}?tag=1`,
                )
            },
            onError: (error) => {
                const errorData = error?.response?.data
                if (errorData?.issueId && errorData?.diagnosticId) {
                    navigate(
                        `${ISSUE_ISSUE_DETAIL.replace(
                            ':issueId',
                            errorData.issueId,
                        )}?tag=1`,
                    )
                } else {
                    // TODO update message
                    const errorMessage =
                        errorData?.message ??
                        'There is no accepted ticket for this Serial'
                    setError(errorMessage)
                }
            },
        },
    )
}
