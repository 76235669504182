import {
    Divider,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import ExternalLink from '~/components/ExternalLink'
import { OrderConfirmationHeadCells } from '~/components/HeaderTable/OrderConfirmationHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { PROCUREMENT_PURCHASEORDER_DETAIL } from '~/constants/Routes'
import { COPACO_ORDER_NUMBER } from '~/constants/externalLink'
import useTable from '~/hooks/useTable'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { capitalize } from '~/utils/helpers'
import { useOrderConfirmationsQuery } from '../query'
import OrderConfirmationListToolbar from './OrderConfirmationListToolbar'
import OrderTableFilters from './OrderTableFilters'
import PropTypes from 'prop-types'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const localization = 'pages.procurement.orderConfirmation.overview.'

const copacoStatuses = ['processed', 'delayed', 'rejected']
const notShowStatuses = ['processing', 'not received']

const OrderConfirmationBodyTable = ({ isLoading, orderConfirmations }) => {
    const navigate = useNavigate()

    const endUserInformation = useCallback((endUser) => {
        return (
            <>
                {endUser?.name && <Typography>{endUser?.name}</Typography>}
                {endUser?.country && (
                    <Typography>{`Country: ${endUser?.country}`}</Typography>
                )}
            </>
        )
    }, [])

    const getOrderNumber = useCallback((status, orderNumber) => {
        if (copacoStatuses.includes(status)) {
            return (
                <ExternalLink
                    extLink={COPACO_ORDER_NUMBER}
                    param={orderNumber}
                />
            )
        }

        if (notShowStatuses.includes(status)) {
            return null
        }

        return orderNumber
    }, [])

    if (isLoading) {
        return <TableLoading colSpan={OrderConfirmationHeadCells.length} />
    }

    return (
        <TableBody>
            {orderConfirmations.length > 0 ? (
                orderConfirmations.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell align='left'>
                                {row.supplier_name}
                            </TableCell>
                            <TableCell align='left'>
                                <Link
                                    component='button'
                                    variant='inherit'
                                    to='#'
                                    underline='hover'
                                    onClick={() =>
                                        navigate(
                                            PROCUREMENT_PURCHASEORDER_DETAIL.replace(
                                                ':id',
                                                row.customer_order_number,
                                            ),
                                        )
                                    }
                                >
                                    <Typography variant='body2' align='right'>
                                        {row.customer_order_number}
                                    </Typography>
                                </Link>
                            </TableCell>

                            <TableCell align='left'>
                                {getOrderNumber(row.status, row.order_number)}
                            </TableCell>
                            <TableCell align='left'>
                                <DateTimeLocale
                                    datetime={row.order_date}
                                    formatHour={true}
                                />
                            </TableCell>
                            <TableCell align='left'>
                                {capitalize(row.status)}
                            </TableCell>
                            <TableCell align='left'>
                                {row.complete_delivery}
                            </TableCell>
                            <TableCell align='left'>
                                <Tooltip
                                    title={row.description_response_code}
                                    placement='top'
                                >
                                    <Typography>{row.response_code}</Typography>
                                </Tooltip>
                            </TableCell>
                            <TableCell align='left'>
                                {endUserInformation(row.end_user_information)}
                            </TableCell>
                            <TableCell align='left'>{row.to_city}</TableCell>
                            <TableCell align='left'>
                                {row.amount_excl_vat}
                            </TableCell>
                            <TableCell align='left'>
                                <DateTimeLocale datetime={row.created_at} />
                            </TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={OrderConfirmationHeadCells.length} />
            )}
        </TableBody>
    )
}

OrderConfirmationBodyTable.propTypes = {
    isLoading: PropTypes.bool,
    orderConfirmations: PropTypes.arrayOf(PropTypes.object),
}

const OrderConfirmationTable = () => {
    const [supplier, setSupplier] = useState('')
    const [status, setStatus] = useState('')

    const {
        results: orderConfirmations,
        data,
        isLoading,
        page,
        setPage,
        order,
        orderBy,
        search,
        searchHandler,
        sortHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useOrderConfirmationsQuery,
        queryAttributes: {
            supplier,
            status,
        },
    })

    const refValue = useRef({
        supplier: '',
        status: '',
    })

    useEffect(() => {
        if (
            supplier !== refValue.current.supplier ||
            status !== refValue.current.status
        ) {
            setPage(1)
            refValue.current.supplier = supplier
            refValue.current.status = status
        }
    }, [setPage, status, supplier])

    return (
        <>
            <OrderConfirmationListToolbar
                payload={{ supplier, status, search, order, orderBy }}
            />
            <Paper sx={{ width: '100%', mb: 2 }}>
                <OrderTableFilters
                    {...{
                        search,
                        searchHandler,
                        supplier,
                        setSupplier,
                        status,
                        setStatus,
                        onEnterSearch,
                    }}
                />
                <Divider />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby='tableTitle'
                        size='medium'
                    >
                        <TableHeader
                            headCells={OrderConfirmationHeadCells}
                            localization={localization}
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                        />
                        <OrderConfirmationBodyTable
                            isLoading={isLoading}
                            orderConfirmations={orderConfirmations}
                        />
                    </Table>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    handleChangePage={changePageHandler}
                    size='medium'
                />
            </Paper>
        </>
    )
}
export default OrderConfirmationTable
