import styled from '@emotion/styled'
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material'
import { ReactComponent as LaptopLarge } from '~/assets/images/devices/laptop_chromebook.svg'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectUser } from '~/store/auth/selector'
import StateButton from './Component/StateButton'
import DeviceAttachments from './DeviceAttachment'
import DeviceComponents from './DeviceComponents'
import DeviceEntitlements from './DeviceEntitlements'
import DeviceInformation from './DeviceInformation'
import DeviceIssueList from './DeviceIssueList'
import DeviceIntuneList from './DeviceIntuneList'
import DeviceReplaceablePart from './DeviceReplaceablePart'
import ProcurementSerial from './ProcurementSerial'
import AccordionDevice from '~/components/AccordionDevice'
import DeviceDispatchList from './DeviceDispatchList'
import PropTypes from 'prop-types'
import { selectFormatDateState } from '~/store/formatdate/selector'
import { formatDateTime } from '~/utils/helpers'
import usePermissions from '~/hooks/usePermission'

const localization = 'pages.device.serial.detail'

const CardHeaderSerial = styled(CardHeader)(({ theme }) => ({
    color: `${theme.palette.blue[100]}`,
    transition: '0.5s',
    '&:hover': {
        color: `${theme.palette.green[100]}`,
    },
}))

const TypographyTitleAccordion = styled(Typography)(({ theme }) => ({
    color: `${theme.palette.blue[100]}`,
    transition: '0.3s',
    '&:hover': {
        color: `${theme.palette.green[100]}`,
    },
}))

const SerialDetail = ({ device }) => {
    const { t } = useTranslation()
    const format = useSelector(selectFormatDateState)
    const { permissions } = usePermissions()
    const user = useSelector(selectUser)
    const [accordions, setAccordions] = useState([])

    const serialSessions = [
        {
            index: 1,
            title: 'attachments',
            content: <DeviceAttachments />,
        },
        {
            index: 2,
            title: 'entitlements',
            content: <DeviceEntitlements deviceId={device?.id} />,
        },
        {
            index: 3,
            title: 'Components',
            content: <DeviceComponents />,
        },
        {
            index: 4,
            title: 'replaceableParts',
            content: <DeviceReplaceablePart />,
        },
        {
            index: 5,
            title: 'issues',
            content: <DeviceIssueList />,
        },
        {
            index: 6,
            title: 'deviceIntuneCheck',
            content: <DeviceIntuneList />,
        },
        {
            index: 7,
            title: 'deviceDispatches',
            content: <DeviceDispatchList />,
        },
    ]

    const handleChange = (panel) => () => {
        if (accordions.includes(panel)) {
            const filteredItems = accordions.filter((item) => item !== panel)
            setAccordions(filteredItems)
        }
        if (!accordions.includes(panel)) {
            accordions.push(panel)
            setAccordions([...accordions])
        }
    }

    const isViewProcurement = permissions.procurement.canViewProcument

    const isUpdateStatePermission = permissions.fleet.canUpdateSerials

    const procurementSerial = useMemo(() => {
        return device?.procurement_serial
    }, [device?.procurement_serial])

    const displayBiosPassword = useMemo(() => {
        if (device?.bios_password === true) {
            return 'SET'
        }

        if (device?.bios_password === false || device?.bios_password === null) {
            return 'NOT SET'
        }

        return device?.bios_password
    }, [device?.bios_password])

    const getLastUpdate = useMemo(() => {
        const updatedDevice = device?.updated_at
            ? device?.updated_at.toString()
            : null

        const updatedProcurementSerial = procurementSerial?.updated_at
            ? procurementSerial?.updated_at.toString()
            : null

        const updatedSerialQueue = device.serial_queue?.updated_at
            ? device.serial_queue?.updated_at.toString()
            : null

        const updatedArray = [
            { date: updatedDevice },
            { date: updatedProcurementSerial },
            { date: updatedSerialQueue },
        ]

        const maxDate = new Date(
            Math.max(
                ...updatedArray.map((element) => {
                    return new Date(element.date)
                }),
            ),
        )

        const year = maxDate.getFullYear()

        if (year === 1970) {
            return 'Never'
        }

        return formatDateTime(maxDate, format)
    }, [
        device.serial_queue?.updated_at,
        device?.updated_at,
        procurementSerial?.updated_at,
        format,
    ])

    const lastestEntitlement = useMemo(
        () =>
            device?.endDateEntitlement
                ? formatDateTime(
                      device?.endDateEntitlement,
                      format,
                      user?.timezone,
                      true,
                  )
                : 'N/A',
        [device?.endDateEntitlement, format, user?.timezone],
    )

    const deviceInformation = useMemo(() => {
        let tagsName = 'N/A'
        if (device?.device_tags.length) {
            tagsName = device?.device_tags
                .map((tags) => {
                    return tags.name
                })
                .join(`, `)
        }
        return [
            {
                style: 'subtitle1',
                value: `${t(`${localization}.productName`)} : ${
                    device?.name ?? 'N/A'
                }`,
            },
            {
                style: 'subtitle1',
                value: `${t(`${localization}.tags`)} : ${tagsName}`,
            },
            {
                style: 'subtitle1',
                value: `${t(`${localization}.productNumber`)} : ${
                    device?.procurement_serial?.product_nr ?? 'N/A'
                }`,
            },
            {
                style: 'subtitle1',
                value: `${t(`${localization}.Vendor`)} : ${
                    device?.vendor?.name
                } | ${t(`${localization}.Family`)} : ${device?.family}`,
            },
            {
                style: 'subtitle1',
                value: `BIOS Password: ${displayBiosPassword}`,
            },
            {
                style: 'subtitle2',
                value: `${t(`${localization}.Model`)} : ${
                    device?.device_model?.description ?? 'N/A'
                } `,
            },
            {
                style: 'subtitle1',
                value: `${t(`${localization}.pkid`)} : ${
                    device?.pkid ?? 'N/A'
                } `,
            },
            {
                style: 'subtitle1',
                value: `${t(`${localization}.express_servicecode`)} : ${
                    device?.express_servicecode ?? 'N/A'
                } `,
            },
            {
                style: 'subtitle2',
                value: `${t(
                    `${localization}.latestEntitlementEndDate`,
                )} : ${lastestEntitlement} `,
            },
            {
                style: 'subtitle1',
                value: `${t(`${localization}.lastUpdate`)} : ${
                    getLastUpdate ?? 'N/A'
                } `,
            },
        ]
    }, [
        device?.device_model?.description,
        device?.device_tags,
        device?.express_servicecode,
        device?.family,
        device?.name,
        device?.pkid,
        device?.procurement_serial?.product_nr,
        device?.vendor?.name,
        displayBiosPassword,
        getLastUpdate,
        lastestEntitlement,
        t,
    ])
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                    <Card>
                        <CardContent>
                            <Box
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                }}
                            >
                                <Box sx={{ my: 1, mx: 2 }}>
                                    <Grid container alignItems='center'>
                                        <Grid item xs={12} md={2}>
                                            <LaptopLarge />
                                        </Grid>
                                        <Grid item xs={12} md={10}>
                                            <List dense={true}>
                                                <ListItem>
                                                    <ListItemText
                                                        primary={
                                                            <TypographyTitleAccordion
                                                                gutterBottom
                                                                variant='h6'
                                                                component='div'
                                                            >
                                                                {device?.serial}
                                                            </TypographyTitleAccordion>
                                                        }
                                                    />
                                                </ListItem>
                                                {deviceInformation.map(
                                                    (item, index) => (
                                                        <ListItem key={index}>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        variant={
                                                                            item.style
                                                                        }
                                                                    >
                                                                        {
                                                                            item.value
                                                                        }
                                                                    </Typography>
                                                                }
                                                            />
                                                        </ListItem>
                                                    ),
                                                )}
                                                <ListItem>
                                                    <ListItemText
                                                        primary={
                                                            <Grid
                                                                container
                                                                direction='row'
                                                                justifyContent='flex-start'
                                                                alignItems='center'
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        marginRight:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    State:{' '}
                                                                </Typography>
                                                                {isUpdateStatePermission ? (
                                                                    <StateButton
                                                                        {...{
                                                                            device,
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        <Box
                                                                            component='span'
                                                                            className='material-symbols-rounded'
                                                                            sx={{
                                                                                marginRight:
                                                                                    '7px',
                                                                            }}
                                                                        >
                                                                            {
                                                                                device
                                                                                    ?.state
                                                                                    ?.icon
                                                                            }
                                                                        </Box>
                                                                        <Typography>
                                                                            {
                                                                                device
                                                                                    ?.state
                                                                                    ?.tid
                                                                            }
                                                                        </Typography>
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        }
                                                    />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                    <Stack direction='column' spacing={3}>
                        <Card>
                            <CardHeaderSerial
                                title={t(`${localization}.deviceInformation`)}
                            />
                            <Divider />
                            <CardContent>
                                <DeviceInformation device={device} />
                            </CardContent>
                        </Card>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ py: 3 }}>
                {isViewProcurement && (
                    <Grid item xs={12} key={'uniquite'}>
                        <Stack direction='column' spacing={3}>
                            <Card>
                                <CardHeaderSerial
                                    title={t(`${localization}.procurementData`)}
                                />
                                <Divider />
                                <CardContent>
                                    <ProcurementSerial />
                                </CardContent>
                            </Card>
                        </Stack>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {serialSessions.map((item) => (
                        <AccordionDevice
                            key={item.index}
                            localization={localization}
                            item={item}
                            accordions={accordions}
                            handleChange={handleChange}
                        />
                    ))}
                </Grid>
            </Grid>
        </>
    )
}

SerialDetail.propTypes = {
    device: PropTypes.object,
}

export default SerialDetail
