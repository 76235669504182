import { Grid, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTicketDetailsQuery } from '~/pages/Diagnostic/query'
import TicketDetails from '~/pages/Diagnostic/TicketDetails'
import PageName from '~/components/PageName'
import { StyledPaper } from '~/components/StyledPaper'
import styled from '@emotion/styled'
import PicturesSection from '~/pages/Diagnostic/PicturesSection'
import { LoadingButton } from '~/components/Button'
import { useIssueQuery } from '~/pages/Issue/query'
import { useMemo, useState } from 'react'
import { ISSUE_STATES } from '~/constants/constants'
import SectionTitle from '~/pages/Diagnostic/components/SectionTitle'
import StyledModal from '~/components/StyledModal'
import { DASHBOARD } from '~/constants/Routes'
import { useQueryClient } from 'react-query'
import { useTriageDoneIssueMutation } from '~/pages/Issue/mutation'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import IssueLog from '~/pages/Diagnostic/IssueLog'
import DeviceHistory from '~/pages/Diagnostic/DeviceHistory'

const Triage = () => {
    const localization = 'pages.issues.triage'
    const { issueId } = useParams()
    const location = useLocation()
    const [showPopup, setShowPopup] = useState(false)

    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { openSnackbar } = useDialogContext()
    const { t } = useTranslation()

    const { data: ticketData } = useTicketDetailsQuery(issueId)
    const { data: issueDetail } = useIssueQuery(issueId)

    const isShownButtons = useMemo(() => {
        const stateIndex = ISSUE_STATES.findIndex(
            (state) => state === issueDetail?.data?.state,
        )

        const triageDoneIndex = ISSUE_STATES.findIndex(
            (state) => state === 'TRIAGE_DONE',
        )

        return stateIndex < triageDoneIndex
    }, [issueDetail])

    const { mutate: makeIssueDoneTriage, isLoading: isSaving } =
        useTriageDoneIssueMutation()

    const triageAndClosePopup = () => {
        makeIssueDoneTriage(
            { issueId },
            {
                onError: () => {
                    openSnackbar({
                        message: t(
                            'pages.issues.overview.error_when_proceeding',
                        ),
                        type: 'error',
                        duration: 10000,
                    })
                },
                onSuccess: () => {
                    setShowPopup(false)
                    queryClient.invalidateQueries(['issue', issueId])

                    queryClient.invalidateQueries(['issue_diagnostic'])

                    queryClient.invalidateQueries(['issue_logs'])

                    queryClient.invalidateQueries(['device_histories'])
                    if (issueDetail?.data?.service_center?.separate_triage) {
                        navigate({ pathname: DASHBOARD })
                    } else {
                        const searchParams = new URLSearchParams(
                            location.search,
                        )
                        searchParams.set('tag', '2')
                        const newSearchString = searchParams.toString()
                        navigate({
                            pathname: window.location.pathname,
                            search: newSearchString,
                        })
                    }
                },
            },
        )
    }

    const Section = styled(Grid, {
        shouldForwardProp: (prop) => prop !== 'show',
    })(({ show }) => {
        return {
            ...(!show && {
                display: 'none',
            }),
        }
    })

    return (
        <>
            <StyledModal
                open={showPopup}
                handleClose={() => {
                    setShowPopup(false)
                }}
            >
                <SectionTitle title={t(`${localization}.message`)} />
                <Stack direction='row' gap={2}>
                    <LoadingButton
                        label={t('dialog.confirm')}
                        onClick={triageAndClosePopup}
                        loading={isSaving}
                        disabled={isSaving}
                    />
                    <LoadingButton
                        label={t('dialog.cancel')}
                        onClick={() => {
                            setShowPopup(false)
                        }}
                        bgColor='#102F44'
                    />
                </Stack>
            </StyledModal>
            <PageName name={t(`${localization}.title`, { issueId })} />
            <Grid container spacing={3}>
                <Section item xs={12} md={6} show={true}>
                    <StyledPaper variant='outlined'>
                        <TicketDetails data={ticketData} />
                    </StyledPaper>
                </Section>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Section item xs={12} show={true}>
                            <StyledPaper variant='outlined'>
                                <PicturesSection
                                    isFinish={
                                        !!issueDetail.diagnostic?.finished_at
                                    }
                                    isInPictureStep={
                                        issueDetail.diagnostic?.current_step ===
                                        1
                                    }
                                    setDisabledNextButton={() => {}}
                                    isTriage={true}
                                />
                            </StyledPaper>
                        </Section>
                        {isShownButtons && (
                            <Section item xs={12} show={true}>
                                <Stack direction='row-reverse' spacing={2}>
                                    <LoadingButton
                                        onClick={() => {
                                            setShowPopup(true)
                                        }}
                                        label={t('pages.issues.create.save')}
                                    />
                                </Stack>
                            </Section>
                        )}
                    </Grid>
                </Grid>
                <Section item xs={12} show={true}>
                    <StyledPaper variant='outlined'>
                        <IssueLog />
                    </StyledPaper>
                </Section>
                <Section item xs={12} show={true}>
                    <StyledPaper variant='outlined'>
                        <DeviceHistory />
                    </StyledPaper>
                </Section>
            </Grid>
        </>
    )
}

export default Triage
