import {
    Table as MuiTable,
    TableBody as MuiTableBody,
    TableCell as MuiTableCell,
    Paper,
    TableContainer,
    TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DataNotFound from '~/components/DataNotFound'
import ExternalLink from '~/components/ExternalLink'
import { TicketHeadCells } from '~/components/HeaderTable/TicketHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableCell from '~/components/TableCell'
import TableHeader from '~/components/TableHeader'
import {
    EASY4U_TICKET_DEVELOPMENT,
    EASY4U_TICKET_PRODUCTION,
} from '~/constants/externalLink'
import useTable from '~/hooks/useTable'
import { calculateBusinessDays, calculateDays } from '~/utils/helpers'
import { useRackTicketListQuery } from '../query'
import LabelStateTicket from './LabelStateTicket'
import LastScanLocation from './LastScanLocation'
import RackTicketListToolbar from './RackTicketListToolbar'
import RackTicketTableFilters from './RackTicketTableFilters'

const TicketTableBody = ({ isLoading, tickets }) => {
    const setColorTicket = useCallback((row) => {
        const today = new Date()
        const ticketCreatedDate = new Date(row.ticket_created)
        const createdAtDate = new Date(row.created_at)

        const weekdays = calculateBusinessDays(createdAtDate, today)

        const daysFromTicketCreated = calculateDays(ticketCreatedDate, today) // ticketCreatedDate from easy4U

        const daysFromCreatedAt = calculateDays(createdAtDate, today) // createdAtDate from created_at in easy4D

        if (daysFromTicketCreated > 7) {
            if (daysFromCreatedAt > 7) {
                return 'red'
            }

            if (weekdays > 2) {
                return 'orange'
            }
        }
        return ''
    }, [])

    const ticketsToRender = useMemo(() => {
        return tickets.map((ticket) => {
            return {
                ...ticket,
                url: ticket?.url
                    ? `${ticket?.url}admin/tickets/werkorder/`
                    : EASY4U_TICKET_PRODUCTION,
                rowColor: setColorTicket(ticket),
                latestDispatch: {
                    rma_code:
                        ticket?.latest_rack_ticket_dispatch?.dispatch_code,
                    status: ticket?.ticket?.ticket_status,
                },
            }
        })
    }, [tickets, setColorTicket])

    if (isLoading) {
        return <TableLoading colSpan={TicketHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {tickets.length > 0 ? (
                ticketsToRender.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <MuiTableCell align='left'>
                                <ExternalLink
                                    extLink={
                                        import.meta.env.VITE_ENVIRONMENT ===
                                        'production'
                                            ? row?.url
                                            : EASY4U_TICKET_DEVELOPMENT
                                    }
                                    param={row.ticket_id}
                                />
                            </MuiTableCell>
                            <TableCell
                                cellValue={row.location}
                                color={row.rowColor}
                            />
                            <TableCell
                                cellValue={<LastScanLocation row={row} />}
                                type={'component'}
                            />
                            <TableCell
                                cellValue={row.latestDispatch.status}
                                color={row.rowColor}
                            />
                            <TableCell
                                cellValue={<LabelStateTicket row={row} />}
                                color={row.rowColor}
                                type='component'
                            />
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={TicketHeadCells.length} />
            )}
        </MuiTableBody>
    )
}

TicketTableBody.propTypes = {
    isLoading: PropTypes.bool,
    tickets: PropTypes.arrayOf(PropTypes.object),
}

const Table = () => {
    const [state, setState] = useState('')
    const [team, setTeam] = useState('')

    const {
        results,
        data,
        isLoading,
        page,
        search,
        order,
        orderBy,
        setPage,
        changePageHandler,
        searchHandler,
        sortHandler,
        onEnterSearch,
    } = useTable({
        query: useRackTicketListQuery,
        queryAttributes: { state, team },
    })

    const refValue = useRef({
        team: '',
        state: '',
    })

    useEffect(() => {
        if (
            team !== refValue.current.team ||
            state !== refValue.current.state
        ) {
            setPage(1)
            refValue.current.team = team
            refValue.current.state = state
        }
    }, [state, team, setPage])

    return (
        <>
            <RackTicketListToolbar
                payload={{ order, orderBy, state, team, page, search }}
            />

            <Paper>
                <RackTicketTableFilters
                    search={search}
                    searchHandler={searchHandler}
                    state={state}
                    setState={setState}
                    team={team}
                    setTeam={setTeam}
                    onEnterSearch={onEnterSearch}
                />
                <TableContainer
                    sx={{ minWidth: 750 }}
                    aria-labelledby='tableTitle'
                    size='medium'
                >
                    <MuiTable>
                        <TableHeader
                            headCells={TicketHeadCells}
                            localization={
                                'pages.issues.rackScans.ticket.overview.'
                            }
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                        />
                        <TicketTableBody
                            isLoading={isLoading}
                            tickets={results}
                        />
                    </MuiTable>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    handleChangePage={changePageHandler}
                    size='medium'
                />
            </Paper>
        </>
    )
}

export default Table
