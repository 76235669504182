import { TableCell } from '@mui/material'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
    ADMINISTRATOR_DATASCHEDULER_DETAIL,
    ADMINISTRATOR_DEFINITION_REGIONS_DETAIL,
    ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL,
    ADMINISTRATOR_DEFINITION_TEAM_DETAIL,
    ADMINISTRATOR_DEFINITION_WORKBENCH_DETAIL,
    ADMINISTRATOR_NAMING_TAG_DETAIL,
    ADMINISTRATOR_NAMING_TAG_RULE_DETAIL,
    ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_DETAIL,
    ADMINISTRATOR_TOOLTIP_DETAIL,
    ADMINISTRATOR_USER_DETAIL,
    FLEET_MODEL_DETAIL,
    FLEET_SERIAL_DETAIL,
    FLEET_SERIAL_DETAIL_SEARCH,
    ISSUE_DETAIL,
    ISSUE_DISPATCH_DETAIL,
    ISSUE_ISSUE_DETAIL,
    ISSUE_RACKSCAN_SCAN_DETAIL,
    PART_COMPONENT_DETAIL,
    PART_PRODUCT_DETAIL,
    PROCUREMENT_DELIVERY_DETAIL,
    PROCUREMENT_PURCHASEORDER_DETAIL,
    PRODUCTION_ADD_ONS_DETAIL,
    PRODUCTION_IMAGES_DETAIL,
    PRODUCTION_PROJECTS_DETAIL,
    PRODUCTION_SERVERS_DETAIL,
} from '~/constants/Routes'
import { route } from '~/utils/helpers'

const ReferencedEntities = ({ isTableCell, dataType, dataIdentifier }) => {
    const linkReferenced = useMemo(() => {
        switch (dataType) {
            case 'ticket-id':
                return route(ISSUE_DETAIL, { id: dataIdentifier })
            case 'issue-id':
                return route(ISSUE_ISSUE_DETAIL, { issueId: dataIdentifier })
            case 'rackscan-id':
                return route(ISSUE_RACKSCAN_SCAN_DETAIL, { id: dataIdentifier })
            case 'device-id':
                return route(FLEET_SERIAL_DETAIL, { id: dataIdentifier })
            case 'device-serial':
                return route(FLEET_SERIAL_DETAIL_SEARCH, {
                    serial: dataIdentifier,
                })
            case 'dispatch-id':
                return route(ISSUE_DISPATCH_DETAIL, { id: dataIdentifier })
            case 'models-id':
                return route(FLEET_MODEL_DETAIL, { id: dataIdentifier })
            case 'components-id':
                return route(PART_COMPONENT_DETAIL, { id: dataIdentifier })
            case 'products-id':
                return route(PART_PRODUCT_DETAIL, { id: dataIdentifier })
            case 'purchase-order-id':
                return route(PROCUREMENT_PURCHASEORDER_DETAIL, {
                    id: dataIdentifier,
                })
            case 'deliveries-id':
                return route(PROCUREMENT_DELIVERY_DETAIL, {
                    id: dataIdentifier,
                })
            case 'projects-id':
                return route(PRODUCTION_PROJECTS_DETAIL, {
                    project_nr: dataIdentifier,
                })
            case 'images-id':
                return route(PRODUCTION_IMAGES_DETAIL, { id: dataIdentifier })
            case 'add-ons-id':
                return route(PRODUCTION_ADD_ONS_DETAIL, { id: dataIdentifier })
            case 'servers-id':
                return route(PRODUCTION_SERVERS_DETAIL, { id: dataIdentifier })
            case 'users-disabled-id':
                return route(ADMINISTRATOR_USER_DETAIL, { id: dataIdentifier })
            case 'service-account-id':
                return route(ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_DETAIL, {
                    id: dataIdentifier,
                })
            case 'naming-tags-id':
                return route(ADMINISTRATOR_NAMING_TAG_DETAIL, {
                    id: dataIdentifier,
                })
            case 'naming-tags-rule-id':
                return route(ADMINISTRATOR_NAMING_TAG_RULE_DETAIL, {
                    id: dataIdentifier,
                })
            case 'service-centers-id':
                return route(ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL, {
                    id: dataIdentifier,
                })
            case 'regions-id':
                return route(ADMINISTRATOR_DEFINITION_REGIONS_DETAIL, {
                    id: dataIdentifier,
                })
            case 'teams-id':
                return route(ADMINISTRATOR_DEFINITION_TEAM_DETAIL, {
                    id: dataIdentifier,
                })
            case 'workbenches-id':
                return route(ADMINISTRATOR_DEFINITION_WORKBENCH_DETAIL, {
                    id: dataIdentifier,
                })
            case 'tooltips-id':
                return route(ADMINISTRATOR_TOOLTIP_DETAIL, {
                    id: dataIdentifier,
                })
            default:
                return route(ADMINISTRATOR_DATASCHEDULER_DETAIL, {
                    id: dataIdentifier,
                })
        }
    }, [dataType, dataIdentifier])

    const renderTableCell = (dataIdentifier) => {
        const linkElement = dataIdentifier ? (
            <Link to={linkReferenced} style={{ textDecoration: 'none' }}>
                {dataIdentifier}
            </Link>
        ) : (
            '--'
        )

        return isTableCell ? (
            <TableCell component='th' scope='row'>
                {linkElement}
            </TableCell>
        ) : (
            { linkElement }
        )
    }

    return renderTableCell(dataIdentifier)
}

ReferencedEntities.propTypes = {
    isTableCell: PropTypes.bool,
    dataType: PropTypes.string,
    dataIdentifier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default ReferencedEntities
