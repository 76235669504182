import issueApi from '~/api/issueApi'
import { useQuery } from 'react-query'
import { DEFAULT_CACHE_TTL } from '~/utils/constants'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { failedToFetched } from '~/utils/helpers'

export const useIssueQualityCheckQuery = (issueId, enabled) => {
    const { openSnackbar } = useDialogContext()
    return useQuery(
        ['issue_quality_check', issueId],
        async () => {
            const { data } = await issueApi.getQCForIssue(issueId)

            return data
        },
        {
            onError: (error) => {
                failedToFetched(error, openSnackbar)
            },
            retry: 1,
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
            enabled,
        },
    )
}

export const useIssueQualityCheckForFormQuery = (issueId) => {
    return useQuery(
        ['issue_quality_check_for_form', issueId],
        async () => {
            const { data } = await issueApi.getQCForIssue(issueId)

            return data
        },
        {
            retry: 1,
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
            refetchOnMount: 'always',
        },
    )
}

export const useIssueQcImagesQuery = (issueId) =>
    useQuery(
        ['issue_qc_images', issueId],
        async () => {
            const { data } = await issueApi.getIssueQcPictures(issueId)

            return data
        },
        {
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
        },
    )
